import React, { createContext, useContext, useState, useEffect } from "react";
import { http } from "../../http/http";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { GlobalContext } from "../../../App";
import BusinessTabs from "../../common/BusinessTabs";
import Auth from "../../../auth/Auth";
const Add_Business_Notes = () => {
    const [activeTab, setActiveTab] = useState('all');
    const { setShowLoader } = useContext(GlobalContext);
    const [activeTabColor, setActiveTabColor] = useState({ all: 'active', pinned: '', archived: '' });
    const [openPinModal, setOpenPinModal] = useState(false);
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const businessId = JSON.parse(localStorage.getItem("BusinessId"));
    const [notesList, setNotesList] = useState([]);
    const [pinNote, setPinNote] = useState()
    const [pinId, setPinId] = useState();
    const [archiveNote, setArchiveNote] = useState();
    const [archiveId, setArchiveId] = useState();
    const [archiveModal, setArchiveModal] = useState(false);
    const [editNote, setEditNote] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [deleteTagIDs, setDeleteTagIDs] = useState([]);

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();

    const handleAddNotes = (data) => {

        if (!Auth.innerPermission("corporate_note_create")) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }

        setShowLoader(true);
        try {
            http(
                {
                    method: "POST",
                    url: `corporate/note/${businessId}/add`, data,
                    isSecure: true,
                    body: {
                        title: data.title,
                        description: data.description,
                        tag: data.tags,
                    },
                },
                (res) => {

                    // Refresh the notes list
                    fetchNotes();
                    toast.success(res.single_message);
                    setShowLoader(false);


                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (err) {
            console.error(err);
        }
    }

    // const handleInputChange = (e) => {
    //     setInputValue(e.target.value);
    // };

    const handleInputChange = (e) => {
        const value = e.target.value;
        // Allow only letters, numbers, and spaces
        const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        setInputValue(sanitizedValue); // Update input field without special characters
    };


    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
    //         event.preventDefault();
    //         const tag = inputValue.trim();
    //         if (tag && !tags.includes(tag)) {
    //             setTags([...tags, tag]);
    //         }
    //         setInputValue('');
    //     }
    // };

    const handleKeyDown = (event) => {
        // if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
        if (event.key === 'Enter') {
            event.preventDefault();
            const trimmedTag = inputValue.trim();
            if (trimmedTag && !tags.includes(trimmedTag)) {
                setTags([...tags, trimmedTag]); // Add valid, unique tag
            }
            setInputValue(''); // Clear the input field
        }
    };


    const handleRemoveTag = (indexToRemove) => {
        // Add tag index to deleteTagIDs if it's not already included
        if (!deleteTagIDs.includes(indexToRemove)) {
            setDeleteTagIDs(prevDeleteTagIDs => [...prevDeleteTagIDs, indexToRemove]);
        }
        // Remove the tag from tags state
        setTags(prevTags => prevTags.filter((_, index) => index !== indexToRemove));
    };

    // const onSubmit = (data) => {
    //     data.tags = tags; // Append tags to form data
    //     handleAddNotes(data);

    //     reset();
    //     setTags([]);
    // };

    const onSubmit = (data) => {
        // Add pending tag if valid
        const trimmedInputValue = inputValue.trim();
        if (trimmedInputValue && !tags.includes(trimmedInputValue)) {
            setTags([...tags, trimmedInputValue]);
        }

        // Filter out empty tags from the list
        data.tags = tags.filter(tag => tag.trim() !== '');

        // Call the backend to add the note
        handleAddNotes(data);

        // Reset the form
        reset();
        setTags([]); // Clear tags
        setInputValue(''); // Clear the input field
    };




    const handleInputFocus = (e) => {
        const element = e.target.closest('.bootstrap-tagsinput');
        if (element) {
            element.classList.add('has-focus');
        }
    };

    // const handleInputBlur = (e) => {
    //     const element = e.target.closest('.bootstrap-tagsinput');
    //     if (element) {
    //         element.classList.remove('has-focus');
    //     }
    // };

    const handleInputBlur = (e) => {
        const trimmedInputValue = inputValue.trim();
        if (trimmedInputValue && !tags.includes(trimmedInputValue)) {
            setTags([...tags, trimmedInputValue]); // Add pending tag
        }
        setInputValue(''); // Clear the input field
    };




    // Add Pin API Calling -->  
    const fetchNotes = (tab) => {
        let endpoint = `corporate/note/${businessId}/`;
        if (tab === 'pinned') {
            endpoint += 'pinlisting';
        } else if (tab === 'archived') {
            endpoint += 'archivelisting';
        } else {
            endpoint += 'listing';
        }

        http({
            method: 'GET',
            url: endpoint,
            isSecure: true,
        },
            (res) => {

                setActiveTabColor({ all: '', pinned: '', archived: '', [tab]: 'active' });
                setNotesList(res.data?.data);
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };

    useEffect(() => {
        fetchNotes(activeTab);
    }, [activeTab]);


    const handlePinSubmit = (pinValue) => {
        setShowLoader(true);
        try {
            http({
                method: "PATCH",
                url: `corporate/note/${pinId}/pin`,
                isSecure: true,
                body: { pin: pinValue },
            }, (res) => {
                fetchNotes();
                setOpenPinModal(false);
                toast.success(res.single_message);
                setShowLoader(false);
            }, (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }

            );
        } catch (err) {
            console.error(err);
        }
    };


    ////// Unpin Api calling --->

    const handleUnPinSubmit = (pinValue) => {
        setShowLoader(true);
        try {
            http({
                method: "PATCH",
                url: `corporate/note/${pinId}/unpin`,
                isSecure: true,
                body: { pin: pinValue },
            }, (res) => {
                fetchNotes();
                setOpenPinModal(false);
                toast.success(res.single_message);
                setShowLoader(false);
            }, (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }

            );
        } catch (err) {
            console.error(err);
        }
    };


    const handlePinClick = (id) => {
        setPinId(id);
        const selectedNote = notesList.find(note => note.id === id);
        setPinNote({
            ...selectedNote,
            pin: selectedNote.pin,
        });
        setOpenPinModal(true);
    };

    const hanldeArchiveClick = (id) => {
        setArchiveId(id);
        const selectedNoteArchive = notesList.find(noteArchive => noteArchive.id === id);
        setArchiveNote({
            ...selectedNoteArchive,
            archive: selectedNoteArchive.archive,
        });
        setArchiveModal(true);

    }

    const handleArchiveSubmit = (archiveValue) => {
        setShowLoader(true);
        try {
            http({
                method: "PATCH",
                url: `corporate/note/${archiveId}/archive`,
                isSecure: true,
                body: { archive: archiveValue },

            }, (res) => {
                fetchNotes();
                setArchiveModal(false);
                toast.success(res.single_message);
                setShowLoader(false);
            }, (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }

            );
        } catch (err) {
            console.error(err);
        }
    }

    const hanldeEditNotes = (id) => {

        if (!Auth.innerPermission("corporate_note_update")) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        try {
            // Check if the note with the given id exists in notesList
            const note = notesList.find(note => note.id === id);
            if (note) {
                // If the note exists, make a GET request to fetch its edit URL
                http({
                    method: "GET",
                    url: `corporate/note/${businessId}/${id}/edit`,
                    isSecure: true,
                },
                    (res) => {
                        // Update the editNote state with the fetched data
                        const data = res?.data;
                        setEditNote(data);
                        setValue("title", data.title);
                        setTags(data.tags.map(tag => tag.name));  // Update the tags state
                        setValue("description", data.description);
                        setIsEditing(true);
                    },
                    (err) => {
                        // Handle errors according to the response
                        if (err.status === 401) {
                            toast.error(err.single_message);
                            setShowLoader(false);
                        } else if (err.status === 400) {
                            toast.error(err.single_message);
                            setShowLoader(false);
                        } else {
                            toast.error(err.single_message);
                            setShowLoader(false);
                        }
                    }
                );
            } else {
                // If the note does not exist, log an error message
                console.error("Note not found");
            }
        } catch (err) {
            console.error(err);
        }
    }

    const updateNoteHandle = (data) => {
        try {
            const filteredTags = editNote.tags.filter((tag, index) => !deleteTagIDs.includes(index));

            // Extract existing tag names from remaining tags in editNote
            const existingTags = new Set(filteredTags.map(tag => tag.name));

            // Filter out duplicates and trim whitespace from new tags
            const newTags = tags.map(tag => tag.trim()).filter(tag => tag !== '' && !existingTags.has(tag));

            // Combine existing tags and new unique tags
            const updatedTags = [...existingTags, ...newTags];

            const payload = {
                title: data.title,
                description: data.description,
                tag: updatedTags
            };

            http(
                {
                    method: "PUT",
                    url: `corporate/note/update/${businessId}/${editNote?.id}`,
                    isSecure: true,
                    body: payload
                },
                (res) => {
                    toast.success(res.single_message)
                    // Refresh the notes list
                    fetchNotes();
                    reset();
                    setTags([]);
                    setIsEditing(false); // Reset to "Add" mode
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                    }
                }
            );
        } catch (err) {
            console.error(err);
        }
    }


    return (
        <div className="right_section font-poppins">
            <div className="clients-detail-area py-3">
                <BusinessTabs />

                {/* <!-- NOTES TAB START --> */}
                <div className="tab-pane" id="notes">
                    <div className="row notes_area mt-2">
                        <div className="col-md-7 left_notes">

                            <div className="col-lg-12 d-flex justify-content-between align-items-center mb-2 comm-head p-0">
                                <div className="left_head">
                                    <h4 className="fs-20 fw-semibold mt-3">Notes</h4>
                                </div>
                                <div className="right_head mt-3 pe-3">
                                    <div className="notes-btn-group d-flex align-items-center">
                                        <button
                                            className={`nav-link ${activeTabColor.all === 'active' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('all')}
                                        >All</button>
                                        <hr />
                                        <button
                                            className={`nav-link ${activeTabColor.pinned === 'active' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('pinned')}
                                        >Pinned</button>
                                        <hr />
                                        <button
                                            className={`nav-link ${activeTabColor.archived === 'active' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('archived')}
                                        >Archived</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row p-0">
                                {(Auth.innerPermission("corporate_note_list")) ? (
                                    notesList && notesList.length > 0 ? (
                                        notesList.map((noteList, index) => (
                                            <div className="col-4 col-md-6 col-sm-12 mt-3 ps-0">
                                                <div className="note-box bg-white shadow-lg rounded-4 p-4">
                                                    <Link
                                                        //  to={`/admin/client/corporate_note/note_detail/${noteList.id}`}
                                                        to={Auth.innerPermission("corporate_note_view") ? `/admin/client/corporate_note/note_detail/${noteList.id}` : "#"}
                                                        onClick={(e) => {
                                                            if (!Auth.innerPermission("corporate_note_view")) {
                                                                e.preventDefault(); // Prevent navigation
                                                                toast.error("You are not authorized to access the resource");
                                                            }
                                                        }}
                                                    >
                                                        <h4 className="fs-18 text-black text-start">{noteList?.title}</h4>
                                                        <p className="fs-14 text-muted mt-2">{moment(noteList?.created_at).format('MMM D, YYYY, h:mm a')}</p>
                                                        <h4 className="fs-18"> </h4>
                                                        <p className="fs-14 text-muted mt-2">{noteList?.description}</p>


                                                        <div className="note-tags mt-3 d-flex align-items-center flex-wrap gap-2">
                                                            {noteList.tags && noteList.tags.length > 0 ? (
                                                                noteList.tags.map((tagsList, tagIndex) => (
                                                                    <span key={tagIndex}>{tagsList?.name}</span>
                                                                ))
                                                            ) : (
                                                                <span>--</span>
                                                            )}
                                                        </div>

                                                    </Link>
                                                    <div className="note-client mt-2 d-flex align-items-center justify-content-between flex-wrap">
                                                        <div className="l_client d-flex align-items-center mt-2">
                                                            <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                            <p className="fs-14 text-black ms-2">John Smith</p>
                                                        </div>
                                                        <div className="r_client d-flex align-items-center gap-1 mt-2">
                                                            <span className={`d-flex align-items-center justify-content-center cursor-pointer ${noteList.pin === "1" ? 'active-pin' : 'inactive-pin'}`} onClick={() => handlePinClick(noteList.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.01" height="13.999" viewBox="0 0 14.01 13.999">
                                                                    <path id="thumbtack" d="M13.583,3.941,10.113.472a1.506,1.506,0,0,0-1.922-.2A1.459,1.459,0,0,0,8.005,2.49l.345.345a.473.473,0,0,1,0,.666L7.125,4.727a.879.879,0,0,1-.835.229l-.479-.123a4.741,4.741,0,0,0-1.692-.128A1.966,1.966,0,0,0,2.463,6.022,2.048,2.048,0,0,0,2.94,8.171L3.959,9.226.171,13.016A.584.584,0,1,0,1,13.841L4.77,10.067l.729.755a2.728,2.728,0,0,0,1.9.856,2.048,2.048,0,0,0,.684-.117A1.834,1.834,0,0,0,9.31,9.985a4.723,4.723,0,0,0-.125-1.677l-.124-.6a.88.88,0,0,1,.233-.825l1.219-1.217a.543.543,0,0,1,.346-.142.425.425,0,0,1,.3.127l.3.3a1.5,1.5,0,0,0,1.938.218,1.459,1.459,0,0,0,.186-2.221Z" transform="translate(0 -0.013)"></path>
                                                                </svg>
                                                            </span>
                                                            <span className={`d-flex align-items-center justify-content-center cursor-pointer ${noteList.archive === "1" ? 'active-pin' : 'inactive-pin'}`} onClick={() => hanldeArchiveClick(noteList.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.002" height="13.999" viewBox="0 0 14.002 13.999">
                                                                    <g id="box" transform="translate(0 -0.052)">
                                                                        <path id="Path_3569" data-name="Path 3569" d="M11.082.052H2.916A2.916,2.916,0,0,0,0,2.968H0a1.75,1.75,0,0,0,1.75,1.75h10.5A1.732,1.732,0,0,0,14,3.1,2.919,2.919,0,0,0,11.209.055Z" transform="translate(0)"></path>
                                                                        <path id="Path_3570" data-name="Path 3570" d="M33.583,213.385H21.917a.583.583,0,0,0-.583.583v4.666a2.92,2.92,0,0,0,2.916,2.916h7a2.92,2.92,0,0,0,2.916-2.916v-4.666A.583.583,0,0,0,33.583,213.385ZM29.5,216.3H26a.583.583,0,1,1,0-1.167h3.5a.583.583,0,1,1,0,1.167Z" transform="translate(-20.751 -207.5)"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span className="d-flex align-items-center justify-content-center cursor-pointer" onClick={() => hanldeEditNotes(noteList.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.999" height="13.998" viewBox="0 0 13.999 13.998">
                                                                    <g id="write" transform="translate(-7.998 -8)">
                                                                        <path id="Path_3528" data-name="Path 3528" d="M8.3,26.037,8,28.468a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)"></path>
                                                                        <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.471,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.858a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)"></path>
                                                                        <path id="Path_3530" data-name="Path 3530" d="M36.874,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.707A.583.583,0,0,0,36.874,52.583Z" transform="translate(-14.877 -31.168)"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-12 text-center mt-5">
                                            <img src={require("../../../assets/images/datanotfound.png")} alt="No Data Found" />
                                            <p className="fs-14 text-danger text-center fw-semibold text-capitalize mt-3">No Data found</p>
                                        </div>
                                    )
                                ) : (
                                    <div className="no-access-message">
                                        <h6 className="fs-14 text-danger text-center fw-semibold text-capitalize mt-3">You do not have permission to access this module.</h6>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-5 right_notes end-0 show-notes">
                            <div className="noteform-area ps-5 pe-2 py-3 position-relative h_100vh">
                                <div className="vr-line"></div>
                                <div className="notes-head p-4 d-flex align-items-center gap-3">
                                    <svg id="note" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                        <path id="Path_3584" data-name="Path 3584" d="M18.042,16.6a3.124,3.124,0,0,0-2.115,2.115.305.305,0,0,1-.571,0A3.125,3.125,0,0,0,13.242,16.6a.3.3,0,0,1,0-.571,3.125,3.125,0,0,0,2.115-2.115.305.305,0,0,1,.571,0,3.124,3.124,0,0,0,2.115,2.115A.3.3,0,0,1,18.042,16.6Z" transform="translate(-13.045 -13.631)" fill="#3fc4c1" />
                                        <g id="Group_917" data-name="Group 917" transform="translate(10.262)">
                                            <path id="Path_3585" data-name="Path 3585" d="M206.595,14.293V62.905A1.291,1.291,0,0,1,205.3,64.2H166.344a1.294,1.294,0,0,1-1.291-1.291V14.293A1.293,1.293,0,0,1,166.344,13H205.3A1.289,1.289,0,0,1,206.595,14.293Z" transform="translate(-156.857 -13.002)" fill="#ffa800" />
                                            <path id="Path_3586" data-name="Path 3586" d="M137.068,97.1v40.319a1.293,1.293,0,0,1-1.29,1.291H96.819a1.291,1.291,0,0,1-1.29-1.291V88.809a1.289,1.289,0,0,1,1.29-1.291h30.106Z" transform="translate(-95.529 -78.712)" fill="#ffcd00" />
                                            <path id="Path_3587" data-name="Path 3587" d="M358.032,97.108h-8.848a1.273,1.273,0,0,1-.722-.221l-.02-.014-.182-.154a1.28,1.28,0,0,1-.369-.9V87.523Z" transform="translate(-316.849 -78.336)" fill="#fff" />
                                        </g>
                                        <g id="Group_918" data-name="Group 918" transform="translate(14.706 41.64)">
                                            <path id="Path_3588" data-name="Path 3588" d="M163.763,352.152H133.817a.7.7,0,0,1,0-1.4h29.946a.7.7,0,0,1,0,1.4Z" transform="translate(-133.117 -350.752)" fill="#fff" />
                                            <path id="Path_3589" data-name="Path 3589" d="M163.763,383.392H133.817a.7.7,0,0,1,0-1.4h29.946a.7.7,0,0,1,0,1.4Z" transform="translate(-133.117 -378.105)" fill="#fff" />
                                            <path id="Path_3590" data-name="Path 3590" d="M163.763,414.632H133.817a.7.7,0,0,1,0-1.4h29.946a.7.7,0,0,1,0,1.4Z" transform="translate(-133.117 -405.459)" fill="#fff" />
                                        </g>
                                        <path id="Path_3591" data-name="Path 3591" d="M362.051,163.007v9.264l-9.571-9.485a1.273,1.273,0,0,0,.722.221Z" transform="translate(-310.703 -144.321)" fill="#ffa800" />
                                        <path id="Path_3592" data-name="Path 3592" d="M438.059,172.706v22.9a1.291,1.291,0,0,1-1.29,1.291h-7.362V164.563Z" transform="translate(-378.059 -146.018)" fill="#ff8400" />
                                        <path id="Path_3593" data-name="Path 3593" d="M157.578,249.865l5.974.916a.528.528,0,0,0,.6-.6l-.916-5.974Z" transform="translate(-139.748 -215.689)" fill="#fff" />
                                        <path id="Path_3594" data-name="Path 3594" d="M19.961,100.933a4,4,0,0,0-5.659,0h0a4,4,0,0,0,0,5.659h0l16.8,16.8,5.659-5.659Z" transform="translate(-13.119 -89.103)" fill="#3fc4c1" />
                                        <path id="Path_3595" data-name="Path 3595" d="M111.207,141.865l-.915-5.974-.07-.451-.325-.324L95.528,120.744v1.982L108.906,136.1l.915,5.974a.526.526,0,0,1-.518.608.75.75,0,0,1-.081-.005l-5.974-.916-7.721-7.721v1.979l6.73,6.73.325.325.453.07,5.974.915a1.714,1.714,0,0,0,.294.022,1.927,1.927,0,0,0,1.9-2.22Z" transform="translate(-85.37 -107.507)" fill="#ffa800" />
                                    </svg>
                                    <h4 className="fs-20 text-white fw-semibold lh-base">Please create<br /> a new note.
                                    </h4>
                                </div>
                                <div className="notes-content mt-4">
                                    <h5 className="fs-20 fw-semibold">Add Note</h5>
                                    <p className="fs-16 text-muted mt-2">Please write your note in the space provided
                                        below.</p>
                                    <form onSubmit={handleSubmit(isEditing ? updateNoteHandle : onSubmit)}>
                                        <div className="form-group mt-4">
                                            <label >Title <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                className="form-input mt-2 bg-white"
                                                placeholder="Type here..."
                                                {...register('title', { required: "Required", })}
                                            />
                                            {errors.title && (
                                                <span className="error">{errors.title.message}</span>
                                            )}
                                        </div>
                                        <div className="form-group mt-4 moreInfoShow">
                                            <label className="d-flex gap-2 align-items-end">Tags <p className="fs-12 fst-italic">(Press Enter)</p></label>
                                            <div className="bootstrap-tagsinput shadow-main">
                                                {tags.map((tag, index) => (
                                                    <span key={index} className="tag-item">
                                                        <span className="tag-text">{tag}</span>
                                                        <span className="tag-close" onClick={() => handleRemoveTag(index)}>x</span>
                                                    </span>
                                                ))}
                                                <input
                                                    type="text"
                                                    name="input"
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                    onKeyDown={handleKeyDown}
                                                    onFocus={handleInputFocus}
                                                    onBlur={handleInputBlur}
                                                    placeholder="Type here..."
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mt-4">
                                            <label >Description <span className="text-danger">*</span></label>
                                            <textarea
                                                className="mt-2"
                                                name="description"
                                                id="description"
                                                cols="20"
                                                rows="10"
                                                {...register('description', { required: "Required", })}
                                            >

                                            </textarea>
                                            {errors.description && (
                                                <span className="error">{errors.description.message}</span>
                                            )}
                                        </div>
                                        <div className="form-group mt-4 text-end">
                                            <button type="submit" className="btn-blue ms-auto">
                                                {isEditing ? 'Update' : 'Add'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="close_message_notes"><i className="fa fa-times"></i></div>
                            <div className="notes-icon">
                                <i className="fa fa-sticky-note"></i>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <!-- NOTES TAB END --> */}
            </div>

            <Modal
                show={openPinModal}
                onHide={setOpenPinModal}
                size="md"
                centered
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between p-0">
                    {
                        pinNote?.pin === "1" ? (
                            <h4 className="fs-20">Unpin</h4>
                        ) : (
                            <h4 className="fs-20">Pin</h4>
                        )
                    }

                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body ">
                    {
                        pinNote?.pin === "1" ? (
                            <h4 className="txt text-center mt-2">Are you sure you want to Unpin?</h4>
                        ) : (
                            <h4 className="txt text-center mt-2">Are you sure you want to Pin?</h4>
                        )
                    }


                </div>
                <div className=" text-center my-4">
                    <form>
                        <div className="modal-footer border-0 justify-content-center p-0">
                            {pinNote?.pin === "1" ? (
                                <button type="button" className="btn-blue" onClick={() => { handleUnPinSubmit("0"); setOpenPinModal(false); }}>Unpin</button>
                            ) : (
                                <button type="button" className="btn-blue" onClick={() => { handlePinSubmit("1"); setOpenPinModal(false); }}>Pin</button>
                            )}
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                show={archiveModal}
                onHide={setArchiveModal}
                size="md"
                centered
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}

            >
                <div className="modal-header border-0 justify-content-between p-0">
                    {
                        archiveNote?.archive === "1" ? (
                            <h4 className="fs-20">Unarchive</h4>
                        ) : (
                            <h4 className="fs-20">Archive</h4>
                        )
                    }

                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body ">
                    {
                        archiveNote?.archive === "1" ? (
                            <h4 className="txt text-center mt-2">Are you sure you want to Unarchive?</h4>
                        ) : (
                            <h4 className="txt text-center mt-2">Are you sure you want to Archive?</h4>
                        )
                    }


                </div>
                <div className=" text-center my-4">
                    <form>
                        <div className="modal-footer border-0 justify-content-center p-0">
                            {archiveNote?.archive === "1" ? (
                                <button type="button" className="btn-blue" onClick={() => { handleArchiveSubmit("0"); setArchiveModal(false); }}>Unarchive</button>
                            ) : (
                                <button type="button" className="btn-blue" onClick={() => { handleArchiveSubmit("1"); setArchiveModal(false); }}>Archive</button>
                            )
                            }

                        </div>
                    </form>
                </div>
            </Modal>
        </div>

    )
}
export default Add_Business_Notes;