import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { http } from "../../http/http";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Marital_Status, Gender, Residency_Status, Client_Category, Occupation_Category, Refer_by_Client } from "../../../helpers/staticList";
import Add_Contact from "./Add_Contact";
import Spouse from "./Spouse";
import Dependent_Detail from "./Dependent_Detail";
import ClientTabs from "../../common/ClientTabs";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { GlobalContext } from "../../../App";
// import { handlePermissionData, handlePermissionIdsData } from '../../http/help';
import { Line } from 'rc-progress';
import Auth from "../../../auth/Auth";
// import SearchField from "../../common/SearchField";
import { SearchField } from '../../common/SearchField';
import GeneralHelper from "../../http/GeneralHelper";
import Delete from "../../feature/Delete";
import Upload_Files from "../../feature/Upload_Files";
import { Depended_list } from "../../../helpers/staticList";

const IndClientProfile = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const storedId = JSON.parse(localStorage.getItem("clientId"));
    const [notesList, setNotesList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [IndClientProfile, setIndClientProfile] = useState();
    const [editClientProfile, setEditClientProfile] = useState(false);
    const [showcCntactModal, setShowContactModal] = useState(false)
    const [spouseModal, setSpouseModal] = useState(false);
    const [editContactModal, setEditContactModal] = useState(false);
    const [editSpouse, setEditSpouse] = useState(false);
    const [editClientProfileData, setEditClientProfileData] = useState()
    const [picture, setPicture] = useState(null);
    const [IdPicture, setIdPicture] = useState(null);
    const [fileType, setFileType] = useState("");
    const [contactList, setContactList] = useState();
    const [page, setPage] = useState(1);
    const [editContactDetail, setEditContactDetail] = useState();
    const [editSpouseDetail, setEditSpouseDeatail] = useState();
    const [spouseList, setSpouseList] = useState();
    const [showDependentModal, setShowDependentModal] = useState(false);
    const [editDepentModal, setEditDepententModal] = useState(false)
    const [dependentList, setDependentList] = useState([]);
    const [editDepentDetail, setEditDepentDetail] = useState(null);
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);
    const [currentTask, setCurrentTask] = useState(null);
    const [showCustomFormModal, setShowCustomFormModal] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Track the selected tab
    const [showSurvey, setShowSurvey] = useState(false);
    const [formListing, setFormListing] = useState([])
    const [surveyFormId, setSurveyFormId] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [isFormUpdate, setIsFormUpdate] = useState(false);
    const [surveyModels, setSurveyModels] = useState([]);
    const [editForm, setEditForm] = useState(null);
    const { register, handleSubmit, setValue, getValues, watch, formState: { errors }, } = useForm();
    const [address, setAddress] = useState(editClientProfileData?.office_location || '');
    const [addressContact, setAddressContect] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [postal, setPostal] = useState("");
    const [addressSpouse, setAddressSpouse] = useState(editSpouseDetail?.address || '');
    const [provinceSpouse, setProvinceSpouse] = useState(editSpouseDetail?.province || '');
    const [postalSpouse, setPostalSpouse] = useState(editSpouseDetail?.postal_code || '');
    const [citySpouse, setCitySpouse] = useState(editSpouseDetail?.city || '');
    const [isSinVisible, setIsSinVisible] = useState(false);
    const [isSpouseSinVisible, setIsSpouseSinSinVisible] = useState(false);
    const [isDependedSinVisible, setIsDependedSinSinVisible] = useState(false);
    const [error, setErrors] = useState({});
    const [inviteUserList, setInviteUserList] = useState([]);
    const [spouseForm, setSpouseForm] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        sin: "",
        email: "",
        contact_number: "",
        date_of_birth: "",
        date_of_landing: "",
        country_id: "",
        city: "",
        street_address: "",
        province: "",
        postal_code: "",
        mis_notes: ""
    });
    const [contactForm, setContactForm] = useState({
        unit_no: "",
        client_since: "",
        client_type: "",
        phone_number: "",
        whatsapp_contact: "",
        city: "",
        address: "",
        province: "",
        postal_code: "",
    });
    const [errorSpouse, setErrorSpouse] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [status, setStatus] = useState("");
    const fileInputRef = useRef(null);
    const MAX_FILE_SIZE = 10 * 1024 * 1024;
    const [selectedSpouseId, setSelectedSpouseId] = useState(null);
    const [selectedDependedId, setSelectedDepended] = useState(null);
    const [errorDepended, setErrorDepended] = useState({})
    const [clientList, setClientList] = useState([]);
    const [searchAccountManagerValue, setSearchAccountManagerValue] = useState("");
    const [searchClientValue, setSearchClientValue] = useState("");
    const [showSIN, setShowSIN] = useState(false);
    const [showSpouseSIN, setShowSpouseSIN] = useState(false);
    const [showDependedSIN, setShowDependedSIN] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
    const [showContactList, setShowContactList] = useState([]);
    const [uploadedFileList, setUploadedFileList] = useState([]);
    const fileIcons = {
        xls: require("../../../assets/images/xlsimage.png"),
        pdf: require("../../../assets/images/pdfimage.png"),
        doc: require("../../../assets/images/docimage.png"),
        default: require("../../../assets/images/imageplaceholder.png"), // Fallback for unknown extensions
    };
    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes >= 1024 * 1024) {
            return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
        } else if (sizeInBytes >= 1024) {
            return `${(sizeInBytes / 1024).toFixed(2)} KB`;
        } else {
            return `${sizeInBytes} B`;
        }
    };
    const timeElapsed = (createdAt) => {
        const now = new Date();
        const createdDate = new Date(createdAt);
        const diff = now - createdDate; // Difference in milliseconds
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (seconds < 60) {
            return `${seconds} sec ago`;
        } else if (minutes < 60) {
            return `${minutes} mins ago`;
        } else if (hours < 24) {
            return `${hours} hours ago`;
        } else if (days < 30) {
            return `${days} days ago`;
        } else if (months < 12) {
            return `${months} months ago`;
        } else {
            return `${years} years ago`;
        }
    };
    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };
    const toggleSpouseSINVisibility = () => {
        setShowSpouseSIN(!showSpouseSIN); // Toggle between showing and hiding SIN
    };
    const toggleDependedSINVisibility = () => {
        setShowDependedSIN(!showDependedSIN); // Toggle between showing and hiding SIN
    };

    const formatDate = (isoDate) => {
        if (!isoDate) return ""; // Return empty if no date
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`;
    };
    const filteredInviteUserList =
        Array.isArray(inviteUserList) &&
        inviteUserList.filter((user) =>
            user.email.toLowerCase().includes(searchAccountManagerValue.toLowerCase())
        );

    // const filteredClientList =
    //     Array.isArray(clientList) &&
    //     clientList.filter((user) =>
    //         user.first_name.toLowerCase().includes(searchClientValue.toLowerCase())
    //     );

    const filteredClientList =
        showContactList &&
        showContactList.filter((data) =>
            (data.first_name || data.company_name) && searchClientValue // Check if either is valid and matches search
                ? (
                    (data.first_name?.toLowerCase() || "") +
                    " " +
                    (data.company_name?.toLowerCase() || "")
                ).includes(searchClientValue.toLowerCase())
                : true // Show all if no search value
        );

    useEffect(() => {
        if (editSpouseDetail) {
            setAddressSpouse(editSpouseDetail.address || "");
            setCitySpouse(editSpouseDetail.city || "");
            setProvinceSpouse(editSpouseDetail.province || "");
            setPostalSpouse(editSpouseDetail.postal_code || "");

            setSpouseForm((prev) => ({
                ...prev,
                address: editSpouseDetail.address || "",
                city: editSpouseDetail.city || "",
                province: editSpouseDetail.province || "",
                postal_code: editSpouseDetail.postal_code || "",
            }));
        }
    }, [editSpouseDetail]);


    useEffect(() => {
        if (editContactDetail) {
            setAddressContect(editContactDetail.address || "");
            setCity(editContactDetail.city || "");
            setProvince(editContactDetail.province || "");
            setPostal(editContactDetail.postal_code || "");
        }
    }, [editContactDetail]);


    const validateForm = () => {
        const newErrors = {};
        if (!editDepentDetail.first_name) newErrors.first_name = ' required';
        if (!editDepentDetail.last_name) newErrors.last_name = 'required';
        return newErrors;
    };
    // Ensure address is updated when editClientProfileData changes
    useEffect(() => {
        if (editClientProfileData?.office_location) {
            setAddress(editClientProfileData.office_location);
        }
    }, [editClientProfileData]);






    const handleSpouseChange = (field, value) => {
        setSpouseForm((prev) => ({ ...prev, [field]: value }));
        // Reset field error if value is not empty
        if (value.trim()) {
            setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
        }
    };




    // Update local address state and form state when address input changes
    const handleChangeOfficeAddress = (value) => {
        setAddress(value); // Update local state
        setEditClientProfileData((prevState) => ({
            ...prevState,
            office_location: value, // Update form state
        }));
    };

    const handleChangeContact = (e) => {
        const { name, value } = e.target;
        setContactForm({ ...contactForm, [name]: value });
        setErrorDepended((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };
    // Handle address selection from suggestions
    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;

                let city = "";
                let province = "";
                let postalCode = "";
                let country = "";

                // Extract the required address components
                addressComponents.forEach((component) => {
                    if (component.types.includes("locality")) {
                        city = component.long_name; // City
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        province = component.short_name; // Province
                    }
                    if (component.types.includes("postal_code")) {
                        postalCode = component.long_name; // Postal Code
                    }
                    if (component.types.includes("country")) {
                        country = component.long_name; // Country
                    }
                });

                // Update the address fields in `editClientProfileData`
                setEditClientProfileData((prevState) => ({
                    ...prevState,
                    office_location: formattedAddress,
                    city: city || prevState.city,
                    province: province || prevState.province,
                    postal_code: postalCode || prevState.postal_code,
                    country: country || prevState.country,
                }));

                // Update the address field directly
                setAddress(formattedAddress);
            } else {
                console.warn("No results found for the selected address.");
            }
        } catch (error) {
            console.error("Error selecting place:", error?.message || "No message", error?.stack || "No stack trace");
        }
    };

    const handleSelectContect = async (value) => {
        setContactForm({ ...contactForm, address: value });

        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                let city = "";
                let province = "";
                let postal_code = "";

                addressComponents.forEach((component) => {
                    if (component.types.includes("administrative_area_level_1")) {
                        province = component.short_name;
                    }
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    }
                    if (component.types.includes("postal_code")) {
                        postal_code = component.short_name;
                    }
                });

                setContactForm({
                    ...contactForm,
                    address: value,
                    city,
                    province,
                    postal_code,
                });
            }
        } catch (error) {
            console.error("Error selecting address:", error);
        }
    };

    const handleSelectSpouse = (selectedAddress) => {
        setAddressSpouse(selectedAddress);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: selectedAddress }, (results, status) => {
            if (status === "OK" && results[0]) {
                const addressComponents = results[0].address_components;

                const getComponent = (type) =>
                    addressComponents.find((component) => component.types.includes(type))?.long_name || "";

                const updatedSpouse = {
                    ...spouseForm,
                    address: selectedAddress, // Update address
                    city: getComponent("locality") || spouseForm.city, // Keep current city if not found
                    province: getComponent("administrative_area_level_1") || spouseForm.province, // Keep current province if not found
                    postal_code: getComponent("postal_code") || spouseForm.postal_code, // Keep current postal_code if not found
                };

                // Update state variables
                setCitySpouse(updatedSpouse.city);
                setProvinceSpouse(updatedSpouse.province);
                setPostalSpouse(updatedSpouse.postal_code);

                // Update form state
                setSpouseForm(updatedSpouse);
            }
        });
    };



    const handleModalOpen = (taskData) => {
        setCurrentTask(taskData);
        setShowCustomFormModal(true);
    };
    const handleTabSelect = async (index) => {
        setSelectedTabIndex(index);
        setShowSurvey(true);

        const form = currentTask.links[index];

        const formListingItem = formListing.find(item => item.id === form.id);


        if (!form?.id) {

            return;
        }

        setSurveyFormId(form.id);
        setSelectedForm(form);

        // Parse the form_data structure
        let surveyData = form.form_data[0];


        // Fetch data from the API using fetchSurveyDataForEdit
        let apiData = await fetchSurveyDataForEdit(formListingItem);

        if (!apiData) {
            console.error('Failed to fetch survey data from API.');
            return;
        }



        const existingData = apiData.data?.data?.data || {};


        // Check if there is existing data to decide if this form is in "update" mode
        setIsFormUpdate(Object.keys(existingData).length > 0);

        const surveyModel = new Model({
            title: surveyData.title,
            pages: surveyData.pages.map((page) => ({
                ...page,
                elements: page.elements.map((element) => {
                    const elementName = element.name;
                    const existingValue = existingData[elementName];



                    const updatedElement = {
                        ...element,
                        value: existingValue !== undefined ? existingValue : element.value,
                        defaultValue: existingValue !== undefined ? existingValue : element.defaultValue,
                    };



                    return updatedElement;
                }),
            })),
        });



        const updatedSurveyModels = [...surveyModels];
        updatedSurveyModels[index] = surveyModel;
        setSurveyModels(updatedSurveyModels);


    };
    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (files) {
            setEditClientProfileData((prevData) => ({
                ...prevData,
                [name]: files[0], // Handle file input
            }));
        } else {
            setEditClientProfileData((prevData) => ({
                ...prevData,
                [name]: value, // Handle text input
            }));

            // Special handling for address input
            if (name === 'office_location') {
                setAddress(value); // Update local state for address
            }
        }
    };

    // const onChangePicture = (e) => {
    //     const file = e.target.files[0];

    //     if (file) {
    //         setPicture(file);
    //         const mimeType = file.type;
    //         setFileType(mimeType.split("/")[1]); // Get the file extension from MIME type
    //     } else {
    //         setPicture(null);
    //         setFileType("");
    //     }
    // };



    const onChangePicture = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Validate file type

            if (!file.type.startsWith("image/")) {
                alert("Only image files (JPEG, PNG) are allowed.");
                handleDeletePicture(); // Clear invalid input
                return;
            }

            // Validate file size
            if (file.size > MAX_FILE_SIZE) {
                alert("File size exceeds 10 MB limit.");
                handleDeletePicture(); // Clear large file input
                return;
            }

            // Set file and its type to state
            setPicture(file);
            const mimeType = file.type;
            setFileType(mimeType.split("/")[1]); // Get the file extension from MIME type
        } else {
            setPicture(null);
            setFileType("");
        }
    };




    // const onChangeIdProof = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setIdPicture(file);
    //     } else {
    //         setIdPicture(null);
    //     }
    // };

    // const onChangeIdProof = (e) => {
    //     const files = Array.from(e.target.files); // Support multiple files

    //     files.forEach((file) => {
    //         // Restrict image files
    //         if (file.type.startsWith("image/")) {
    //             alert(`${file.name} is an image file and is not allowed.`);
    //             return;
    //         }

    //         // Validate file size
    //         if (file.size > MAX_FILE_SIZE) {
    //             alert(`${file.name} exceeds the 10 MB size limit.`);
    //             return;
    //         }

    //         // Add the file to the uploaded files state
    //         setUploadedFiles((prev) => [...prev, { file, size: (file.size / 1024).toFixed(1) + " KB" }]);

    //         // Simulate upload progress
    //         simulateUploadProgress(file);
    //     });
    // };

    // const onChangeIdProof = (e) => {
    //     const file = e.target.files[0];

    //     if (!file) return; // No file selected

    //     if (file.type.startsWith("image/")) {
    //         alert(`${file.name} is an image file and is not allowed.`);
    //         return;
    //     }

    //     if (file.size > MAX_FILE_SIZE) {
    //         alert(`${file.name} exceeds the 10 MB size limit.`);
    //         return;
    //     }

    //     setUploadedFiles((prev) => [...prev, { file, size: (file.size / 1024).toFixed(1) + " KB" }]); // Add to newly uploaded files
    //     simulateUploadProgress(file);
    // };


    // const simulateUploadProgress = (file) => {
    //     let progress = 0;

    //     const interval = setInterval(() => {
    //         progress += 10; // Increment progress by 10%
    //         setUploadProgress((prev) => ({
    //             ...prev,
    //             [file.name]: progress,
    //         }));

    //         if (progress >= 100) {
    //             clearInterval(interval);
    //         }
    //     }, 200);
    // };

    // const handleRemoveFile = (fileName, isExisting = false) => {
    //     if (isExisting) {
    //         setExistingFiles((prev) => prev.filter((file) => file.name !== fileName));
    //     } else {
    //         setUploadedFiles((prev) => prev.filter((file) => file.file.name !== fileName));
    //         setUploadProgress((prev) => {
    //             const updatedProgress = { ...prev };
    //             delete updatedProgress[fileName];
    //             return updatedProgress;
    //         });
    //     }
    // };


    const onChangeIdProof = (e) => {
        const file = e.target.files[0]; // Get the first file (single file upload)

        if (!file) return; // No file selected

        // Restrict image files
        if (file.type.startsWith("image/")) {
            alert(`${file.name} is an image file and is not allowed.`);
            return;
        }

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
            alert(`${file.name} exceeds the 10 MB size limit.`);
            return;
        }

        // Clear previously uploaded files and set the new file
        setUploadedFiles([{ file, size: (file.size / 1024).toFixed(1) + " KB" }]);

        // Simulate upload progress
        simulateUploadProgress(file);
    };


    const simulateUploadProgress = (file) => {
        let progress = 0;

        const interval = setInterval(() => {
            progress += 10; // Increment progress by 10%
            setUploadProgress((prev) => ({
                ...prev,
                [file.name]: progress,
            }));

            if (progress >= 100) {
                clearInterval(interval); // Stop progress simulation at 100%
            }
        }, 200); // Simulate progress every 200ms
    };

    const handleRemoveFile = (fileName) => {
        // Remove the file from the uploaded files state
        setUploadedFiles((prev) => prev.filter((file) => file.file.name !== fileName));
        setUploadProgress((prev) => {
            const updatedProgress = { ...prev };
            delete updatedProgress[fileName];
            return updatedProgress;
        });
    };




    const handleAccordionClick = (index) => {
        setActiveAccordionIndex(index); // Update state with clicked index
    };





    const replaceNullsWithEmptyStrings = (data) => {
        const updatedData = {};
        Object.entries(data).forEach(([key, value]) => {
            updatedData[key] = value === null ? "" : value; // Replace null with empty string
        });
        return updatedData;
    };

    const handleSubmitProfile = async (event) => {
        event.preventDefault();
        setShowLoader(true);
        const formData = new FormData();
        // Append profile picture, send null if not uploaded
        // if (picture) {
        //     formData.append("profile_picture", picture);
        // } else {
        //     formData.append("profile_picture", null);
        // }

        if (picture) {
            formData.append("profile_picture", picture); // New file uploaded
        } else if (editClientProfileData?.profile_picture) {
            formData.append("profile_picture", editClientProfileData.profile_picture); // Use existing URL
        }

        // Append ID proofs, send null if no files are uploaded
        if (uploadedFiles.length > 0) {
            uploadedFiles.forEach(({ file }) => {
                formData.append("id_proof", file);
            });
        } else {
            formData.append("id_proof", null);
        }

        // App

        // Merge the updated `status` into `editClientProfileData`
        const updatedProfileData = {
            ...editClientProfileData,
            status: status, // Ensure `status` is the updated value from the state
        };

        // Replace null values with empty strings
        const processedProfileData = replaceNullsWithEmptyStrings(updatedProfileData);

        Object.entries(processedProfileData).forEach(([key, value]) => {
            if (key !== "id_proof" && key !== "profile_picture") {
                formData.append(key, value);
            }
        });

        try {
            await http(
                {
                    method: "POST",
                    url: `clients/update/${id}`,
                    isSecure: true,
                    contentType: "multipart/form-data", // Specify multipart form-data
                    body: formData,
                },
                (res) => {
                    toast.success(res.single_message);
                    setEditClientProfile(false); // Close profile edit modal
                    setProfileUpdated((prev) => !prev); // Trigger re-fetch or update state
                    setShowLoader(false);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while processing the request.");
        }
    };


    const formListingData = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `custom_form/listing`,
                    isSecure: true,

                },
                (res) => {
                    setFormListing(res?.data?.data)
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    useEffect(() => {

        formListingData();
    }, []);

    const handleCompleteSurvey = (sender) => {
        try {
            // Capture the filled survey data
            const surveyData = sender.data;

            // Dynamically retrieve id, title, and module_name
            const moduleName = editClientProfileData?.links?.[0]?.module_name;

            const url = isFormUpdate
                ? `custom_form/link/update/${surveyFormId}`
                : `custom_form/link/${surveyFormId}/save`;

            http(
                {
                    method: "POST",
                    url: url,
                    isSecure: true,
                    body: {
                        "module_name": moduleName, // Dynamic module name
                        "data": {
                            "data": surveyData // Captured survey data
                        }
                    }
                },
                (res) => {
                    toast.success(res.single_message)
                    if (!isFormUpdate) {
                        // After first save, mark the form as updateable
                        setIsFormUpdate(true);
                    }
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error('Error completing survey:', error);
        }
    };


    const fetchSurveyDataForEdit = async (formListingItem) => {
        try {

            return new Promise((resolve, reject) => {
                http(
                    {
                        method: "GET",
                        url: `custom_form/link/${formListingItem?.id}/edit`,  // Use the id from the formListingItem passed in
                        isSecure: true,
                    },
                    (res) => {
                        resolve(res?.data);  // Return the nested data object
                        setEditForm(res?.data)
                    },
                    (err) => {
                        // Handle errors according to the response
                        if (err.status === 401) {
                            toast.error(err.single_message);
                            setShowLoader(false);
                        } else if (err.status === 400) {
                            toast.error(err.single_message);
                            setShowLoader(false);
                        } else {
                            toast.error(err.single_message);
                            setShowLoader(false);
                        }
                    }
                );
            });
        } catch (error) {
            console.error('Error fetching survey data for edit:', error);
            return null;
        }
    };
    ///// Update Client Contact API Calling ///////////

    const updateContact = (updatedData) => {
        const validationErrors = validateFormDepended();

        if (Object.keys(validationErrors).length > 0) {
            setErrorDepended(validationErrors); // Set errors if validation fails
            return; // Exit if validation fails
        }

        setShowLoader(true);

        const contactDetailId =
            selectedContactId || editContactDetail?.contact_details?.contact_detail?.id;

        // Add contact_detail_id to the payload
        const payload = {
            ...updatedData, // Spread other fields from updatedData
            contact_detail_id: contactDetailId || null, // Include contact_detail_id
        };
        http(
            {
                method: "PUT",
                url: `client/contact/update/${editContactDetail?.client_id}/${editContactDetail?.id}`,
                // url: `v2/corporate_member/update/${editContactDetail?.id}`,
                body: payload,
                isSecure: true,
            },
            (res) => {
                toast.success(res.single_message)
                setEditContactModal(false);
                fetchConData();
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                // Optionally, you can perform additional actions after the profile is updated
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };
    const onContactSubmit = (e) => {
        e.preventDefault();
        updateContact(contactForm);
    };
    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setContactForm((prevForm) => ({
            ...prevForm,
            [name]: value, // Dynamically update the state based on the input name
        }));
    };
    ////// Edit Main Clinet Profile Api ///////////////

    // useEffect(() => {
    //     http(
    //         {
    //             method: "GET",
    //             url: `clients/${id}/edit`,
    //             isSecure: true,
    //         },
    //         (res) => {
    //             setEditClientProfileData(res?.data);
    //             setStatus(res?.data.status || "");

    //         },
    //         (err) => {
    //             // Handle errors according to the response
    //             if (err.status === 401) {
    //                 toast.error(err.single_message);
    //                 setShowLoader(false);
    //             } else if (err.status === 400) {
    //                 toast.error(err.single_message);
    //                 setShowLoader(false);
    //             } else {
    //                 toast.error(err.single_message);
    //             }
    //         }
    //     );
    // }, [id, profileUpdated])


    useEffect(() => {
        if (editClientProfile) { // Trigger only if edit modal is open
            // setShowLoader(true); // Show loader before API call
            http(
                {
                    method: "GET",
                    url: `clients/${id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setEditClientProfileData(res?.data);
                    setStatus(res?.data.status || "");
                    setShowLoader(false); // Stop loader on success
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                    setShowLoader(false); // Stop loader on error
                }
            );
        }
    }, [editClientProfile, id, profileUpdated]);

    //// Client View Api /////

    useEffect(() => {
        http(
            {
                method: "GET",
                url: `clients/${id}/view`,
                isSecure: true,
            },
            (res) => {
                setIndClientProfile(res?.data, "dtata");
                localStorage.setItem("ClientUserData", JSON.stringify(res?.data?.first_name));

            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    }, [id, profileUpdated]);


    //// Listing Client Contact Api /////////

    const fetchConData = () => {
        http(
            {
                method: "GET",
                url: `client/contact/${id}/listing`,
                isSecure: true,
            },
            (res) => {
                setContactList(res?.data?.data);
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );

    }
    useEffect(() => {
        fetchConData();
    }, [page]);






    const fetchContactData = () => {
        if (contactList && contactList.length > 0) {
            http(
                {
                    method: "GET",
                    url: `client/contact/${contactList[0]?.client_id}/${contactList[0]?.id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setEditContactDetail(res?.data);
                    setContactForm({
                        ...contactForm,
                        unit_no: res?.data?.unit_no || "",
                        client_since: res?.data?.client_since || "",
                        client_type: res?.data?.client_type || "",
                        phone_number: res?.data?.phone_number || "",
                        whatsapp_contact: res?.data?.whatsapp_contact || "",
                        city: res?.data?.city || "",
                        address: res?.data?.address || "",
                        province: res?.data?.province || "",
                        postal_code: res?.data?.postal_code || "",
                    });
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        }
    };

    useEffect(() => {
        fetchContactData();
    }, [contactList]);


    // Client Spouse Listing Api ----->>

    const fetchSpouData = () => {
        http(
            {
                method: "GET",
                url: `client/spouse/${id}/listing?page=${page}&limit=${1}`,
                isSecure: true,
            },
            (res) => {
                setSpouseList(res?.data?.data);
            },
            (err) => { },
        );
    }

    useEffect(() => {

        fetchSpouData();
    }, []);


    // Client  Spouse Edit Api ----->>



    const fetchSpouseData = () => {
        // setShowLoader(true);
        if (spouseList && spouseList.length > 0) {
            http(
                {
                    method: "GET",
                    url: `client/spouse/${spouseList[0]?.client_id}/${spouseList[0]?.id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    const fetchedData = res?.data || {};
                    setEditSpouseDeatail(res?.data)

                    setSpouseForm({
                        first_name: fetchedData.first_name || "",
                        middle_name: fetchedData.middle_name || "",
                        last_name: fetchedData.last_name || "",
                        sin: fetchedData.sin || "",
                        email: fetchedData.email || "",
                        contact_number: fetchedData.contact_number || "",
                        date_of_birth: fetchedData.date_of_birth || "",
                        date_of_landing: fetchedData.date_of_landing || "",
                        country_id: fetchedData.country_id || "",
                        city: fetchedData.city || "",
                        address: fetchedData.address || "", // Update here
                        province: fetchedData.province || "",
                        postal_code: fetchedData.postal_code || "",
                        mis_notes: fetchedData.mis_notes || ""
                    });

                    // Update individual state variables for the form
                    setAddressSpouse(fetchedData.address || ""); // Use `address` here
                    setCitySpouse(fetchedData.city || "");
                    setProvinceSpouse(fetchedData.province || "");
                    setPostalSpouse(fetchedData.postal_code || "");
                    setShowLoader(false);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        }
    };


    // Trigger fetchSpouseData when spouseList updates
    useEffect(() => {
        fetchSpouseData();
    }, [spouseList]);




    ///// Update Client Contact API Calling ///////////

    const updateSposue = (updatedData) => {
        const validationErrors = validateFormSpouse(); // Validate inputs
        if (Object.keys(validationErrors).length > 0) {
            setErrorSpouse(validationErrors); // Set errors if validation fails
            return; // Exit if validation fails
        }
        setShowLoader(true);
        const contactDetailId =
            selectedSpouseId || editSpouseDetail?.contact_details?.contact_detail?.id;

        // Add contact_detail_id to the payload
        const payload = {
            ...updatedData, // Spread other fields from updatedData
            contact_detail_id: contactDetailId || null, // Include contact_detail_id
        };
        http(
            {
                method: "PUT",
                url: `client/spouse/update/${editSpouseDetail?.client_id}/${editSpouseDetail?.id}`,
                body: payload,
                isSecure: true,
            },
            (res) => {
                toast.success(res.single_message)
                setEditSpouse(false);
                fetchSpouData();
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                // Optionally, you can perform additional actions after the profile is updated
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };
    const onSpouseSubmit = (e) => {
        e.preventDefault();
        // Call the updateProfile function with the formData
        updateSposue(spouseForm);
    };

    //  Denpendet Listing Api Calling -->

    const fetchData = async () => {

        try {
            http(
                {
                    method: "GET",
                    url: `client/dependent/${id}/listing`,
                    isSecure: true,
                },
                (res) => {
                    setDependentList(res?.data?.data)
                }

            );
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchData(); // Call the function here
    }, [id]);

    /// Edit Depentent Api calling -->
    const editDependentData = async (clientId, dependentId) => {
        // setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    url: `client/dependent/${clientId}/${dependentId}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setEditDepentDetail(res?.data); // Set data for edit modal
                    setEditDepententModal(true); // Show the edit modal
                    setShowLoader(false);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );

        } catch (error) {
            toast.error(err.single_message);
            setShowLoader(false);
        }
    };

    const handleEditIconClick = (clientId, dependentId) => {
        if (!Auth.innerPermission("individual_dependent_update")) {
            toast.error("You are not authorized to access the resource")
            return;  // Prevent further execution if no permission
        }
        editDependentData(clientId, dependentId);
    };

    /// Update Dependent Api Calling -->

    const updateDependtentData = async (clientId, dependentId) => {
        // setShowLoader(true);
        const validationErrors = validateForm(); // Validate inputs
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors); // Set errors if validation fails
            return; // Exit if validation fails
        }
        setShowLoader(true);
        const dependedDetailId =
            selectedDependedId || editDepentDetail?.contact_details?.contact_detail?.id;
        try {
            http({
                method: "PUT", // Assuming you are updating the data with a PUT request
                url: `client/dependent/update/${clientId}/${dependentId}`,
                body: {
                    first_name: editDepentDetail?.first_name, // Assuming showEditOperationData contains the updated name
                    last_name: editDepentDetail?.last_name,
                    middle_name: editDepentDetail?.middle_name,
                    sin: editDepentDetail?.sin,
                    relation: editDepentDetail?.relation,
                    date_of_birth: editDepentDetail?.date_of_birth,
                    contact_detail_id: dependedDetailId
                },
                isSecure: true,
            },

                (res) => {
                    toast.success(res.single_message)
                    hideEditDepentModal();
                    fetchData();
                    setShowLoader(false);

                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            toast.error(err.single_message);
            setShowLoader(false);
        }

    };

    const deleteSpouse = () => {

        // if (!hasSpouseDeletePermission()) {
        if (!Auth.innerPermission("individual_spouse_delete")) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        setShowLoader(true);
        try {
            http(
                {
                    method: "DELETE",
                    url: `client/spouse/delete/${spouseList[0]?.id}`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.single_message);
                    fetchSpouData();
                    setShowLoader(false);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            toast.error(err.single_message);
            // setShowLoader(false);
        }
    };

    const deleteContact = (contactId) => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "DELETE",
                    url: `client/contact/delete/${contactId}`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.single_message);
                    fetchConData();
                    setShowLoader(false);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error('Error deleting director:', error);
        }
    };


    const deleteDepended = (dependedId) => {

        // if (!hasContactDeletePermission()) {
        if (!Auth.innerPermission("individual_dependent_delete")) {

            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        setShowLoader(true);
        try {
            http(
                {
                    method: "DELETE",
                    url: `client/dependent/delete/${dependedId}`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.single_message);
                    fetchData();
                    setShowLoader(false);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error('Error deleting director:', error);
        }
    };

    // Function to toggle the visibility of SIN number
    const toggleSinVisibility = () => {
        setIsSinVisible(!isSinVisible); // Toggle the state
    };
    // Function to toggle the visibility of SIN number
    const toggleSpouseSinVisibility = () => {
        setIsSpouseSinSinVisible(!isSpouseSinVisible); // Toggle the state
    };
    // Function to toggle the visibility of SIN number
    const toggleDependentSinVisibility = () => {
        setIsDependedSinSinVisible(!isDependedSinVisible); // Toggle the state
    };
    const showClientEditModal = () => {
        // if (!hasClientProfileEditPermission()) {
        if (!Auth.innerPermission("client_update")) {
            toast.error("You are not authorized to access the resource")
            return;  // Prevent further execution if no permission
        }

        setEditClientProfile(true);
    };
    const onHideHandler = () => {
        setEditClientProfile(false);
    };
    // const showContactModal = () => {
    //     setShowContactModal(true);
    // };
    const showContactModal = () => {
        // if (hasContactPermission()) {
        if (Auth.innerPermission("individual_contact_create")) {
            // Open the contact modal if permission is granted
            setShowContactModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }
    };
    const showSpouseModal = () => {
        // if (hasSpousePermission()) {
        if (Auth.innerPermission("individual_spouse_create")) {
            // Open the contact modal if permission is granted
            setSpouseModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }

    }

    const showEditContactModal = () => {
        // if (hasContactEditPermission()) {
        if (Auth.innerPermission("individual_contact_update")) {
            // Open the contact modal if permission is granted
            setEditContactModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }
        // setEditContactModal(true);
    }
    const hideEditContact = () => {
        setEditContactModal(false);
    }

    const showEditSpouse = () => {
        // if (hasSpouseEditPermission()) {
        if (Auth.innerPermission("individual_spouse_update")) {
            // Open the contact modal if permission is granted
            setEditSpouse(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }

        // setEditSpouse(true);
    }
    const hideEditSpouse = () => {
        setEditSpouse(false);
    }

    const ShowDependent = () => {
        // if (hasDependentPermission()) {
        if (Auth.innerPermission("individual_dependent_create")) {
            setShowDependentModal(true);
        } else {
            toast.error("You are not authorized to access the resource")
        }

    }

    // const showEditDepentModal = () => {
    //     setEditDepententModal(true);
    // }
    const hideEditDepentModal = () => {


        if (Auth.innerPermission("individual_dependent_edit") || Auth.innerPermission("individual_dependent_update")) {
            setEditDepententModal(false);
        } else {
            toast.error("You are not authorized to access the resource")
        }
        // setEditDepententModal(false);
    }



    // const handleClientList = async () => {
    //     // setShowLoader(true);
    //     try {
    //         http(
    //             {
    //                 method: "GET",
    //                 url: `clients`,
    //                 isSecure: true,
    //             },
    //             (res) => {
    //                 setClientList(res?.data?.data)
    //             },

    //             (err) => {
    //                 // Handle errors according to the response
    //                 if (err.status === 401) {
    //                     toast.error(err.single_message);
    //                     setShowLoader(false);
    //                 } else if (err.status === 400) {
    //                     toast.error(err.single_message);
    //                     setShowLoader(false);
    //                 } else {
    //                     toast.error(err.single_message);
    //                     setShowLoader(false);
    //                 }
    //             }
    //         );
    //     } catch (err) {
    //         console.error("Unexpected Error:", err);
    //     }
    // };

    const handleContactData = () => {
        http(
            {
                method: "GET",
                url: `v2/contact_detail`,
                isSecure: true,
            },
            (res) => {
                if (res?.data?.data) {
                    setShowContactList(res.data?.data); // Set the contact list data
                } else {
                    setShowContactList([]); // If no data, set an empty list
                }
            },
            (err) => {
                console.error("Error fetching contact data:", err);
                toast.error(err.single_message || "An error occurred.");
            }
        );
    };

    const handleInviteUserList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `lists/adminusers`,
                    isSecure: true,
                },
                (res) => {
                    setInviteUserList(res?.data?.data)
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleInviteUserList();
        handleContactData();
    }, []);


    const handleNotesList = async () => {

        http({
            method: 'GET',
            url: `client/note/${storedId}/listing`,
            isSecure: true,
        },
            (res) => {

                setNotesList(res.data?.data);
            },
            (err) => {
                console.error("Error:", err);
            }
        );
    };

    const handleTaskListData = (page) => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/task/${storedId}/listing`,
                    isSecure: true,
                },
                (res) => {
                    setTaskList(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
            setShowLoader(false);
        }
    };
    const handleTagData = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/tags/${storedId}/listing`,
                    isSecure: true,
                },
                (res) => {
                    setTagList(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
            setShowLoader(false);
        }
    };

    const handleFileUpload = async (fileList, fileLabel, fileExpireDate) => {
        const formData = new FormData();
        formData.append("name", fileLabel);
        formData.append("model_type", "client");
        formData.append("model_id", storedId);
        formData.append("expiry_date", fileExpireDate);
        formData.append("status", "1");

        fileList.forEach((file, index) => {
            console.log(`Appending file: ${file.name}, Size: ${file.size}`);
            formData.append(`file`, file.fileObject);
        });
        http(
            { method: "POST", url: `client/upload-document`, isSecure: true, contentType: "multipart/form-data", body: formData, },
            (res) => {
                toast.success(res.single_message);
                setOpenUploadFileModal(false);
                handleFolderFileListing();
            },
            (err) => {
                console.error("Error response:", err);
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };

    const handleFolderFileListing = async () => {
        const modelType = "client";
        try {
            http({ method: "GET", url: `client/document/listing?model_type=${modelType}&model_id=${storedId}`, isSecure: true, },
                (res) => {
                    setUploadedFileList(res?.data?.data)
                }
            );
        } catch (error) { }
    };
    const handleFileDelete = async (fileId) => {
        try {
            await http({
                method: "DELETE",
                url: `files/${fileId}`, // Include the file ID in the API call
                isSecure: true,
            },
                (res) => {
                    toast.success(res.single_message)
                    handleFolderFileListing();
                    setFolderFileList((prevList) => ({
                        ...prevList,
                        files: prevList.files.filter((file) => file.id !== fileId), // Remove the deleted file
                    }));
                });
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    useEffect(() => {
        handleNotesList();
        handleTaskListData();
        handleTagData();
        handleFolderFileListing();
    }, []);

    const handleDeletePicture = () => {
        setPicture(null); // Clears the uploaded picture
    };

    const handleDeleteExistingPicture = () => {
        setEditClientProfileData((prev) => ({
            ...prev,
            profile_picture: null, // Clears the existing profile picture
        }));
    };

    const handleDeleteIDProof = () => {
        setEditClientProfileData((prev) => ({
            ...prev,
            id_proof: null, // Clear the ID proof from the state
        }));
    };

    const validateFormSpouse = () => {
        const errors = {};
        if (!spouseForm.first_name.trim()) {
            errors.first_name = "First Name is required.";
        }
        if (!spouseForm.last_name.trim()) {
            errors.last_name = "Last Name is required.";
        }
        if (!spouseForm.email.trim()) {
            errors.email = "Email is required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(spouseForm.email)) {
            errors.email = "Enter a valid email address.";
        }
        // Add other field validations as needed

        return errors;
    };

    const handleSpouseSelect = (spouse) => {
        setSpouseForm((prev) => ({
            ...prev,
            first_name: spouse.first_name || "",
            middle_name: spouse.middle_name || "",
            last_name: spouse.last_name || "",
            email: spouse.email || "",
            phone_number: spouse.phone_number || "",
            date_of_birth: formatDate(spouse.dob) || "",
            date_of_landing: formatDate(spouse.date_of_landing) || "",
            city: spouse.city || "",
            province: spouse.province || "",
            postal_code: spouse.postal_code || "",
            mis_notes: spouse.notes || "",

        }));
        setErrorSpouse((prevErrors) => ({
            ...prevErrors,
            first_name: "",
            last_name: "",
            email: "",
        }));
        setSelectedSpouseId(spouse.id);
    };
    const handleContactSelect = (contact) => {
        setContactForm((prev) => ({
            ...prev,
            unit_no: contact.unit_no || "",
            client_since: contact.client_since || "",
            client_type: contact.client_type || "",
            phone_number: contact.phone_number || "",
            whatsapp_contact: contact.whatsapp_contact || "",
            city: contact.city || "",
            street_address: contact.street_address || "",
            province: contact.province || "",
            postal_code: contact.postal_code || "",

        }));
        setErrorDepended((prevErrors) => ({
            ...prevErrors,
            client_since: "",
            phone_number: "",
            whatsapp_contact: "",
            client_type: "",

        }));
        setSelectedContactId(contact.id);
    };
    const handleDependedSelect = (depended) => {
        setEditDepentDetail((prev) => ({
            ...prev,


            first_name: depended.first_name || "",
            middle_name: depended.middle_name || "",
            last_name: depended.last_name || "",
            date_of_birth: formatDate(depended.dob) || "",
            relation: depended.relation || "",


        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            first_name: "",
            last_name: "",
        }));
        setSelectedDepended(depended.id);
    };
    const validateFormDepended = () => {
        const errors = {};

        // if (!contactForm.first_name?.trim()) {
        //     errors.first_name = "First Name is required.";
        // }
        if (!contactForm.phone_number) {
            errors.phone_number = "Required";
        } else if (contactForm.phone_number.length < 7) {
            errors.phone_number = "Phone number must be at least 7 digits.";
        }
        if (!contactForm.client_since) {
            errors.client_since = "Required";
        }
        if (!contactForm.client_type) {
            errors.client_type = "Required";
        }
        if (!contactForm.whatsapp_contact) {
            errors.whatsapp_contact = "Required";
        } else if (contactForm.whatsapp_contact.length < 7) {
            errors.whatsapp_contact = "WhatsApp contact must be at least 7 digits.";
        }
        // Add other field validations as needed
        return errors;
    };

    const handleDeleteIconClick = (contactId) => {
        if (!Auth.innerPermission("individual_contact_delete")) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        setSelectedContactId(contactId); // Store the ID of the contact to delete
        setOpenDeleteModal(true); // Open the modal
    };

    const handleDeleteConfirm = () => {
        setOpenDeleteModal(false); // Close the modal
        deleteContact(selectedContactId); // Call the delete function
    };
    const handleUploadFileClick = () => {
        setOpenUploadFileModal(true); // Open the modal
    };
    const handleEditClick = (taskId) => {
        navigate(`/admin/client/individual_task/individual_task_detail/${taskId}`);
    };
    return (
        <>
            <div className="right_section font-poppins">
                <div className="clients-detail-area py-3">
                    <ClientTabs id={id} />
                    <div className="row tabs-content">
                        <div className="col-md-12 tab-content p-0">

                            {/* <!-- HOME TAB START --> */}
                            <div className="tab-pane active" id="home">
                                <div className="row mt-2 justify-content-between">
                                    <div className="col-md-6 left_head">
                                        <h4 className="fs-20 fw-semibold mt-3">Profile</h4>
                                    </div>
                                    {IndClientProfile?.forms.length && IndClientProfile?.links.length ? (
                                        <div className="col-md-6 mt-2 right_head">
                                            <ul className="ps-0 d-flex align-items-center gap-2 justify-content-end">
                                                <li onClick={() => handleModalOpen(editClientProfileData)}>
                                                    <span className="file-form cursor-pointer">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="13.999" viewBox="0 0 12.599 13.999">
                                                            <path id="Path_506" data-name="Path 506" d="M402.367,1279h5.866a3.067,3.067,0,0,1,3.366,3.381v7.231a3.056,3.056,0,0,1-3.366,3.388h-5.866a3.072,3.072,0,0,1-3.367-3.388v-7.231A3.083,3.083,0,0,1,402.367,1279Zm.189,3.262v-.007h2.092a.541.541,0,0,1,.388.161.549.549,0,0,1,0,.777.541.541,0,0,1-.388.161h-2.092a.546.546,0,0,1,0-1.092Zm0,4.256h5.488a.546.546,0,0,0,0-1.092h-5.488a.546.546,0,0,0,0,1.092Zm0,3.2h5.488a.531.531,0,0,0,.35-.182.55.55,0,0,0,0-.735.531.531,0,0,0-.35-.182h-5.488a.5.5,0,0,0-.3.056.5.5,0,0,0-.225.2.56.56,0,0,0,0,.588.541.541,0,0,0,.525.252Z" transform="translate(-399 -1279)" fill="#202529" fillRule="evenodd" />
                                                        </svg>
                                                        Fill Required Forms
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    ) : null}

                                    <div className="col-md-12 mt-4">
                                        <div className="profile-box position-relative">
                                            <div className="login-box d-flex align-items-center position-absolute top-0 start-0">
                                                <span
                                                    className={`status-indicator ${IndClientProfile?.status === "1" ? "active-indicator" : "inactive-indicator"
                                                        }`}
                                                ></span>
                                                <p className="ms-2 fs-14">
                                                    {IndClientProfile?.status === "1"
                                                        ? "Active"
                                                        : IndClientProfile?.status === "2"
                                                            ? "May Be"
                                                            : "Inactive"}
                                                </p>
                                            </div>
                                            <div className="position-absolute start-0 end-0 bottom-0">
                                                <div className="position-relative p-detail-box px-4 py-3 d-flex align-items-center justify-content-between flex-wrap align-items-center">
                                                    <div className="left-prof d-flex align-items-center mt-2">
                                                        <div className="dp-box me-2">
                                                            {IndClientProfile?.profile_picture ? (
                                                                // If the image exists, display the image
                                                                <img
                                                                    src={IndClientProfile?.profile_picture}
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                // If no image, display initials
                                                                <div

                                                                    style={{
                                                                        width: "60px",
                                                                        height: "60px",
                                                                        borderRadius: "100%",
                                                                        backgroundColor: "#b2c8ec", // Use any fallback color
                                                                        fontSize: "20px",
                                                                        color: '#77A0DF',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}
                                                                >
                                                                    {`${IndClientProfile?.first_name?.charAt(0).toUpperCase() || ""}${IndClientProfile?.last_name?.charAt(0).toUpperCase() || ""}`}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="dp-detail">
                                                            <h5 className="text-capitalize">{IndClientProfile?.first_name} {IndClientProfile?.last_name}</h5>
                                                            <span className="d-flex align-items-center">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.333" viewBox="0 0 20 18.333">
                                                                    <path id="Path_3524" data-name="Path 3524" d="M468.939,1224a5,5,0,0,1,3.576,1.507,5.182,5.182,0,0,1,1.485,3.636v8.046a5.182,5.182,0,0,1-1.485,3.636,5,5,0,0,1-3.576,1.507H459.06a5,5,0,0,1-3.575-1.507A5.183,5.183,0,0,1,454,1237.19v-8.046a5.212,5.212,0,0,1,.385-1.966,5.139,5.139,0,0,1,2.739-2.791,5.056,5.056,0,0,1,1.936-.387Zm1.591,6.661.08-.082a.8.8,0,0,0-.011-1.019.825.825,0,0,0-.529-.265.759.759,0,0,0-.3.041.857.857,0,0,0-.26.163L465,1233.167a1.547,1.547,0,0,1-2,0l-4.5-3.667a.771.771,0,0,0-.512-.153.739.739,0,0,0-.486.224.794.794,0,0,0-.073,1.018l.131.132,4.55,3.616a3.1,3.1,0,0,0,3.909,0Z" transform="translate(-454 -1224)" fill="#fff" fillRule="evenodd" />
                                                                </svg>
                                                                <p className="ms-2">{IndClientProfile?.email}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <a
                                                        onClick={() => showClientEditModal(true)}
                                                    >
                                                        <div className="write-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="59" viewBox="0 0 58 59">
                                                                <defs>
                                                                    <filter id="Rectangle_241" x="0" y="0" width="58" height="59" filterUnits="userSpaceOnUse">
                                                                        <feOffset dy="10" input="SourceAlpha" />
                                                                        <feGaussianBlur stdDeviation="3" result="blur" />

                                                                        <feFlood floodOpacity="0.161" />
                                                                        <feComposite operator="in" in2="blur" />
                                                                        <feComposite in="SourceGraphic" />
                                                                    </filter>
                                                                </defs>
                                                                <g id="Group_678" data-name="Group 678" transform="translate(-1819 -366)">
                                                                    <g transform="matrix(1, 0, 0, 1, 1819, 366)" filter="url(#Rectangle_241)">
                                                                        <rect id="Rectangle_241-2" data-name="Rectangle 241" width="40" height="40" rx="12" transform="translate(9)" fill="#fff" />
                                                                    </g>
                                                                    <g id="write" transform="translate(1830.001 368)">
                                                                        <path id="Path_3528" data-name="Path 3528" d="M8.427,29.354l-.42,3.473a1.044,1.044,0,0,0,1.04,1.182A1.026,1.026,0,0,0,9.181,34l3.473-.42a3.31,3.31,0,0,0,1.928-.947l9-9a.417.417,0,0,0,0-.589l-4.618-4.622a.417.417,0,0,0-.589,0l-9,9a3.31,3.31,0,0,0-.947,1.928Z" transform="translate(0 -6.009)" fill="#024fc5" />
                                                                        <path id="Path_3529" data-name="Path 3529" d="M44.186,10.787,42.032,8.633a2.16,2.16,0,0,0-3.055,0l-2.022,2.023a.417.417,0,0,0,0,.589l4.62,4.62a.417.417,0,0,0,.589,0l2.023-2.023A2.16,2.16,0,0,0,44.186,10.787Z" transform="translate(-16.82 0)" fill="#024fc5" />
                                                                        <path id="Path_3530" data-name="Path 3530" d="M40.25,52.833A.833.833,0,0,0,39.417,52H29.833a.833.833,0,0,0,0,1.667h9.583A.833.833,0,0,0,40.25,52.833Z" transform="translate(-12.251 -25.667)" fill="#024fc5" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-12 mt-4">
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425">
                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2 cursor-pointer">
                                                    <h6 className="fs-20 fw-semibold">About</h6>
                                                    <img onClick={() => showClientEditModal(true)} src={require("../../../assets/images/edit-icon.png")} alt="" />
                                                </div>
                                            </div>
                                            <div className="scrollarea">
                                                <div className="row about-info p-0">
                                                    <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">
                                                            SIN
                                                            {IndClientProfile?.sin && (
                                                                isSinVisible ? (
                                                                    <img
                                                                        src={require("../../../assets/images/eye-hide.png")}
                                                                        className="cursor-pointer ms-2 w-14"
                                                                        alt="Hide SIN"
                                                                        onClick={toggleSinVisibility} // On click, hide the SIN
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={require("../../../assets/images/eye.png")}
                                                                        className="cursor-pointer ms-2 w-14"
                                                                        alt="Show SIN"
                                                                        onClick={toggleSinVisibility} // On click, show the SIN
                                                                    />
                                                                )
                                                            )}
                                                        </p>

                                                        <h4 className="fs-18">
                                                            {IndClientProfile?.sin
                                                                ? isSinVisible
                                                                    ? IndClientProfile.sin // Show full SIN if visible
                                                                    : IndClientProfile.sin.replace(/\d(?=\d{2})/g, "*") // Mask SIN except last 2 digits
                                                                : "--" // Show `--` if SIN is not available
                                                            }
                                                        </h4>
                                                    </div>

                                                    <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">Occupation Type</p>
                                                        <h4 className="fs-18">
                                                            {IndClientProfile?.occupation_category
                                                                ? Occupation_Category.getName(IndClientProfile.occupation_category) // Show the occupation category name
                                                                : "--" // Show `--` if no data is available
                                                            }
                                                        </h4>

                                                    </div>
                                                    <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">Date Of Landing</p>
                                                        <h4 className="fs-18">
                                                            {IndClientProfile?.date_of_landing
                                                                ? moment(IndClientProfile.date_of_landing).format('DD MMM, YYYY') // Format the date if available
                                                                : "--" // Show `--` if no date is available
                                                            }
                                                        </h4>


                                                    </div>
                                                    {/* <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">Client Type</p>
                                                        <h4 className="fs-18">Client</h4>
                                                    </div> */}
                                                    <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">Client Category</p>
                                                        <h4 className="fs-18">
                                                            {IndClientProfile?.client_category
                                                                ? Client_Category.getName(IndClientProfile.client_category) // Fetch and display the client category name
                                                                : "--" // Show `--` if no data is available
                                                            }
                                                        </h4>

                                                    </div>
                                                    <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">Residence Status</p>
                                                        <h4 className="fs-18">{IndClientProfile?.residency_status ? Residency_Status.getName(IndClientProfile?.residency_status) : "--"}</h4>
                                                    </div>
                                                    <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">Date of Birth</p>
                                                        <h4 className="fs-18">{IndClientProfile?.date_of_birth ? moment(IndClientProfile?.date_of_birth).format('DD MMM, YYYY') : "--"}</h4>
                                                    </div>
                                                    <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">Date of Marriage</p>
                                                        <h4 className="fs-18">{IndClientProfile?.date_of_marriage ? moment(IndClientProfile?.date_of_marriage).format('DD MMM, YYYY') : "--"}</h4>
                                                    </div>
                                                    <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">Marital Status</p>
                                                        <h4 className="fs-18">{IndClientProfile?.marital_status ? Marital_Status.getName(IndClientProfile?.marital_status) : "--"}</h4>
                                                    </div>
                                                    <div className="col-md-6 about-info-box mt-3">
                                                        <p className="fs-14">Family Head</p>
                                                        <h4 className="fs-18">{IndClientProfile?.family_head ? IndClientProfile?.family_head : "--"}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 mt-4">
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425">

                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                    <h6 className="fs-20 fw-semibold">Tasks</h6>
                                                    {/* <p className="text-blue cursor-pointer fs-14">View All</p> */}
                                                </div>
                                            </div>
                                            <div className="scrollarea pe-2">
                                                <div>
                                                    {taskList && taskList.length > 0 ? (
                                                        taskList.map((task) => (
                                                            <div onClick={() => handleEditClick(task.id)} style={{ cursor: "pointer" }} className="row task-info" key={task?.id}>
                                                                <div className="col-md-12 bg-white p-3 rounded-3 shadow_main my-2 d-flex align-items-center justify-content-between gap-2 ms-1 flex-wrap">
                                                                    <div>
                                                                        <h4 className="fs-18">{task?.name}</h4>
                                                                        <p className="fs-14">{formatDate(task?.created_at)}</p>
                                                                    </div>
                                                                    <span
                                                                        className={`${GeneralHelper.getTaskStatusColorName(
                                                                            task?.status_task_id
                                                                        )}_status cursor-pointer`}
                                                                    >
                                                                        {GeneralHelper.getTaskStatusName(task?.status_task_id)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">
                                                                    No Data found
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 mt-4">
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425">

                                            <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                <h6 className="fs-20 fw-semibold">Notes</h6>
                                                <img src={require("../../../assets/images/plus-icon.svg")} alt="" />
                                            </div>

                                            <div className="scrollarea">
                                                <div>
                                                    {notesList && notesList.length > 0 ? (
                                                        notesList.map((notes) => (
                                                            <div className="row notes-box" key={notes?.id}>
                                                                <div className="col-md-12 notes-info-box mt-3 d-flex align-items-start">
                                                                    <span></span>
                                                                    <div className="note-content ms-2">
                                                                        <h4 className="fs-18">{notes?.title}</h4>
                                                                        <p className="fs-14">{notes?.description}</p>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">
                                                                    No Data found
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="col-lg-4 col-md-6 col-sm-12 mt-4 imp-clients" style={{ position: "relative" }}>
                                        
                                        <div className="workprogress">
                                            <div className="tooltip-container">
                                                <span className="tooltip-text">Working in progress</span>
                                            </div>
                                        </div>

                                        <div className="bg-white shadow-lg rounded-4 p-3">
                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                    <h6 className="fs-20 fw-semibold">Important Clients</h6>
                                                    <a href="#"><img src={require("../../../assets/images/plus-icon.svg")} alt="" /></a>
                                                </div>
                                            </div>
                                            <div className="scrollarea">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <ul className="ps-0">
                                                            <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                                <div className="left-cl d-flex align-items-center gap-2 mt-1">
                                                                    <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                    <div className="mt-2">
                                                                        <h4 className="fs-18">Turner Homenick</h4>
                                                                        <p className="fs-14">Owner</p>
                                                                    </div>
                                                                </div>
                                                                <div className="right-cl mt-1">
                                                                    <img src={require("../../../assets/images/drop-arrow.svg")} alt="" />
                                                                    <img src={require("../../../assets/images/three-icon.svg")} alt="" />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-4 col-md-12 col-sm-12 mt-4">
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425" style={{ position: "relative" }}>
                                            <div className="workprogress">
                                                <div className="tooltip-container">
                                                    <span className="tooltip-text">Working in progress</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                    <h6 className="fs-20 fw-semibold">Integration</h6>
                                                    <a href="#"><img src={require("../../../assets/images/plus-icon.svg")} alt="" /></a>
                                                </div>
                                            </div>

                                            <div className="scrollarea">
                                                <div className="row mt-2">
                                                    <div className="col-lg-12 px-2">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="left-in d-flex align-items-center">
                                                                <img src={require("../../../assets/images/qb.svg")} alt="" />
                                                                <h4 className="fs-18 fw-semibold">QuickBooks Online</h4>
                                                            </div>
                                                            <span className="status-access">Access</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-4 col-md-6 col-sm-12 mt-4 imp-clients" style={{ position: "relative" }}>
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425" style={{ position: "relative" }}>
                                            <div className="workprogress">
                                                <div className="tooltip-container">
                                                    <span className="tooltip-text">Working in progress</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                    <h6 className="fs-20 fw-semibold">Linked Clients</h6>
                                                </div>
                                            </div>

                                            <div className="scrollarea">
                                                <div className="row">
                                                    <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                        <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                        <div className="note-content ms-2 mt-1">
                                                            <h4 className="fs-18">Missing w2</h4>
                                                            <p className="fs-14">Still waiting on Clients w2</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                        <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                        <div className="note-content ms-2 mt-1">
                                                            <h4 className="fs-18">Missing w2</h4>
                                                            <p className="fs-14">Still waiting on Clients w2</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                        <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                        <div className="note-content ms-2 mt-1">
                                                            <h4 className="fs-18">Missing w2</h4>
                                                            <p className="fs-14">Still waiting on Clients w2</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                        <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                        <div className="note-content ms-2 mt-1">
                                                            <h4 className="fs-18">Missing w2</h4>
                                                            <p className="fs-14">Still waiting on Clients w2</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                        <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                        <div className="note-content ms-2 mt-1">
                                                            <h4 className="fs-18">Missing w2</h4>
                                                            <p className="fs-14">Still waiting on Clients w2</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-4 col-md-12 col-sm-12 mt-4">
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425" >
                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                    <h6 className="fs-20 fw-semibold">Document Files</h6>
                                                    <a onClick={() => handleUploadFileClick()}>
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                            <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="scrollarea">
                                                <div className="row mt-2">
                                                    {uploadedFileList?.length > 0 ? (
                                                        uploadedFileList && uploadedFileList.map((file, index) => (
                                                            <div
                                                                onClick={() => {
                                                                    if (['pdf', 'jpg', 'png', 'xls', 'doc'].includes(file.extension.toLowerCase())) {
                                                                        // Open viewable file types (PDF, Images)
                                                                        window.open(file.path, "_blank");
                                                                    } else {
                                                                        // Force download for other file types
                                                                        const link = document.createElement("a");
                                                                        link.href = file.path;
                                                                        link.download = file.name; // Optional: Set a download name
                                                                        document.body.appendChild(link);
                                                                        link.click();
                                                                        document.body.removeChild(link);
                                                                    }
                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                                className="complete-box mt-3 p-3 rounded-4 shadow_main bg-white border-none gap-2 d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center gap-2 flex-wrap">
                                                                    <img
                                                                        src={fileIcons[file.extension] || fileIcons.default}
                                                                        alt={`${file.extension} icon`}
                                                                        width="60"
                                                                        height="60"
                                                                    />
                                                                    <div className="comp-text d-flex align-items-center flex-wrap justify-content-between gap-2">
                                                                        <div className="">
                                                                            <h5 className="fs-14 fw-medium text_ell">
                                                                                {file?.name}.{file?.extension}
                                                                            </h5>
                                                                            <span className="d-flex align-items-center gap-2">
                                                                                <p className="fs-12 text-muted">
                                                                                    Size: <br />{formatFileSize(file?.size)}
                                                                                </p>
                                                                                |
                                                                                <p className="fs-12 text-muted">
                                                                                    Uploaded: <br />{timeElapsed(file?.created_at)}
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="30"
                                                                    height="30"
                                                                    viewBox="0 0 30 30"


                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleFileDelete(file.id);
                                                                    }}
                                                                >
                                                                    <g
                                                                        id="Group_1055"
                                                                        data-name="Group 1055"
                                                                        transform="translate(16079 13588)"
                                                                    >
                                                                        <rect
                                                                            id="Rectangle_276"
                                                                            data-name="Rectangle 276"
                                                                            width="30"
                                                                            height="30"
                                                                            rx="8"
                                                                            transform="translate(-16079 -13588)"
                                                                            fill="#fad3d4"
                                                                        />
                                                                        <path
                                                                            id="Path_3653"
                                                                            data-name="Path 3653"
                                                                            d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z"
                                                                            transform="translate(-16469.299 -14914.999)"
                                                                            fill="#e5252a"
                                                                            fillRule="evenodd"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </div>


                                                        ))
                                                    ) : (
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-14 text-danger text-capitalize text-center">No Data Found</h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 mt-4">
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425">
                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                    <h6 className="fs-20 fw-semibold">Tags</h6>
                                                    <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                </div>
                                            </div>

                                            <div className="scrollarea">
                                                <div className="row">
                                                    <div className="col-md-12 px-2">
                                                        <div className="d-flex align-items-center flex-wrap gap-2 justify-content-center">
                                                            {tagList && tagList.length > 0 ? (
                                                                tagList.map((tags) => (
                                                                    <span className="status-access rounded-5 ms-0" key={tags.id}>
                                                                        {tags?.name}
                                                                    </span>
                                                                ))
                                                            ) : (
                                                                <div className="row contact-info mt-2 position-relative">
                                                                    <div className="col-md-12 other-info-box mt-2">
                                                                        <h4 className="fs-14 text-danger text-capitalize text-center">
                                                                            No Data found
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425">

                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                    <h6 className="fs-20 fw-semibold">Contact Details</h6>
                                                    <a onClick={() => showContactModal()}>
                                                        <svg
                                                            id="Layer_2"
                                                            data-name="Layer 2"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="28"
                                                            height="28"
                                                            viewBox="0 0 28 28"
                                                        >
                                                            <path
                                                                id="Path_3531"
                                                                data-name="Path 3531"
                                                                d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z"
                                                                transform="translate(-1 -1)"
                                                                fill="#212529"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="scrollarea">
                                                {Auth.innerPermission("individual_contact_view") || Auth.innerPermission("individual_contact_list") ? (
                                                    contactList?.length === 0 ? (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">
                                                                    No Data found
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        contactList?.map((clientList, index) => (
                                                            <div className="row" key={index}>
                                                                <div className="row contact-info mt-2" key={clientList?.id}>
                                                                    <div className="col-md-12">
                                                                        <div className="r_cont d-flex align-items-center justify-content-between gap-2">
                                                                            <h4 className="fs-18 fw-semibold">#1</h4>
                                                                            <div className="d-flex gap-2 align-items-center">
                                                                                <a onClick={() => showEditContactModal()}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28.001 28"><g id="write" transform="translate(-7.998 -8)"><path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529"></path><path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529"></path><path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529"></path></g></svg>
                                                                                </a>
                                                                                <a onClick={() => handleDeleteIconClick(clientList?.id)}>
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="18"
                                                                                        height="19"
                                                                                        viewBox="0 0 12.598 13.998"
                                                                                    >
                                                                                        <path
                                                                                            id="Path_3741"
                                                                                            data-name="Path 3741"
                                                                                            d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z"
                                                                                            transform="translate(-399 -1335)"
                                                                                            fill="#202529"
                                                                                        />
                                                                                    </svg>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-3">
                                                                        <p className="fs-14">Phone No.</p>
                                                                        <h4 className="fs-18 fw-semibold">{clientList?.phone_number ? clientList?.phone_number : "--"}</h4>
                                                                    </div>

                                                                    <div className="col-md-6 other-info-box mt-3">
                                                                        <p className="fs-14">Client Since</p>
                                                                        <h4 className="fs-18 fw-semibold">{clientList?.client_since ? clientList?.client_since : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-3">
                                                                        <p className="fs-14">Client Type</p>
                                                                        <h4 className="fs-18 fw-semibold">{clientList?.client_type ? clientList?.client_type : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-3">
                                                                        <p className="fs-14">Whatsapp Contact</p>
                                                                        <h4 className="fs-18 fw-semibold">{clientList?.whatsapp_contact ? clientList?.whatsapp_contact : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-3">
                                                                        <p className="fs-14">City</p>
                                                                        <h4 className="fs-18 fw-semibold">{clientList?.city ? clientList?.city : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-12 other-info-box mt-3">
                                                                        <p className="fs-14">Street Address</p>
                                                                        <h4 className="fs-18 fw-semibold">{clientList?.address ? clientList?.address : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-3">
                                                                        <p className="fs-14">Province</p>
                                                                        <h4 className="fs-18 fw-semibold">{clientList?.province ? clientList?.province : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-3">
                                                                        <p className="fs-14">Postal Code</p>
                                                                        <h4 className="fs-18 fw-semibold">{clientList?.postal_code ? clientList?.postal_code : "--"}</h4>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                ) : (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h6 className="fs-14 text-danger text-center fw-semibold text-capitalize mt-3">
                                                                You do not have permission to access this module.
                                                            </h6>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>



                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425">
                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                    <h6 className="fs-20 fw-semibold">Spouse</h6>
                                                    {/* {hasSpouseViewPermission() ? ( */}
                                                    {Auth.innerPermission("individual_spouse_view") ? (
                                                        spouseList?.length > 0 ? (
                                                            <div className="">
                                                                <a className="me-3"
                                                                    onClick={() => deleteSpouse()}
                                                                >
                                                                    <svg width="18" height="18" version="1.1" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg"><title /><desc /><defs /><g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#000000" id="Core" transform="translate(-299.000000, -129.000000)"><g id="delete" transform="translate(299.000000, 129.000000)"><path d="M1,16 C1,17.1 1.9,18 3,18 L11,18 C12.1,18 13,17.1 13,16 L13,4 L1,4 L1,16 L1,16 Z M14,1 L10.5,1 L9.5,0 L4.5,0 L3.5,1 L0,1 L0,3 L14,3 L14,1 L14,1 Z" id="Shape" /></g></g></g></svg>
                                                                </a>
                                                                <a onClick={() => showEditSpouse()}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28.001 28">
                                                                        <g id="write" transform="translate(-7.998 -8)">
                                                                            <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                            <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                            <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                        </g>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        ) : (
                                                            <a onClick={() => showSpouseModal()}>
                                                                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529"></path></svg>
                                                            </a>
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                            <div className="scrollarea">
                                                <>
                                                    {/* {hasSpouseViewPermission() ? ( */}
                                                    {Auth.innerPermission("individual_spouse_view") ? (
                                                        spouseList && spouseList.length > 0 ? (
                                                            spouseList.map((spouse) => (
                                                                <div className="row contact-info mt-2" key={spouse?.id}>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">Full Name</p>
                                                                        <h4 className="fs-18">{spouse?.first_name ? spouse?.first_name : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">Last Name</p>
                                                                        <h4 className="fs-18">{spouse?.last_name ? spouse?.last_name : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 about-info-box mt-2">
                                                                        <p className="fs-14">
                                                                            SIN
                                                                            {isSpouseSinVisible ? (
                                                                                <img
                                                                                    src={require("../../../assets/images/eye-hide.png")}
                                                                                    className="cursor-pointer ms-2 w-14"
                                                                                    alt="Hide SIN"
                                                                                    onClick={toggleSpouseSinVisibility}
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={require("../../../assets/images/eye.png")}
                                                                                    className="cursor-pointer ms-2 w-14"
                                                                                    alt="Show SIN"
                                                                                    onClick={toggleSpouseSinVisibility}
                                                                                />
                                                                            )}
                                                                        </p>
                                                                        <h4 className="fs-18">
                                                                            {spouse?.sin
                                                                                ? isSpouseSinVisible
                                                                                    ? spouse.sin
                                                                                    : spouse.sin.replace(/\d(?=\d{2})/g, "*")
                                                                                : "--"}
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">Email Address</p>
                                                                        <h4 className="fs-18">{spouse?.email ? spouse?.email : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">Phone No.</p>
                                                                        <h4 className="fs-18">{spouse?.contact_number ? spouse?.contact_number : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">Date of Birth</p>
                                                                        <h4 className="fs-18">{spouse?.date_of_birth ? spouse?.date_of_birth : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">Date of Landing</p>
                                                                        <h4 className="fs-18">{spouse?.date_of_landing ? spouse?.date_of_landing : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">Country of Residence</p>
                                                                        <h4 className="fs-18">{spouse?.country?.name ? spouse?.country?.name : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">City</p>
                                                                        <h4 className="fs-18">{spouse?.city ? spouse?.city : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-12 other-info-box mt-2">
                                                                        <p className="fs-14">Street Address</p>
                                                                        <h4 className="fs-18">{spouse?.address ? spouse?.address : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">Province</p>
                                                                        <h4 className="fs-18">{spouse?.province ? spouse?.province : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-6 other-info-box mt-2">
                                                                        <p className="fs-14">Postal Code</p>
                                                                        <h4 className="fs-18">{spouse?.postal_code ? spouse?.postal_code : "--"}</h4>
                                                                    </div>
                                                                    <div className="col-md-12 other-info-box mt-2">
                                                                        <p className="fs-14">Misc.</p>
                                                                        <h4 className="fs-18">{spouse?.mis_notes ? spouse?.mis_notes : "--"}</h4>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className="row contact-info mt-2 position-relative">
                                                                <div className="col-md-12 other-info-box mt-2">
                                                                    <h4 className="fs-14 text-danger text-capitalize text-center">No Data Found</h4>
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h6 className="fs-14 text-danger text-center fw-semibold text-capitalize mt-3">You do not have permission to access this module.</h6>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <div className="bg-white shadow-lg rounded-4 p-3 h_425">
                                            <div className="row">
                                                <div className="col-lg-12 d-flex align-items-center justify-content-between px-2">
                                                    <h6 className="fs-20 fw-semibold">Dependent Details</h6>
                                                    <a onClick={() => ShowDependent()}>
                                                        <svg
                                                            id="Layer_2"
                                                            data-name="Layer 2"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="28"
                                                            height="28"
                                                            viewBox="0 0 28 28"
                                                        >
                                                            <path
                                                                id="Path_3531"
                                                                data-name="Path 3531"
                                                                d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z"
                                                                transform="translate(-1 -1)"
                                                                fill="#212529"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>

                                            {/* Dependent detail box */}
                                            <div className="scrollarea">
                                                {/* {hasDependentViewPermission() ? ( */}
                                                {Auth.innerPermission("individual_dependent_view") || Auth.innerPermission("individual_dependent_list") ? (
                                                    dependentList.length === 0 ? (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">
                                                                    No Data found
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        dependentList.map((dependent, index) => (
                                                            <div className="row" key={index}>
                                                                <div className="col-lg-12 d_leftcont d-flex align-items-center justify-content-between gap-2 flex-wrap mt-3">
                                                                    <div className="l_cont d-flex align-items-center gap-2">
                                                                        <div
                                                                            style={{
                                                                                width: "60px",
                                                                                height: "60px",
                                                                                borderRadius: "100%",
                                                                                backgroundColor: "#b2c8ec", // Use any fallback color
                                                                                fontSize: "20px",
                                                                                color: '#77A0DF',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center'
                                                                            }}
                                                                        >
                                                                            {`${dependent?.first_name?.charAt(0).toUpperCase() || ""}${dependent?.last_name?.charAt(0).toUpperCase() || ""}`}
                                                                        </div>
                                                                        <span>
                                                                            <h4 className="fs-18 fw-semibold">
                                                                                {dependent?.first_name} {dependent?.last_name}
                                                                            </h4>
                                                                        </span>
                                                                    </div>
                                                                    <div className="r_cont d-flex align-items-center gap-2">
                                                                        <a onClick={() => handleEditIconClick(dependent?.client_id, dependent?.id)}>
                                                                            {/* Edit Icon */}
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28.001 28"><g id="write" transform="translate(-7.998 -8)"><path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529"></path><path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529"></path><path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529"></path></g></svg>
                                                                        </a>
                                                                        <a
                                                                            onClick={() => deleteDepended(dependent?.id)}
                                                                        >

                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="18"
                                                                                height="19"
                                                                                viewBox="0 0 12.598 13.998"
                                                                            >
                                                                                <path
                                                                                    id="Path_3741"
                                                                                    data-name="Path 3741"
                                                                                    d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z"
                                                                                    transform="translate(-399 -1335)"
                                                                                    fill="#202529"
                                                                                />
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                {/* Dependent Details */}
                                                                <div className="col-md-6 mt-3">
                                                                    <p className="fs-14">First Name</p>
                                                                    <h4 className="fs-18">{dependent?.first_name ? dependent?.first_name : "--"}</h4>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <p className="fs-14">Last Name</p>
                                                                    <h4 className="fs-18">{dependent?.last_name ? dependent?.last_name : "--"}</h4>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <p className="fs-14">Date Of Birth</p>
                                                                    <h4 className="fs-18">{dependent?.date_of_birth ? dependent?.date_of_birth : "--"}</h4>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <p className="fs-14">Relation With Client</p>
                                                                    <h4 className="fs-18">{dependent?.relation ? dependent?.relation : "--"}</h4>
                                                                </div>
                                                                <hr />
                                                            </div>
                                                        ))
                                                    )
                                                ) : (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h6 className="fs-14 text-danger text-center fw-semibold text-capitalize mt-3">You do not have permission to access this module.</h6>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- HOME TAB END --> */}

                            {/* <!-- COMMUNICATION TAB START --> */}
                            <div className="tab-pane" id="communication">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-md-7 d-flex align-items-center justify-content-between ">
                                        <div className="left_head">
                                            <h4 className="fs-24 mt-3">Communication</h4>
                                        </div>
                                        <div className="right_head mt-3">
                                            <a href="#" className="send_new text-white d-flex align-items-center justify-content-center">
                                                <i className="fa fa-plus me-2"></i>
                                                Add New
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- COMMUNICATION TAB END --> */}

                            {/* <!-- FILES TAB START --> */}
                            <div className="tab-pane" id="files">
                                <div className="row mt-2">
                                    <div className="col-md-12 left_head">
                                        <h4 className="fs-24 mt-3">Files</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- FILES TAB START --> */}

                            {/* <!-- TASKS TAB START --> */}
                            <div className="tab-pane" id="tasks">
                                <div className="row mt-2">
                                    <div className="col-md-12 left_head">
                                        <h4 className="fs-24 mt-3">Tasks</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- TASKS TAB START --> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* EDIT ABOUT */}
            <Modal
                show={editClientProfile}
                onHide={onHideHandler}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between align-items-start">
                    <h4 className="fs-20 fw-semibold">Edit Profile</h4>
                    <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmitProfile}>
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">First Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="first_name"
                                    value={editClientProfileData?.first_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Middle Name</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="middle_name"
                                    value={editClientProfileData?.middle_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Last Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="last_name"
                                    value={editClientProfileData?.last_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Email Address <span className="text-danger">*</span></label>
                                <input
                                    type="email"
                                    className="form-input mt-2"
                                    placeholder="lisa88@gmail.com"
                                    name="email"
                                    value={editClientProfileData?.email}
                                    disabled
                                />
                            </div>
                            {/* <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">SIN</label>

                                <div className="sin_area">
                                    <input
                                        type="password"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        name="sin"
                                        value={editClientProfileData?.sin}
                                        onChange={handleChange}
                                    />
                                    <div className="sin_icons">
                                        <i className="fa fa-eye"></i>
                                        <i className="fa fa-eye-slash"></i>
                                    </div>
                                </div>

                            </div> */}
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
                                <label className="fs-18">SIN</label>
                                <div className="sin_area">
                                    <input
                                        type={showSIN ? "text" : "password"} // Conditionally show or hide SIN
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        name="sin"
                                        maxLength={9}
                                        value={editClientProfileData?.sin}
                                        onChange={handleChange}
                                    />
                                    <div className="sin_icons">
                                        {showSIN ? (
                                            <i
                                                className="fa fa-eye-slash"
                                                onClick={toggleSINVisibility} // Hide SIN when clicked
                                            ></i>
                                        ) : (
                                            <i
                                                className="fa fa-eye"
                                                onClick={toggleSINVisibility} // Show SIN when clicked
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Date of Birth</label>
                                <input
                                    type="date"
                                    className="form-input mt-2"
                                    name="date_of_birth"
                                    max={new Date().toISOString().split("T")[0]}
                                    value={editClientProfileData?.date_of_birth}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Date of Landing</label>
                                <input
                                    type="date"
                                    className="form-input mt-2"
                                    name="date_of_landing"
                                    max={new Date().toISOString().split("T")[0]}
                                    value={editClientProfileData?.date_of_landing}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Date of Marriage</label>
                                <input
                                    type="date"
                                    className="form-input mt-2"
                                    name="date_of_marriage"
                                    max={new Date().toISOString().split("T")[0]}
                                    value={editClientProfileData?.date_of_marriage}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-3">
                                <label className="fs-16">Marital Status</label>
                                <select className="form-input mt-2" name="marital_status" value={editClientProfileData?.marital_status} onChange={handleChange}>
                                    <option value=""
                                        selected
                                        disabled="disabled">Select Marital Status</option>
                                    {Marital_Status && Marital_Status.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Marital Status Change Date</label>
                                <input
                                    type="date"
                                    className="form-input mt-2"
                                    name="marital_status_change_date"
                                    max={new Date().toISOString().split("T")[0]}
                                    value={editClientProfileData?.marital_status_change_date}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Office Location</label>
                                <PlacesAutocomplete
                                    value={address} // Replace with your state variable for the address
                                    onChange={handleChangeOfficeAddress} // Handle input change here
                                    onSelect={handleSelect} // Handle address selection here
                                    searchOptions={{
                                        componentRestrictions: {
                                            country: ["us", "ca"],
                                        },
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type address here...',
                                                    className: 'form-input mt-2 google-address',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                            key={suggestion.placeId}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Country</label>
                                <input type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="country"
                                    value={editClientProfileData?.country}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">City</label>
                                <input type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="city"
                                    value={editClientProfileData?.city}
                                    onChange={(e) =>
                                        setEditClientProfileData((prevState) => ({
                                            ...prevState,
                                            city: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Province</label>
                                <input type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="province"
                                    value={editClientProfileData?.province}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Postal Code</label>
                                <input type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="postal_code"
                                    value={editClientProfileData?.postal_code}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Primary Contact Person</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="primary_contact_person"
                                    value={editClientProfileData?.primary_contact_person}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Family Head</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="family_head"
                                    value={editClientProfileData?.family_head}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-3">
                                <label className="fs-16">Residancy Status</label>
                                <select className="form-input mt-2" name="residency_status" value={editClientProfileData?.residency_status} onChange={handleChange}>
                                    <option value=""
                                        selected
                                        disabled="disabled">Select</option>
                                    {Residency_Status && Residency_Status.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-3">
                                <label className="fs-16">Referred By Client/Other</label>
                                <div className="custom-select-container w-100">
                                    <div className="dropdown">
                                        <button
                                            className="form-input dropdown-toggle w-100 text-start mt-2"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {editClientProfileData?.refer_by_client
                                                ? (() => {
                                                    const selectedClient = showContactList.find(
                                                        (user) => user.id === parseInt(editClientProfileData.refer_by_client)
                                                    );
                                                    if (selectedClient) {
                                                        // Combine first_name and company_name for display
                                                        return selectedClient.first_name
                                                            ? `${selectedClient.first_name} ${selectedClient.company_name ? `(${selectedClient.company_name})` : ""
                                                            }`
                                                            : selectedClient.company_name || "Select";
                                                    }
                                                    return "Select";
                                                })()
                                                : "Select"}
                                        </button>
                                        <div className="dropdown-menu w-100 p-0">
                                            <ul className="p-0">
                                                <li>
                                                    <input
                                                        type="search"
                                                        className="form-input"
                                                        placeholder="Search Refer by Client..."
                                                        value={searchClientValue}
                                                        onChange={(e) => setSearchClientValue(e.target.value)}
                                                    />
                                                </li>
                                            </ul>
                                            <ul className="p-0 listing_show">
                                                {filteredClientList && filteredClientList.length > 0 ? (
                                                    filteredClientList.map((user) => (
                                                        <li key={user.id}>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => {
                                                                    // Update the editClientProfileData object with the selected client ID
                                                                    setEditClientProfileData((prevData) => ({
                                                                        ...prevData,
                                                                        refer_by_client: user.id,
                                                                    }));
                                                                    handleChange({
                                                                        target: { name: "refer_by_client", value: user.id },
                                                                    });
                                                                    setSearchClientValue(""); // Clear search input
                                                                }}
                                                            >
                                                                {user.first_name} {/* Show first_name */}
                                                                {user.company_name ? `${user.company_name}` : ""}
                                                            </button>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>
                                                        <button className="dropdown-item" type="button" disabled>
                                                            No Client Available
                                                        </button>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-3">
                                <label className="fs-16">Personal Account Manager</label>
                                <div className="custom-select-container w-100">
                                    <div className="dropdown">
                                        <button
                                            className="form-input dropdown-toggle w-100 text-start mt-2"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {editClientProfileData?.account_manager
                                                ? inviteUserList.find((user) => user.id === editClientProfileData.account_manager)?.email ||
                                                "Select an Account Manager"
                                                : "Select an Account Manager"}
                                        </button>
                                        <div className="dropdown-menu w-100 p-0">
                                            <ul className="p-0">
                                                <li>
                                                    <input
                                                        type="search"
                                                        className="form-input"
                                                        placeholder="Search Account Manager..."
                                                        value={searchAccountManagerValue}
                                                        onChange={(e) => setSearchAccountManagerValue(e.target.value)}
                                                    />
                                                </li>
                                            </ul>
                                            <ul className="p-0 listing_show">
                                                {filteredInviteUserList && filteredInviteUserList.length > 0 ? (
                                                    filteredInviteUserList.map((user) => (
                                                        <li key={user.id}>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => {
                                                                    handleChange({
                                                                        target: { name: "account_manager", value: user.id },
                                                                    });
                                                                    setSearchAccountManagerValue(""); // Clear search input
                                                                }}
                                                            >
                                                                {user.email}
                                                            </button>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>
                                                        <button className="dropdown-item" type="button" disabled>
                                                            No Account Managers Available
                                                        </button>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-3">
                                <label>
                                    Refer Staff
                                </label>
                                <select

                                    id="refer_by_staff"
                                    className="form-input mt-2"
                                    name="refer_by_staff"
                                    value={editClientProfileData?.refer_by_staff}
                                    onChange={handleChange}

                                >
                                    <option
                                        value=""
                                        selected
                                        disabled="disabled"
                                    >
                                        Select
                                    </option>

                                    <option value="1">johantest@elarafy.com</option>
                                    <option value="2">userstafftest@elarafy.com</option>
                                    <option value="3">testskt skt</option>
                                    <option value="4">prince@savetax.com</option>
                                    <option value="5">test  staff40</option>
                                    <option value="6">Staff35 Prince (PP) </option>
                                    <option value="7">sanjeevstaff elarafy</option>
                                    <option value="8">demotest@elarafy.com</option>
                                    <option value="9">thudemostaff@elarafy.com</option>
                                    <option value="10">checkingstaff@elarafy.com</option>
                                    <option value="11">ddd@elarafy.com</option>
                                    <option value="12">dalvir@elarafy.com</option>
                                    <option value="13">testing2@gmail.com</option>
                                    <option value="14">testing3@gmail.com</option>
                                    <option value="15">superadmin@elarafy.com</option>
                                    <option value="16">testing1@gmail.com</option>

                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-3">
                                <label className="fs-16">Client Category</label>
                                <select className="form-input mt-2"
                                    name="client_category"
                                    value={editClientProfileData?.client_category}
                                    onChange={handleChange}

                                >
                                    <option value=""
                                        selected
                                        disabled="disabled">Select</option>
                                    {Client_Category && Client_Category.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-16">Occupation Category</label>
                                <select className="form-input mt-2" name="occupation_category"
                                    value={editClientProfileData?.occupation_category} onChange={handleChange}>
                                    <option value=""
                                        selected
                                        disabled="disabled">Select</option>
                                    {Occupation_Category && Occupation_Category.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-16">Self-Employed HST #</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="self_employed_hst_no"
                                    value={editClientProfileData?.self_employed_hst_no}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-3">
                                <label className="fs-16">Gender</label>
                                <select className="form-input mt-2" name="gender" value={editClientProfileData?.gender} onChange={handleChange}>
                                    <option value=""
                                        selected
                                        disabled="disabled">Select</option>
                                    {Gender && Gender.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-3">
                                <label htmlFor="first-name">Status</label>
                                <select
                                    name="status"
                                    id="status"
                                    className="form-input mt-2"
                                    // value={editClientProfileData?.status}
                                    // onChange={handleChange}
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}

                                >
                                    <option
                                        value=""
                                        selected
                                        disabled="disabled"
                                    >
                                        Status
                                    </option>
                                    <option value="1">active</option>
                                    <option value="0">inactive</option>
                                    <option value="2">May be</option>
                                </select>
                            </div>
                            <div className="col-md-12 mt-1 p-0">
                                <div className="row">
                                    {/* <div className="col-lg-6 mt-3">
                                        <label htmlFor="first-name">Upload ID Proof</label>
                                        <div className="upload-input position-relative mt-2">
                                            <div className="main-wrapper">
                                               
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper justify-content-center add-ind cursor-pointer">
                                                        <input
                                                            type="file"
                                                            name="id_proof"
                                                            accept=".pdf,.doc,.docx,.xls,.xlsx"
                                                            onChange={onChangeIdProof}
                                                            id="file-upload"  // Give it an ID for reference
                                                        />
                                                        <label htmlFor="file-upload" className="file-upload-text cursor-pointer justify-content-center">
                                                            <i className="fa fa-upload me-2 fs-16 text-white"></i>
                                                            <span className="fs-16 text-white">Browse</span>
                                                        </label>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">Drag & drop here</h4>
                                                    <p className="upload-description">
                                                        Supports: PDF, DOC, XLS | Max Size: 10 MB
                                                    </p>
                                                </div>
                                                {(editClientProfileData?.id_proof && !uploadedFiles.length) && (
                                                    <div className="uploaded-files-list">
                                                        <div className="d-flex align-items-center justify-content-start gap-3">

                                                            <img
                                                                src={
                                                                    editClientProfileData.id_proof.endsWith(".pdf")
                                                                        ? require("../../../assets/images/PDF_file_icon.png")
                                                                        : editClientProfileData.id_proof.endsWith(".doc") || editClientProfileData.id_proof.endsWith(".docx")
                                                                            ? require("../../../assets/images/doc.png") // Replace with Word icon
                                                                            : editClientProfileData.id_proof.endsWith(".xls") || editClientProfileData.id_proof.endsWith(".xlsx") || editClientProfileData.id_proof.endsWith(".xlsm")
                                                                                ? require("../../../assets/images/xlsimage.png") // Replace with Excel icon
                                                                                : editClientProfileData.id_proof // Fallback to image URL for other types
                                                                }
                                                                alt="File Icon"
                                                                className="file-icon"
                                                                style={{ width: "40px", height: "40px" }}
                                                            />

                                                            <div className="file-info">
                                                                <div className="d-flex align-items-center justify-content-between gap-3">
                                                                    <div>
                                                                        <div className="file-name">Uploaded:</div>
                                                                        <div className="file-size mt-1">
                                                                            {editClientProfileData.id_proof.split("/").pop()} | Size: ~2 MB
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-end">

                                                                        <i
                                                                            className="fa fa-times fs-14 text-muted cursor-pointer"
                                                                            onClick={handleDeleteIDProof}
                                                                            title="Remove File"
                                                                        ></i>

                                                                        <div className="progress-percentage fs-12 text-black mt-1">100%</div>
                                                                    </div>
                                                                </div>


                                                                <div className="progress-container">
                                                                    <Line
                                                                        percent={100}
                                                                        strokeWidth="2"
                                                                        strokeColor="#007bff"
                                                                        trailWidth="2"
                                                                        trailColor="#d3d3d3"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}


                                                {uploadedFiles.length > 0 && (
                                                    <div className="uploaded-files-list">
                                                        {uploadedFiles.map(({ file, size }) => (
                                                            <div key={file.name} className="file-upload-item">
                                                                <div className="d-flex align-items-center justify-content-start gap-3">
                                                                    <img src={file.type.includes("pdf") ? require("../../../assets/images/PDF_file_icon.png") : require("../../../assets/images/Google_Docs_Logo_512px.png")
                                                                    } alt="File Icon" className="file-icon" />

                                                                    <div className="file-info">
                                                                        <div className="d-flex align-items-center justify-content-between gap-3">
                                                                            <div>
                                                                                <div className="file-name">Uploading: </div>
                                                                                <div className="file-size mt-1">{file.name}  |  Size: {size}</div>
                                                                            </div>
                                                                            <div className="text-end">
                                                                                <i className="fa fa-times fs-14 text-muted cursor-pointer" onClick={() => handleRemoveFile(file.name)} title="Remove File"></i>
                                                                                <div className="progress-percentage fs-12 text-black mt-1">{uploadProgress[file.name] || 60}%</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="progress-container">
                                                                            <Line
                                                                                percent={uploadProgress[file.name] || 0}
                                                                                strokeWidth="2"
                                                                                strokeColor="#007bff"
                                                                                trailWidth="2"
                                                                                trailColor="#d3d3d3"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-6 mt-3">
                                        <label htmlFor="first-name">Upload Profile Picture</label>
                                        <div className="upload-input position-relative mt-2 text-center">
                                            <div className="main-wrapper">
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper text-center add-ind cursor-pointer" onClick={() => fileInputRef.current.click()}>
                                                        <input
                                                            type="file"
                                                            name="profile_picture"
                                                            onChange={onChangePicture}
                                                            ref={fileInputRef}
                                                            style={{ display: "none" }}
                                                        />
                                                        <i className="fa fa-upload me-2"></i>
                                                        <span className="file-upload-text cursor-pointer">Browse</span>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">Drag & drop here</h4>
                                                    <p className="fs-14 text-muted">
                                                        Supports: JPEG, JPG, PNG
                                                        <br /> Max Size: 10 Mb
                                                    </p>
                                                </div>

                                                {/* Preview Section */}
                                                {picture ? (
                                                    <div className="upload-thumb text-center mt-4 d-flex align-items-center justify-content-center">
                                                        <div className="uploaded-img picture rounded-4 d-flex align-items-center position-relative">

                                                            {picture.type.includes("image") && (
                                                                <>
                                                                    <img
                                                                        src={URL.createObjectURL(picture)}
                                                                        alt="Uploaded"
                                                                        className="rounded-4"
                                                                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                                    />
                                                                </>
                                                            )}
                                                            <div
                                                                className="cancelimg"
                                                                onClick={() => handleDeletePicture()}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <i className="fa fa-times text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    editClientProfileData?.profile_picture && (
                                                        <div className="upload-thumb text-center mt-4 d-flex align-items-center justify-content-center">
                                                            <div className="uploaded-img picture rounded-4 d-flex align-items-center position-relative">
                                                                <img
                                                                    src={editClientProfileData.profile_picture}
                                                                    alt="Uploaded"
                                                                    className="rounded-4"
                                                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                                />
                                                                <div
                                                                    className="cancelimg"
                                                                    onClick={() => handleDeleteExistingPicture()}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <i className="fa fa-times text-white"></i>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-12 mt-4 mb-2">
                                <button type="submit" className="btn-blue ms-auto">Update</button>
                            </div>
                        </div>
                    </form>
                </div >
            </Modal >
            {/* EDIT ABOUT */}

            < Modal
                show={editContactModal}
                onHide={hideEditContact}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Edit Contact</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <form onSubmit={onContactSubmit}>
                        <div className="advance-form row">
                            <SearchField
                                source="contact"
                                selectedData={contactForm}
                                onDataSelect={handleContactSelect}
                            />
                            <div className="col-lg-4 col-md-6 mt-3">
                                <label className="fs-18">Phone No. <span className="text-danger">*</span></label>
                                <input
                                    type="number"
                                    name="phone_number"
                                    className="form-input mt-2"
                                    placeholder="Phone No."
                                    value={contactForm.phone_number}
                                    onChange={handleChangeContact}
                                />
                                {errorDepended.phone_number && <p className="text-danger mt-1">{errorDepended.phone_number}</p>}
                            </div>
                            <div className="col-lg-4 col-md-6 mt-3">
                                <label className="fs-18">Client Since <span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    name="client_since"
                                    className="form-input mt-2"
                                    value={contactForm.client_since}
                                    onChange={handleChangeContact}
                                />
                                {errorDepended.client_since && <p className="text-danger mt-1">{errorDepended.client_since}</p>}
                            </div>
                            <div className="col-lg-4 col-md-12 mt-3">
                                <label className="fs-18">Client Type <span className="text-danger">*</span></label>
                                <select
                                    name="client_type"
                                    id="client_type"
                                    className="form-input mt-2"
                                    value={contactForm.client_type || ""} // Dynamically set value from state
                                    onChange={handleChangeContact}
                                >
                                    <option value="" disabled>Select</option> {/* Default placeholder */}
                                    <option value="Client">Client</option>
                                    <option value="Director Only">Director Only</option>
                                </select>
                                {errorDepended.client_type && <p className="text-danger mt-1">{errorDepended.client_type}</p>}
                            </div>
                            <div className="col-lg-4 col-md-6 mt-3">
                                <label className="fs-18">Whatsapp No. <span className="text-danger">*</span></label>
                                <input
                                    type="number"
                                    name="whatsapp_contact"
                                    className="form-input mt-2"
                                    placeholder="Phone No."
                                    value={contactForm.whatsapp_contact}
                                    onChange={handleChangeContact}
                                />
                                {errorDepended.whatsapp_contact && <p className="text-danger mt-1">{errorDepended.whatsapp_contact}</p>}
                            </div>
                            <div className="col-lg-8 col-md-12 mt-3">
                                <label className="fs-18">Street Address</label>
                                <PlacesAutocomplete
                                    value={contactForm.address}
                                    onChange={(value) =>
                                        setContactForm({ ...contactForm, address: value })
                                    }
                                    onSelect={handleSelectContect}
                                    searchOptions={{
                                        componentRestrictions: { country: ["us", "ca"] },
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: "Type address here...",
                                                    className: "form-input mt-2 google-address",
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion)}
                                                        key={suggestion.placeId}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-3">
                                <label className="fs-18">City</label>
                                <input
                                    type="text"
                                    name="city" // Name should match the key in contactForm
                                    className="form-input mt-2"
                                    value={contactForm.city} // Bind state to the value
                                    onChange={handleContactChange} // Handle changes
                                />
                            </div>

                            <div className="col-lg-4 col-md-6 mt-3">
                                <label className="fs-18">Province</label>
                                <input
                                    type="text"
                                    name="province" // Name should match the key in contactForm
                                    className="form-input mt-2"
                                    value={contactForm.province} // Bind state to the value
                                    onChange={handleContactChange} // Handle changes
                                />
                            </div>

                            <div className="col-lg-4 col-md-6 mt-3">
                                <label className="fs-18">Postal Code</label>
                                <input
                                    type="text"
                                    name="postal_code" // Name should match the key in contactForm
                                    className="form-input mt-2"
                                    value={contactForm.postal_code} // Bind state to the value
                                    onChange={handleContactChange} // Handle changes
                                />
                            </div>

                            <div className="col-lg-12 mt-4 mb-2">
                                <button type="submit" className="btn-blue ms-auto">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal >

            <Modal
                show={editSpouse}
                onHide={hideEditSpouse}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Edit Spouse</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <form onSubmit={onSpouseSubmit}>
                        <div className="advance-form row">

                            <SearchField
                                source="spouse"
                                selectedData={spouseForm}
                                onDataSelect={handleSpouseSelect}
                            />
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    className="form-input mt-2"
                                    value={spouseForm.first_name}
                                    onChange={(e) => handleSpouseChange("first_name", e.target.value)}
                                />
                                {errorSpouse.first_name && <p className="error text-danger">{errorSpouse.first_name}</p>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Middle Name</label>
                                <input
                                    type="text"
                                    name="middle_name"
                                    id="middle_name"
                                    className="form-input mt-2"
                                    value={spouseForm.middle_name}
                                    onChange={(e) => handleSpouseChange("middle_name", e.target.value)}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Last Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    className="form-input mt-2"
                                    value={spouseForm.last_name}
                                    onChange={(e) => handleSpouseChange("last_name", e.target.value)}
                                />
                                {errorSpouse.last_name && <p className="error text-danger">{errorSpouse.last_name}</p>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">SIN</label>
                                <div className="sin_area">
                                    <input
                                        type={showSpouseSIN ? "text" : "password"}
                                        name="sin"
                                        id="sin"
                                        className="form-input mt-2"
                                        maxLength={9}
                                        value={spouseForm.sin}
                                        onChange={(e) => handleSpouseChange("sin", e.target.value)}
                                    />
                                    <div className="sin_icons">
                                        {showSpouseSIN ? (
                                            <i
                                                className="fa fa-eye-slash"
                                                onClick={toggleSpouseSINVisibility} // Hide SIN when clicked
                                            ></i>
                                        ) : (
                                            <i
                                                className="fa fa-eye"
                                                onClick={toggleSpouseSINVisibility} // Show SIN when clicked
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Email Address <span className="text-danger">*</span></label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-input mt-2"
                                    value={spouseForm.email}
                                    onChange={(e) => handleSpouseChange("email", e.target.value)}
                                />
                                {errorSpouse.email && <p className="error text-danger">{errorSpouse.email}</p>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Phone No.</label>
                                <input
                                    type="text"
                                    name="contact_number"
                                    id="contact_number"
                                    className="form-input mt-2"
                                    value={spouseForm.contact_number}
                                    onChange={(e) => handleSpouseChange("contact_number", e.target.value)}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Date of Birth</label>
                                <input
                                    type="date"
                                    name="date_of_birth"
                                    id="date_of_birth"
                                    className="form-input mt-2"
                                    value={spouseForm.date_of_birth}
                                    onChange={(e) => handleSpouseChange("date_of_birth", e.target.value)}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Date of Landing</label>
                                <input
                                    type="date"
                                    name="date_of_landing"
                                    id="date_of_landing"
                                    className="form-input mt-2"
                                    value={spouseForm.date_of_landing}
                                    onChange={(e) => handleSpouseChange("date_of_landing", e.target.value)}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-3">
                                <label className="fs-18">Country of Residence</label>
                                <select
                                    className="form-input mt-2"
                                    name="country_id"
                                    id="country_id"
                                    value={spouseForm.country_id}
                                    onChange={(e) => handleSpouseChange("country_id", e.target.value)}

                                >
                                    <option value="0" disabled selected>Select</option>
                                    <option value="1" >Canada</option>
                                    <option value="2">USA</option>
                                    {/* <option value="3">India</option>
                                    <option value="4">Other</option> */}
                                </select>
                            </div>

                            <div className="col-lg-8 col-md-12 col-md-12 mt-3">
                                <label className="fs-18">Street Address</label>
                                <PlacesAutocomplete
                                    value={addressSpouse}
                                    onChange={setAddressSpouse} // Handle address input
                                    onSelect={handleSelectSpouse} // Handle address selection
                                    searchOptions={{
                                        componentRestrictions: { country: ['us', 'ca'] },
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type address here...',
                                                    className: 'form-input mt-2 google-address',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion)}
                                                        key={suggestion.placeId}
                                                    >
                                                        <span >{suggestion.description}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    className="form-input mt-2"
                                    value={citySpouse}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCitySpouse(value); // Update citySpouse state
                                        setSpouseForm((prev) => ({ ...prev, city: value })); // Update spouseForm state
                                    }}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Province</label>
                                <input
                                    className="form-input mt-2"
                                    name="province"
                                    id="province"
                                    value={provinceSpouse}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setProvinceSpouse(value); // Update provinceSpouse state
                                        setSpouseForm((prev) => ({ ...prev, province: value })); // Update spouseForm state
                                    }}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Postal Code</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    className="form-input mt-2"
                                    value={postalSpouse}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setPostalSpouse(value); // Update postalSpouse state
                                        setSpouseForm((prev) => ({ ...prev, postal_code: value })); // Update spouseForm state
                                    }}
                                />
                            </div>

                            <div className="col-lg-8 col-md-12 col-md-12 mt-3">
                                <label className="fs-18">Misc. Note</label>
                                <input
                                    type="text"
                                    name="mis_notes"
                                    id="mis_notes"
                                    className="form-input mt-2"
                                    value={spouseForm.mis_notes}
                                    onChange={(e) => handleSpouseChange("mis_notes", e.target.value)}
                                />
                            </div>
                            <div className="col-lg-12 mt-4 mb-2">
                                <button type="submit" className="btn-blue ms-auto">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                show={editDepentModal}
                onHide={hideEditDepentModal}
                centered
                size="lg"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Edit Dependent Details</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <form onSubmit={(e) => {
                    e.preventDefault(); // Prevent the default form submission behavior
                    // Call the function to update operation data
                    updateDependtentData(editDepentDetail?.client_id, editDepentDetail?.id);
                }}>
                    <div className="modal-body ">
                        <div className="spouse-form mt-2">
                            <div className="row mb-3">
                                <SearchField
                                    source="dependent"
                                    selectedData={editDepentDetail}
                                    onDataSelect={handleDependedSelect}
                                />

                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">First Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.first_name} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => {
                                            setEditDepentDetail({ ...editDepentDetail, first_name: e.target.value });
                                            setErrors({ ...error, first_name: '' }); // Clear error when the user starts typing
                                        }}
                                    />
                                    {error.first_name && <p className="text-danger mt-1">{error.first_name}</p>} {/* Show error message */}
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">Last Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.last_name} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => {
                                            setEditDepentDetail({ ...editDepentDetail, last_name: e.target.value });
                                            setErrors({ ...error, last_name: '' }); // Clear error when the user starts typing
                                        }}
                                    />
                                    {error.last_name && <p className="text-danger mt-1">{error.last_name}</p>} {/* Show error message */}
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">Middle Name</label>
                                    <input
                                        type="text"
                                        name="middle_name"
                                        id="middle_name"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.middle_name} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => setEditDepentDetail({ ...editDepentDetail, middle_name: e.target.value })}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">SIN</label>
                                    <div className="sin_area">
                                        <input
                                            type={showDependedSIN ? "text" : "password"}
                                            name="sin"
                                            id="sin"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            maxLength={9}
                                            value={editDepentDetail?.sin} // Assuming 'registration_date' is the property to be displayed
                                            onChange={(e) => setEditDepentDetail({ ...editDepentDetail, sin: e.target.value })}
                                        />
                                        <div className="sin_icons">
                                            {showDependedSIN ? (
                                                <i
                                                    className="fa fa-eye-slash"
                                                    onClick={toggleDependedSINVisibility} // Hide SIN when clicked
                                                ></i>
                                            ) : (
                                                <i
                                                    className="fa fa-eye"
                                                    onClick={toggleDependedSINVisibility} // Show SIN when clicked
                                                ></i>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">Date Of Birth</label>
                                    <input
                                        type="date"
                                        name="date_of_birth"
                                        id="date_of_birth"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        max={new Date().toISOString().split("T")[0]}
                                        value={editDepentDetail?.date_of_birth} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => setEditDepentDetail({ ...editDepentDetail, date_of_birth: e.target.value })}
                                    />
                                </div>
                                {/* <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">Relation</label>
                                    <input
                                        type="text"
                                        name="relation"
                                        id="relation"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.relation} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => setEditDepentDetail({ ...editDepentDetail, relation: e.target.value })}
                                    />
                                </div> */}

                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label htmlFor="relation">
                                        Relation
                                    </label>
                                    <select
                                        name="relation"
                                        id="relation"
                                        className="form-input mt-2"
                                        value={editDepentDetail?.relation} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => setEditDepentDetail({ ...editDepentDetail, relation: e.target.value })}
                                    >
                                        <option value=""
                                            selected
                                            disabled="disabled">Select</option>
                                        {Depended_list && Depended_list.getList().map((data) => (
                                            <option key={data.key} value={data.key}>
                                                {data.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-12 mt-4">
                                    <button type="submit" className="btn-blue ms-auto">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal show={showCustomFormModal} onHide={() => setShowCustomFormModal(false)} centered size="xl" contentClassName="mx-auto" className="modal modal-lg email-modal" backdrop="static" keyboard={false}>
                <div className="modal-header border-0 justify-content-between align-items-start">
                    <h4 className="fs-20 fw-semibold cursor-pointer">Fill Required Forms</h4>
                    <svg className="cursor-pointer" onClick={() => setShowCustomFormModal(false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path id="circle-xmark" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.707,14.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12l2.293,2.293Z" fill="#212529" />
                    </svg>
                </div>
                <div className="modal-body position-relative">
                    <form>
                        <div className="tabs-section mt-2">
                            <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                                <div className="p-2">
                                    <TabList>
                                        {currentTask?.links?.map((form, index) => (
                                            <Tab key={index}>{form.form_data?.length > 0 && form.form_data[0]?.title ? form.form_data[0].title : form.title || `Form ${index + 1}`}</Tab>
                                        ))}
                                    </TabList>
                                </div>
                                <div className="tabs-contentmain pb-3">
                                    {currentTask?.links?.map((form, index) => (
                                        <TabPanel key={index}>
                                            <div className="row p-0">
                                                {selectedForm?.id === form.id && surveyModels[index] && (
                                                    <div className="survey-container">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger mt-3 del-survey"
                                                        // onClick={() => deleteCustomForm()}
                                                        >
                                                            Delete Survey Form
                                                        </button>
                                                        <Survey model={surveyModels[index]} onComplete={handleCompleteSurvey} />
                                                    </div>
                                                )}
                                            </div>
                                        </TabPanel>
                                    ))}
                                </div>
                            </Tabs>
                        </div>
                    </form>
                </div>
            </Modal>
            <Add_Contact
                showcCntactModal={showcCntactModal}
                setShowContactModal={setShowContactModal}
                fetchConData={fetchConData}
            />
            <Spouse
                spouseModal={spouseModal}
                setSpouseModal={setSpouseModal}
                fetchSpouData={fetchSpouData}
                id={id}
            />
            <Dependent_Detail
                showDependentModal={showDependentModal}
                setShowDependentModal={setShowDependentModal}
                fetchData={fetchData}
            />
            <Delete
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onDelete={handleDeleteConfirm}
            />
            <Upload_Files
                open={openUploadFileModal}
                onClose={() => setOpenUploadFileModal(false)}
                title="Custom File Upload"
                buttonLabel="Upload"
                onFileUpload={handleFileUpload}
            />
        </>
    )
}
export default IndClientProfile;