import React, { useState, useEffect, useRef, useContext } from "react";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Marital_Status, Gender, Residency_Status, Client_Category, Occupation_Category, Refer_by_Client } from "../../../helpers/staticList";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Line } from 'rc-progress';
import { useDispatch, useSelector } from "react-redux";
import { fetchClientData } from "../../../reduxTool/features/client/clientSlice";
import { GlobalContext } from "../../../App";


const AddIndividualClient = () => {
    const { clientData } = useSelector((state) => state.clientSlice);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const selectedUserData = JSON.parse(localStorage.getItem("selectedUser"));
    const selectedContactData = JSON.parse(sessionStorage.getItem("contact_data"));
    const [picture, setPicture] = useState(null);
    const [inviteUserList, setInviteUserList] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customFormList, setCustomFormList] = useState([]);
    const [address, setAddress] = useState('');
    const [formState, setFormState] = useState({
        city: "",
        province: "",
        country: "",
        postal_code: "",
    });

    const [showContactList, setShowContactList] = useState([]);
    console.log(showContactList, "showContactList")
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
    const [userData, setUserData] = useState(null);
    const [searchEmpValue, setSearchEmpValue] = useState("");
    const [searchClientValue, setSearchClientValue] = useState("");
    const fileInputRef = useRef(null);

    const [showSIN, setShowSIN] = useState(false);


    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };


    const filteredEmpList = inviteUserList && inviteUserList.filter((data) =>
        data.email.toLowerCase().includes(searchEmpValue.toLowerCase())
    );
    const filteredClientList =
        showContactList &&
        showContactList.filter((data) =>
            (data.first_name || data.company_name) && searchClientValue // Check if either is valid and matches search
                ? (
                    (data.first_name?.toLowerCase() || "") +
                    " " +
                    (data.company_name?.toLowerCase() || "")
                ).includes(searchClientValue.toLowerCase())
                : true // Show all if no search value
        );




    console.log("Filtered Client List:", filteredClientList); // Debug filtered list
    const handleNewChange = (e) => {
        const { name, value } = e.target;

        // Update local state
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        // Update React Hook Form state
        setValue(name, value);
    };

    // Synchronize formState with watch (React Hook Form state)
    useEffect(() => {
        const subscription = watch((values) => {
            setFormState((prevState) => ({
                ...prevState,
                ...values,
            }));
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        const populateFormFields = (data) => {
            if (!data) {
                return;
            }
            try {
                Object.keys(data).forEach((key) => {
                    if (data[key]) {
                        if (key === "street_address") {
                            setAddress(data[key]);
                        } else if (key === "dob") {
                            setValue("date_of_birth", data[key].split("T")[0]);
                        } else {
                            setValue(key, data[key]);
                        }
                    }
                });
            } catch (error) {
                console.error("Error populating form fields:", error);
            }
        };

        try {
            const storedUserData = JSON.parse(localStorage.getItem("selectedUser"));
            const contactData = JSON.parse(sessionStorage.getItem("contact_data"));
            if (contactData) {
                populateFormFields(contactData);
            } else if (storedUserData) {
                populateFormFields(storedUserData);
            } else {
                console.warn("No data found in storage.");
            }
        } catch (error) {
            console.error("Error retrieving data from storage:", error);
        }
    }, [setValue]);

    const handleChange = (value) => {
        setAddress(value);
    };


    const handleSelect = async (value) => {
        setAddress(value);

        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;

                let selectedCity = "";
                let selectedProvince = "";
                let selectedCountry = "";
                let postalCode = "";

                // Extract the required address components
                addressComponents.forEach((component) => {
                    if (component.types.includes("locality")) {
                        selectedCity = component.long_name; // City
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        selectedProvince = component.long_name; // Province long name
                    }
                    if (component.types.includes("country")) {
                        selectedCountry = component.long_name; // Country
                    }
                    if (component.types.includes("postal_code")) {
                        postalCode = component.long_name; // Postal code
                    }
                });

                // Update form fields using both React Hook Form and local state
                setValue("address", formattedAddress); // Update address in the form
                setValue("city", selectedCity);
                setValue("province", selectedProvince);
                setValue("country", selectedCountry);
                setValue("postal_code", postalCode);

                setFormState((prevState) => ({
                    ...prevState,
                    city: selectedCity,
                    province: selectedProvince,
                    country: selectedCountry,
                    postal_code: postalCode,
                }));
            } else {
                console.warn("No results found for the selected address.");
            }
        } catch (error) {
            console.error(
                "Error selecting place:",
                error?.message || "No message",
                error?.stack || "No stack trace"
            );
        }
    };

    const onChangePicture = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Validate file type
            if (!file.type.startsWith("image/")) {
                alert("Only image files (JPEG, PNG) are allowed.");
                handleRemovePicture(); // Clear invalid input
                return;
            }

            // Validate file size
            if (file.size > MAX_FILE_SIZE) {
                alert("File size exceeds 10 MB limit.");
                handleRemovePicture(); // Clear large file input
                return;
            }

            setPicture(file); // Set the valid file to state
        }
    };

    // Handle removing the picture
    const handleRemovePicture = () => {
        setPicture(null); // Clear the picture state
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset the file input value to an empty string
        }
    };


    // const onChangeIdProof = (e) => {
    //     const file = e.target.files[0]; // Get the first file (single file upload)

    //     if (!file) return; // No file selected

    //     // Restrict image files
    //     if (file.type.startsWith("image/")) {
    //         alert(`${file.name} is an image file and is not allowed.`);
    //         return;
    //     }

    //     // Validate file size
    //     if (file.size > MAX_FILE_SIZE) {
    //         alert(`${file.name} exceeds the 10 MB size limit.`);
    //         return;
    //     }

    //     // Clear previously uploaded files and set the new file
    //     setUploadedFiles([{ file, size: (file.size / 1024).toFixed(1) + " KB" }]);

    //     // Simulate upload progress
    //     simulateUploadProgress(file);
    // };

    // const onChangeIdProof = (e) => {
    //     const files = Array.from(e.target.files); // Get all selected files

    //     const validFiles = files.filter((file) => {
    //         // Restrict image files
    //         if (file.type.startsWith("image/")) {
    //             alert(`${file.name} is an image file and is not allowed.`);
    //             return false;
    //         }

    //         // Validate file size
    //         if (file.size > MAX_FILE_SIZE) {
    //             alert(`${file.name} exceeds the 10 MB size limit.`);
    //             return false;
    //         }

    //         return true;
    //     });

    //     if (validFiles.length > 0) {
    //         // Append valid files to the existing uploaded files
    //         setUploadedFiles((prevFiles) => [
    //             ...prevFiles,
    //             ...validFiles.map((file) => ({
    //                 file,
    //                 size: (file.size / 1024).toFixed(1) + " KB",
    //             })),
    //         ]);

    //         // Simulate upload progress for each valid file
    //         validFiles.forEach((file) => simulateUploadProgress(file));
    //     }
    // };

    // const simulateUploadProgress = (file) => {
    //     let progress = 0;

    //     const interval = setInterval(() => {
    //         progress += 10; // Increment progress by 10%
    //         setUploadProgress((prev) => ({
    //             ...prev,
    //             [file.name]: progress,
    //         }));

    //         if (progress >= 100) {
    //             clearInterval(interval); // Stop progress simulation at 100%
    //         }
    //     }, 200); // Simulate progress every 200ms
    // };
    // const handleRemoveFile = (fileName) => {
    //     // Remove the file from the uploaded files list
    //     setUploadedFiles((prevFiles) => prevFiles.filter((fileObj) => fileObj.file.name !== fileName));

    //     // Remove the file from the upload progress tracking
    //     setUploadProgress((prevProgress) => {
    //         const updatedProgress = { ...prevProgress };
    //         delete updatedProgress[fileName];
    //         return updatedProgress;
    //     });
    // };

    const onChangeIdProof = (e) => {
        const file = e.target.files[0]; // Get the first file (single file upload)

        if (!file) return; // No file selected

        // Restrict image files
        if (file.type.startsWith("image/")) {
            alert(`${file.name} is an image file and is not allowed.`);
            return;
        }

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
            alert(`${file.name} exceeds the 10 MB size limit.`);
            return;
        }

        // Clear previously uploaded files and set the new file
        setUploadedFiles([{ file, size: (file.size / 1024).toFixed(1) + " KB" }]);

        // Simulate upload progress
        simulateUploadProgress(file);
    };

    const simulateUploadProgress = (file) => {
        let progress = 0;

        const interval = setInterval(() => {
            progress += 10; // Increment progress by 10%
            setUploadProgress((prev) => ({
                ...prev,
                [file.name]: progress,
            }));

            if (progress >= 100) {
                clearInterval(interval); // Stop progress simulation at 100%
            }
        }, 200); // Simulate progress every 200ms
    };

    const handleRemoveFile = () => {
        // Clear the uploaded file
        setUploadedFiles([]);
        setUploadProgress({});
    };
    const handleCheckboxCustomForm = (event) => {
        const value = parseInt(event.target.value, 10); // Parse the value as an integer

        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const handleCustomFormList = async () => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFormList(res?.data?.data);
                    setShowLoader(false)
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        } catch (error) {

        }
    };

    const handleIndividualSubmit = async () => {
        setShowLoader(true);
        const formData = new FormData();
        // Append profile picture, send null if not uploaded
        if (picture) {
            formData.append("profile_picture", picture);
        } else {
            formData.append("profile_picture", null);
        }

        // Append ID proofs, send null if no files are uploaded
        if (uploadedFiles.length > 0) {
            uploadedFiles.forEach(({ file }) => {
                formData.append("id_proof", file);
            });
        } else {
            formData.append("id_proof", null);
        }

        // Append form IDs from selectedOptions state
        selectedOptions.forEach(id => {
            formData.append('form_id[]', id);
        });
        if (selectedUserData?.id || selectedContactData?.id) {
            formData.append("contact_detail_id", selectedUserData?.id || selectedContactData?.id);
        }

        // Append other form data fields
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value);
        });

        // // Append address-related fields
        if (address) formData.append("office_location", address);

        http(
            {
                method: "POST",
                url: "add-individual",
                isSecure: true,
                contentType: "multipart/form-data",
                body: formData
            },
            (res) => {
                toast.success(res.single_message)
                reset();
                navigate(`/admin/client/individual_profile/${res?.data[0]?.id}`);
                setShowLoader(false);
                localStorage.removeItem("selectedUser");
                sessionStorage.removeItem("contact_data")
                // window.location.reload();
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );

    };

    // const fetchData = async () => {
    //     // setShowLoader(true);
    //     try {
    //         http(
    //             {
    //                 method: "GET",
    //                 url: `clients`,
    //                 isSecure: true,
    //             },
    //             (res) => {
    //                 setClientList(res?.data?.data)
    //             },

    //             (err) => {
    //                 // Handle errors according to the response
    //                 if (err.status === 401) {
    //                     toast.error(err.single_message);
    //                     setShowLoader(false);
    //                 } else if (err.status === 400) {
    //                     toast.error(err.single_message);
    //                     setShowLoader(false);
    //                 } else {
    //                     toast.error(err.single_message);
    //                     setShowLoader(false);
    //                 }
    //             }
    //         );
    //     } catch (err) {
    //         console.error("Unexpected Error:", err);
    //     }
    // };

    const fetchContactData = () => {
        http(
            {
                method: "GET",
                url: `v2/contact_detail`,
                isSecure: true,
            },
            (res) => {
                if (res?.data?.data) {
                    setShowContactList(res.data?.data); // Set the contact list data
                } else {
                    setShowContactList([]); // If no data, set an empty list
                }
            },
            (err) => {
                console.error("Error fetching contact data:", err);
                toast.error(err.single_message || "An error occurred.");
            }
        );
    };

    const handleInviteUserList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `lists/adminusers`,
                    isSecure: true,
                },
                (res) => {
                    setInviteUserList(res?.data?.data)
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleInviteUserList();
        fetchContactData();
    }, []);

    useEffect(() => {
        handleCustomFormList();
    }, []);

    const handleFileClick = (file) => {
        if (!(file instanceof File)) {
            console.error("Invalid file passed to handleFileClick");
            return;
        }

        const fileURL = URL.createObjectURL(file); // Create a Blob URL
        window.open(fileURL, "_blank"); // Open the Blob URL in a new tab
    };
    return (
        <div className="right_section font-poppins">
            <div className="individual-form-area py-3">
                <div className="row individual-head align-items-center">
                    <div className="col-md-12 my-2">
                        <h4 className="fs-20 fw-semibold">Add Individual Client</h4>
                    </div>
                    <div className="col-md-12">
                        <div className="main-form-area p-4 mt-3 bg-white shadow-lg">
                            <form
                                onSubmit={handleSubmit(handleIndividualSubmit)}
                            >
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <h6 className="fs-20 fw-semibold">Basic Details</h6>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">
                                            First Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("first_name", {
                                                required: true,
                                            })}
                                        />
                                        {errors.first_name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.first_name &&
                                            errors.first_name.type === "pattern" && (
                                                <p
                                                    role="alert"
                                                    className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                                >
                                                    At least add one alphabetic
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">
                                            Middle Name
                                        </label>
                                        <input
                                            type="text"
                                            name="middle_name"
                                            id="middle_name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("middle_name", {
                                            })}
                                        />
                                        {errors.middle_name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="middlename">
                                            Last Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("last_name", {
                                                required: true,
                                            })}
                                        />
                                        {errors.last_name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.last_name &&
                                            errors.last_name.type === "pattern" && (
                                                <p
                                                    role="alert"
                                                    className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                                >
                                                    At least add one alphabetic
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label className="fs-18">SIN</label>
                                        <div className="sin_area">
                                            <input
                                                type={showSIN ? "text" : "password"} // Conditionally show or hide SIN
                                                className="form-input mt-2"
                                                placeholder="Type here..."
                                                name="sin"
                                                maxLength={9}
                                                {...register("sin", {
                                                })}
                                            />
                                            <div className="sin_icons">
                                                {showSIN ? (
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        onClick={toggleSINVisibility} // Hide SIN when clicked
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-eye"
                                                        onClick={toggleSINVisibility} // Show SIN when clicked
                                                    ></i>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label for="email">Email Address <span className="text-danger">*</span></label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("email", {
                                                required: true,
                                                pattern: /\S+@\S+\.\S+/,
                                            })}
                                            disabled
                                        />
                                        {errors.email?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1-fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.email?.type === "pattern" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Incorrect email format
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Date of Birth
                                        </label>
                                        <input
                                            type="date"
                                            data-date-format="DD-MM-YYYY" id="date_of_birth"
                                            name="date_of_birth"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            max={new Date().toISOString().split("T")[0]}
                                            {...register("date_of_birth", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Date of Landing
                                        </label>
                                        <input
                                            type="date"
                                            id="date_of_landing"
                                            name="date_of_landing"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            max={new Date().toISOString().split("T")[0]}
                                            {...register("date_of_landing", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">
                                            Marital Status
                                        </label>
                                        <select
                                            name="marital_status"
                                            id="marital_status"
                                            className="form-input mt-2"
                                            {...register("marital_status", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select Marital Status</option>
                                            {Marital_Status && Marital_Status.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label>
                                            Date of Marriage
                                        </label>
                                        <input
                                            type="date"
                                            data-date-format="DD-MM-YYYY" id="date_of_marriage"
                                            name="date_of_marriage"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            max={new Date().toISOString().split("T")[0]}
                                            {...register("date_of_marriage", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Marital Status Change Date
                                        </label>
                                        <input
                                            type="date"
                                            id="marital_status_change_date"
                                            name="marital_status_change_date"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            max={new Date().toISOString().split("T")[0]} // Set today's date as the max
                                            {...register(
                                                "marital_status_change_date", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">
                                            Office Location
                                        </label>
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleChange}  // Handle input change here
                                            onSelect={handleSelect}  // Handle address selection here
                                            searchOptions={{
                                                componentRestrictions: {
                                                    country: ["us", "ca"],
                                                },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Type address here...',
                                                            className: 'form-input mt-2 google-address',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                    })}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label>
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            name="city"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            value={formState.city}
                                            onChange={handleNewChange}
                                            {...register("city", {})}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label>
                                            Country
                                        </label>
                                        <input
                                            type="text"
                                            name="country"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            value={formState.province}
                                            onChange={handleNewChange}
                                            {...register("province", {})}

                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label>
                                            Province
                                        </label>
                                        <input
                                            type="text"
                                            name="province"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            value={formState.country}
                                            onChange={handleNewChange}
                                            {...register("country", {})}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label>
                                            Postal Code
                                        </label>
                                        <input
                                            type="text"
                                            name="postal_code"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formState.postal_code}
                                            onChange={handleNewChange}
                                            {...register("postal_code", {})}

                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Primary Contact Person
                                        </label>
                                        <input
                                            type="text"
                                            id="primary_contact_person"
                                            name="primary_contact_person"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register(
                                                "primary_contact_person", {
                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label for="fristname">
                                            Family Head
                                        </label>
                                        <input
                                            type="text"
                                            id="family_head"
                                            name="family_head"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("family_head", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">
                                            Residency Status
                                        </label>
                                        <select
                                            name="residency_status"
                                            id="residency_status"
                                            className="form-input mt-2"
                                            {...register("residency_status", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Residency_Status && Residency_Status.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">
                                            Referred By Client/Other
                                        </label>

                                        <div className="custom-select-container w-100">
                                            <div className="dropdown">
                                                <button
                                                    className="form-input dropdown-toggle w-100 text-start mt-2"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {getValues("refer_by_client") && showContactList
                                                        ? (() => {
                                                            const selectedClient = showContactList.find((h) => h.id === parseInt(getValues("refer_by_client")));
                                                            if (selectedClient) {
                                                                // Combine first_name and company_name for display
                                                                return selectedClient.first_name
                                                                    ? `${selectedClient.first_name}${selectedClient.company_name ? ` (${selectedClient.company_name})` : ''}`
                                                                    : selectedClient.company_name || "Select";
                                                            }
                                                            return "Select";
                                                        })()
                                                        : "Select"}
                                                </button>
                                                <div className="dropdown-menu w-100 p-0">
                                                    <ul className="p-0">
                                                        <li>
                                                            <input
                                                                type="search"
                                                                className="form-input"
                                                                placeholder="Search client..."
                                                                value={searchClientValue}
                                                                onChange={(e) => setSearchClientValue(e.target.value)}
                                                            />
                                                        </li>
                                                    </ul>
                                                    <ul className="p-0 listing_show">
                                                        {filteredClientList &&
                                                            filteredClientList.map((data) => (
                                                                <li key={data.id}>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setValue("refer_by_client", data.id, {
                                                                                shouldValidate: true,
                                                                            }); // Set the selected value
                                                                            setSearchClientValue(""); // Clear search field
                                                                        }}
                                                                    >
                                                                        {data.first_name} {/* Show first_name */}
                                                                        {data.company_name ? `${data.company_name}` : ""} {/* Append company_name if available */}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">
                                            Personal Account Manager
                                        </label>
                                        <div className="custom-select-container w-100">
                                            <div className="dropdown">
                                                <button
                                                    className="form-input dropdown-toggle w-100 text-start mt-2"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {getValues("account_manager")
                                                        ? inviteUserList && inviteUserList.find((h) => h.id === parseInt(getValues("account_manager")))?.name || "Select"
                                                        : "Select"}
                                                </button>
                                                <div className="dropdown-menu w-100 p-0">
                                                    <ul className="p-0">
                                                        <li>
                                                            <input
                                                                type="search"
                                                                className="form-input"
                                                                placeholder="Search hst return..."
                                                                value={searchEmpValue}
                                                                onChange={(e) => setSearchEmpValue(e.target.value)}
                                                            />
                                                        </li>
                                                    </ul>
                                                    <ul className="p-0 listing_show">
                                                        {filteredEmpList && filteredEmpList.map((data) => (
                                                            <li key={data.id}>
                                                                <button
                                                                    className="dropdown-item"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setValue("account_manager", data.id, { shouldValidate: true });
                                                                        setSearchEmpValue(""); // Clear search field
                                                                    }}
                                                                >
                                                                    {data.email}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">
                                            Client Category
                                        </label>
                                        <select
                                            name="client_category"
                                            id="client_category"
                                            className="form-input mt-2"
                                            {...register("client_category", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Client_Category && Client_Category.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}

                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">
                                            Occupation Category
                                        </label>
                                        <select
                                            name="occupation_category"
                                            id="occupation_category"
                                            className="form-input mt-2"
                                            {...register(
                                                "occupation_category", {
                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Occupation_Category && Occupation_Category.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Self-Employed HST #
                                        </label>
                                        <input
                                            type="text"
                                            id="self_employed_hst_no"
                                            name="self_employed_hst_no"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register(
                                                "self_employed_hst_no", {



                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="first_name">Gender</label>
                                        <select
                                            name="gender"
                                            id="gender"
                                            className="form-input mt-2"
                                            {...register("gender", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Gender && Gender.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* <div className="col-lg-6 mt-3 h-au">
                                        <label htmlFor="first_name">Upload Documents</label>
                                        <div className="upload-input position-relative mt-2">
                                            <div className="main-wrapper">
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper justify-content-center add-ind cursor-pointer">
                                                        <input
                                                            type="file"
                                                            name="id_proof"
                                                            accept=".pdf,.doc,.docx,.xls,.xlsx"
                                                            onChange={onChangeIdProof}
                                                            id="file-upload" // Give it an ID for reference
                                                            multiple // Allow multiple file selection
                                                        />
                                                        <label htmlFor="file-upload" className="file-upload-text cursor-pointer justify-content-center">
                                                            <i className="fa fa-upload me-2 fs-16 text-white"></i>
                                                            <span className="fs-16 text-white">Browse</span>
                                                        </label>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">Drag & drop here</h4>
                                                    <p className="upload-description">
                                                        Supports: PDF, DOC, XLS | Max Size: 10 MB
                                                    </p>
                                                </div>

                                                {uploadedFiles.length > 0 && (
                                                    <div className="uploaded-files-list">
                                                        {uploadedFiles.map(({ file, size }) => (
                                                            <div
                                                                key={file.name}
                                                                className="file-upload-item"
                                                                onClick={() => handleFileClick(file)} // Pass the File object here
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <div className="d-flex align-items-center justify-content-start gap-3">
                                                                    <img
                                                                        src={
                                                                            file.name.endsWith(".pdf")
                                                                                ? require("../../../assets/images/PDF_file_icon.png")
                                                                                : file.name.endsWith(".xls") || file.name.endsWith(".xlsx") || file.name.endsWith(".xlsm")
                                                                                    ? require("../../../assets/images/xlsimage.png") // Excel file icon
                                                                                    : file.name.endsWith(".doc") || file.name.endsWith(".docx")
                                                                                        ? require("../../../assets/images/docimage.png") // Word file icon
                                                                                        : require("../../../assets/images/docimage.png") // Default icon for unsupported types
                                                                        }
                                                                        alt="File Icon"
                                                                        className="file-icon"
                                                                    />

                                                                    <div className="file-info">
                                                                        <div className="d-flex align-items-center justify-content-between gap-3">
                                                                            <div>
                                                                                <div className="file-name">{file.name}</div>
                                                                                <div className="file-size mt-1">{size}</div>
                                                                            </div>
                                                                            <div className="text-end">
                                                                                <i
                                                                                    className="fa fa-times fs-14 text-muted cursor-pointer"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation(); // Prevents the parent div's click event
                                                                                        handleRemoveFile(file.name);
                                                                                    }}
                                                                                    title="Remove File"
                                                                                ></i>
                                                                                <div className="progress-percentage fs-12 text-black mt-1">
                                                                                    {uploadProgress[file.name] || 0}%
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="progress-container">
                                                                            <Line
                                                                                percent={uploadProgress[file.name] || 0}
                                                                                strokeWidth="2"
                                                                                strokeColor="#007bff"
                                                                                trailWidth="2"
                                                                                trailColor="#d3d3d3"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-4 mt-3 h-au">
                                        <label htmlFor="first_name">Upload Profile Picture</label>
                                        <div className="upload-input position-relative mt-2 text-center">
                                            <div className="main-wrapper">
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper text-center add-ind cursor-pointer" onClick={() => fileInputRef.current.click()}>
                                                        <input
                                                            type="file"
                                                            name="profile_picture"
                                                            onChange={onChangePicture}
                                                            ref={fileInputRef}
                                                            style={{ display: "none" }}
                                                        />
                                                        <i className="fa fa-upload me-2"></i>
                                                        <span className="file-upload-text cursor-pointer">Browse</span>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">Drag & drop here</h4>
                                                    <p className="fs-14 text-muted">
                                                        Supports: JPEG, JPG, PNG
                                                        <br /> Max Size: 10 Mb
                                                    </p>
                                                </div>
                                                {picture && (
                                                    <div className="upload-thumb text-center mt-4 d-flex align-items-center justify-content-center">
                                                        <div className="uploaded-img picture rounded-4 d-flex align-items-center position-relative">
                                                            {picture.type.startsWith("image/") && (
                                                                <>
                                                                    <img
                                                                        src={URL.createObjectURL(picture)}
                                                                        alt="Uploaded"
                                                                        className="rounded-4"
                                                                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                                    />
                                                                </>
                                                            )}
                                                            <div
                                                                className="cancelimg"
                                                                onClick={handleRemovePicture}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <i className="fa fa-times text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="mt-3">
                                        <label htmlFor="Required Forms" className="inv-title text-black mb-2">Required Forms</label>
                                        {customFormList && customFormList.map((option) => (
                                            <div key={option.id} className="d-flex align-items-center gap-2 mt-2">
                                                <input
                                                    type="checkbox"
                                                    value={option.id}
                                                    checked={selectedOptions.includes(option.id)}
                                                    onChange={handleCheckboxCustomForm}
                                                />
                                                <label className="text-black fs-14">{option.data[0]?.title}</label>
                                            </div>
                                        ))}
                                    </div> */}
                                    <div className="col-md-12 individual-footer">
                                        <div className="btn-groups mt-4">
                                            {/* <button
                                                        type="button"
                                                        className="btn cancel me-2"
                                                    >
                                                        Reset
                                                    </button> */}
                                            <button
                                                type="submit"
                                                className="add-new ms-2 px-4"
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddIndividualClient;
