import React, { useState } from 'react';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SelectUser from '../../../feature/SelectUser';

const ToggleTableRows = ({ fileListing, setFileListing, handleFileListing }) => {
    const [activeRow, setActiveRow] = useState(null);
    const [activeType, setActiveType] = useState(null);
    const [activeSubfolder, setActiveSubfolder] = useState(null);
    const [selectedId, setSelectedId] = useState(null); // Selected Parent File


    console.log(selectedId, "selectedFileId")

    const [showModal, setShowModal] = useState(false);
    const fileIcons = {
        xls: require("../../../../assets/images/xlsimage.png"),
        pdf: require("../../../../assets/images/pdfimage.png"),
        doc: require("../../../../assets/images/docimage.png"),
        default: require("../../../../assets/images/imageplaceholder.png"), // Fallback for unknown extensions
    };
    const getBgClass = (extension) => {
        switch (extension) {
            case 'pdf': return 'pdf-bg';
            case 'xls': return 'xls-bg';
            case 'xlsx': return 'xls-bg';
            case 'doc': return 'files-bg';
            case 'docx': return 'files-bg';
            case 'jpeg': return 'jpg-bg';
            case 'jpg': return 'jpg-bg';
            default: return 'files-bg'; // Default class
        }
    };




    const toggleRow = (folderId) => {
        setActiveRow((prevRow) => (prevRow === folderId ? null : folderId)); // Close if clicked again, else open new
    };
    const toggleSubfolder = (subfolderId) => {
        setActiveSubfolder((prevSubfolder) => (prevSubfolder === subfolderId ? null : subfolderId));
    };


    // Handle Folder Click
    const handleFolderClick = (event, folderId, type) => {
        event.stopPropagation(); // Prevent event bubbling
        // setActiveRow(folderId); // Store selected folder ID
        setSelectedId(folderId)
        setActiveType(type);
        setShowModal(true); // Open the modal
    };




    const handleCloseModal = () => {
        setShowModal(false);
        // setActiveRow(null);
        setSelectedId(null)
    };


    return (
        <div className="table_part files_table">
            <table className="w-100 ">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date Added</th>
                        <th>Added by</th>
                        <th>Visible</th>
                    </tr>
                </thead>
                <tbody>
                    {fileListing.length > 0 ? (
                        fileListing.map((folder) => (
                            <Droppable key={folder.id} droppableId={`folder-${folder.id}`}>
                                {(provided) => (
                                    <>
                                        <tr
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className={activeRow === folder.id ? "clicked" : ""}
                                            onClick={() => toggleRow(folder.id)}
                                            style={{ cursor: 'pointer', backgroundColor: '#ffffff' }}
                                        >

                                            <td className='d-flex align-items-center gap-2'>
                                                <div data-toggle="toggle">
                                                    <svg className='rotate' xmlns="http://www.w3.org/2000/svg" width="10.004" height="12" viewBox="0 0 10.004 12">
                                                        <path
                                                            id="drop_down"
                                                            d="M4.87.36c-.06.05-.31.27-.51.47A21.523,21.523,0,0,0,.34,6.63,4.368,4.368,0,0,0,0,7.8a1.845,1.845,0,0,0,.22.9,1.8,1.8,0,0,0,.9.8,7.953,7.953,0,0,0,1.07.26,24.179,24.179,0,0,0,3.8.24,28.451,28.451,0,0,0,3.69-.21,8.654,8.654,0,0,0,1.33-.34,1.873,1.873,0,0,0,.72-.65,1.8,1.8,0,0,0,.27-.93v-.06a5.607,5.607,0,0,0-.41-1.32A21.49,21.49,0,0,0,7.62.83,5.081,5.081,0,0,0,7.07.34,1.832,1.832,0,0,0,6.01,0,1.873,1.873,0,0,0,4.87.37Z"
                                                            transform="translate(10.004) rotate(90)"
                                                            fill="#747475"
                                                        />
                                                    </svg>
                                                </div>
                                                {folder?.name}
                                            </td>
                                            <td>{moment(folder?.created_at).format("MMM DD, YYYY, hh:mm a")}</td>
                                            <td className='d-flex align-items-center gap-2'>
                                                {folder?.user?.profileImage ? (

                                                    <img
                                                        src={folder.user.profileImage}
                                                        alt={folder.user.name}
                                                        width="30"
                                                        height="30"
                                                        className="rounded-circle"
                                                        style={{ objectFit: "cover" }}
                                                    />
                                                ) : (

                                                    <div
                                                        className="user-initials rounded-circle d-flex align-items-center justify-content-center"
                                                        style={{
                                                            width: "30px",
                                                            height: "30px",
                                                            backgroundColor: "#ccc",
                                                            color: "#fff",
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        {folder?.user?.name?.slice(0, 2).toUpperCase()}
                                                    </div>
                                                )}
                                                {folder?.user?.name}
                                            </td>
                                            <td  >
                                                {folder?.status === 1 ? (
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        onClick={(event) => handleFolderClick(event, folder.id, 'folder')}
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-eye"
                                                        onClick={(event) => handleFolderClick(event, folder.id, 'folder')}
                                                    ></i>
                                                )}
                                            </td>

                                        </tr>
                                        {activeRow === folder.id && folder.children && folder.children.length > 0 && (
                                            folder.children.map((child) => (
                                                <React.Fragment key={child.id}>
                                                    <tr
                                                        key={child.id}
                                                        //   className="bg-gray-50"
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                        className={activeSubfolder === child.id ? "clicked" : ""}
                                                        onClick={() => toggleSubfolder(child.id)}
                                                        style={{
                                                            cursor: 'pointer', marginLeft: activeRow === folder.id ? "20px" : "0",
                                                            backgroundColor: "transparent",
                                                            boxShadow: "none",
                                                        }}
                                                    >
                                                        <td className='d-flex align-items-center gap-2 subrow-bg ms_20 w-100'>
                                                            <div data-toggle="toggle">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10.004" height="12" viewBox="0 0 10.004 12">
                                                                    <path
                                                                        id="drop_down"
                                                                        d="M4.87.36c-.06.05-.31.27-.51.47A21.523,21.523,0,0,0,.34,6.63,4.368,4.368,0,0,0,0,7.8a1.845,1.845,0,0,0,.22.9,1.8,1.8,0,0,0,.9.8,7.953,7.953,0,0,0,1.07.26,24.179,24.179,0,0,0,3.8.24,28.451,28.451,0,0,0,3.69-.21,8.654,8.654,0,0,0,1.33-.34,1.873,1.873,0,0,0,.72-.65,1.8,1.8,0,0,0,.27-.93v-.06a5.607,5.607,0,0,0-.41-1.32A21.49,21.49,0,0,0,7.62.83,5.081,5.081,0,0,0,7.07.34,1.832,1.832,0,0,0,6.01,0,1.873,1.873,0,0,0,4.87.37Z"
                                                                        transform="translate(10.004) rotate(90)"
                                                                        fill="#747475"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            {child?.name}
                                                        </td>
                                                        <td className='subrow-bg'>{moment(child?.created_at).format("MMM DD, YYYY, hh:mm a")}</td>
                                                        <td className='d-flex align-items-center gap-2 subrow-bg w-100'>
                                                            {child?.user?.profileImage ? (

                                                                <img
                                                                    src={child.user.profileImage}
                                                                    alt={child.user.name}
                                                                    width="30"
                                                                    height="30"
                                                                    className="rounded-circle"
                                                                    style={{ objectFit: "cover" }}
                                                                />
                                                            ) : (

                                                                <div
                                                                    className="user-initials rounded-circle d-flex align-items-center justify-content-center"
                                                                    style={{
                                                                        width: "30px",
                                                                        height: "30px",
                                                                        backgroundColor: "#ccc",
                                                                        color: "#fff",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                >
                                                                    {child?.user?.name?.slice(0, 2).toUpperCase()}
                                                                </div>
                                                            )}
                                                            {child?.user?.name}
                                                        </td>
                                                        <td className='subrow-bg' >

                                                            {child?.status === 1 ? (
                                                                <i
                                                                    className="fa fa-eye-slash"
                                                                    onClick={(event) => handleFolderClick(event, folder.id, 'folder')}
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className="fa fa-eye"
                                                                    onClick={(event) => handleFolderClick(event, folder.id, 'folder')}
                                                                ></i>
                                                            )}

                                                        </td>
                                                    </tr>
                                                    {activeSubfolder === child.id && (
                                                        child.files?.map((subFile) => (
                                                            <tr
                                                                key={subFile.id}
                                                                className={`$ {activeRow === folder.id ? getBgClass(subFile.extension) : "tr-none"} `}
                                                                style={{
                                                                    marginLeft: activeRow === folder.id ? "20px" : "0",
                                                                    backgroundColor: "transparent",
                                                                    boxShadow: "none",
                                                                }}
                                                                onClick={() => {
                                                                    if (['pdf', 'jpg', 'png', 'xls', 'doc'].includes(subFile.extension.toLowerCase())) {
                                                                        window.open(subFile.path, "_blank");
                                                                    } else {
                                                                        const link = document.createElement("a");
                                                                        link.href = subFile.path;
                                                                        link.download = subFile.name; // Optional: Set a download name
                                                                        document.body.appendChild(link);
                                                                        link.click();
                                                                        document.body.removeChild(link);
                                                                    }
                                                                }}
                                                            >
                                                                <td className={`d-flex align-items-center gap-2 newtd ms_40 w-100 ${getBgClass(subFile.extension)}`}>
                                                                    <div data-toggle="toggle">
                                                                        <img
                                                                            src={fileIcons[subFile.extension] || fileIcons.default}
                                                                            alt={`${subFile.extension} icon`}
                                                                            width="60"
                                                                            height="60"
                                                                        />
                                                                    </div>
                                                                    {subFile?.name}
                                                                </td>
                                                                <td className={`${getBgClass(subFile.extension)}`}>{moment(subFile?.created_at).format("MMM DD, YYYY, hh:mm a")}</td>
                                                                <td className={`${getBgClass(subFile.extension)} d-flex align-items-center gap-2 w_auto`}>
                                                                    {subFile?.user?.profileImage ? (
                                                                        <img
                                                                            src={subFile.user.profileImage}
                                                                            alt={subFile.user.name}
                                                                            width="30"
                                                                            height="30"
                                                                            className="rounded-circle"
                                                                            style={{ objectFit: "cover" }}
                                                                        />
                                                                    ) : (
                                                                        <div
                                                                            className="user-initials rounded-circle d-flex align-items-center justify-content-center"
                                                                            style={{
                                                                                width: "30px",
                                                                                height: "30px",
                                                                                backgroundColor: "#ccc",
                                                                                color: "#fff",
                                                                                fontWeight: "bold"
                                                                            }}
                                                                        >
                                                                            {subFile?.user?.name?.slice(0, 2).toUpperCase()}
                                                                        </div>
                                                                    )}
                                                                    {subFile?.user?.name}
                                                                </td>
                                                                <td className={`${getBgClass(subFile.extension)}`}   >
                                                                    {subFile?.status === 1 ? (
                                                                        <i
                                                                            className="fa fa-eye-slash"
                                                                            onClick={(event) => handleFolderClick(event, subFile.id, 'file')}
                                                                        ></i>
                                                                    ) : (
                                                                        <i
                                                                            className="fa fa-eye"
                                                                            onClick={(event) => handleFolderClick(event, subFile.id, 'file')}
                                                                        ></i>
                                                                    )}


                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </React.Fragment>
                                            ))
                                        )}
                                        {folder.files?.length > 0 &&
                                            folder.files.map((subFile) => (

                                                <tr
                                                    key={subFile.id}
                                                    className={`${activeRow === folder.id ? getBgClass(subFile.extension) : "tr-none"} `}
                                                    style={{
                                                        marginLeft: activeRow === folder.id ? "20px" : "0",
                                                        backgroundColor: "transparent",
                                                        boxShadow: "none",
                                                    }}
                                                    onClick={() => {
                                                        if (['pdf', 'jpg', 'png', 'xls', 'doc'].includes(subFile.extension.toLowerCase())) {
                                                            window.open(subFile.path, "_blank");
                                                        } else {
                                                            const link = document.createElement("a");
                                                            link.href = subFile.path;
                                                            link.download = subFile.name; // Optional: Set a download name
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                        }
                                                    }}
                                                >
                                                    <td className={`d-flex align-items-center gap-2 newtd ms_20 w-100 ${getBgClass(subFile.extension)}`}>
                                                        <div data-toggle="toggle">
                                                            <img
                                                                src={fileIcons[subFile.extension] || fileIcons.default}
                                                                alt={`${subFile.extension} icon`}
                                                                width="60"
                                                                height="60"
                                                            />
                                                        </div>
                                                        {subFile?.name}
                                                    </td>
                                                    <td className={`${getBgClass(subFile.extension)}`}>{moment(subFile?.created_at).format("MMM DD, YYYY, hh:mm a")}</td>
                                                    <td className={`${getBgClass(subFile.extension)} d-flex align-items-center gap-2 w_auto`}>
                                                        {subFile?.user?.profileImage ? (

                                                            <img
                                                                src={subFile.user.profileImage}
                                                                alt={subFile.user.name}
                                                                width="30"
                                                                height="30"
                                                                className="rounded-circle"
                                                                style={{ objectFit: "cover" }}
                                                            />
                                                        ) : (

                                                            <div
                                                                className="user-initials rounded-circle d-flex align-items-center justify-content-center"
                                                                style={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    backgroundColor: "#ccc",
                                                                    color: "#fff",
                                                                    fontWeight: "bold"
                                                                }}
                                                            >
                                                                {subFile?.user?.name?.slice(0, 2).toUpperCase()}
                                                            </div>
                                                        )}
                                                        {subFile?.user?.name}
                                                    </td>

                                                    <td className={`${getBgClass(subFile.extension)}`}  >
                                                        {subFile?.status === 1 ? (
                                                            <i
                                                                className="fa fa-eye-slash"
                                                                onClick={(event) => handleFolderClick(event, subFile.id, 'file')}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-eye"
                                                                onClick={(event) => handleFolderClick(event, subFile.id, 'file')}
                                                            ></i>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </>

                                )}
                            </Droppable>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="text-center text-muted">No folders available.</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <SelectUser show={showModal} onHide={handleCloseModal} activeRow={selectedId ? selectedId : activeRow} type={activeType} />
        </div >
    );
};

export default ToggleTableRows;
