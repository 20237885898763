import React, { useState, useContext, useEffect } from "react";
import BusinessTabs from "../../common/BusinessTabs";
import { DragDropContext } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { http } from "../../http/http";
import { GlobalContext } from "../../../App";
import Grids from "../Client/Files/Grids";
import AddFiles from "../Client/Files/AddFiles";
import BusinessFileListing from "../../Business/Files/BusinessFileListing";
import BusinessTableFileListing from "../../Business/Files/BusinessTableFileListing";
import BusinessUploadFile from "../../Business/Files/BusinessUploadFile";

const Business_Files = () => {
    const { setShowLoader } = useContext(GlobalContext);
    const businessStoredId = JSON.parse(localStorage.getItem("BusinessId"));
    const [folderName, setFolderName] = useState("");
    const [fileListing, setFileListing] = useState([]);
    const [uploadedFileList, setUploadedFileList] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [active, setActive] = useState("Grid");
    const [privacyOption, setPrivacyOption] = useState("1");


    const handleDropdownChange = (e) => {
        setPrivacyOption(e.target.value);
    };

    const handleInputChange = (e) => setFolderName(e.target.value);

    const addFolderHandle = () => {
        const payload = {
            name: folderName,
            // parent_id: 1, 
            model_type: "corporate",
            model_id: businessStoredId,
            status: privacyOption,
        };
        http({ method: "POST", url: `folders`, isSecure: true, body: payload, },
            (res) => {
                toast.success(res.single_message);
                handleFileListing();
                setFolderName("");
            },
            (err) => {
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };
    const handleFileListing = () => {
        const modelType = "corporate";
        try {
            http(
                {
                    method: "GET",
                    url: `folders?model_type=${modelType}&model_id=${businessStoredId}`,
                    isSecure: true,
                },
                (res) => {
                    setFileListing(res?.data?.data)
                }
            );
        } catch (error) {

        }
    };
    useEffect(() => {
        handleFileListing();
    }, []);

    const handleFileDropped = (folderId, fileIds) => {
        const payload = {
            file_ids: fileIds, // Send all selected file IDs
            target_folder_id: folderId,
        };

        http(
            { method: "POST", url: `move-files`, isSecure: true, body: payload },
            (res) => {
                toast.success("Files moved successfully!");
                setUploadedFileList((prevFiles) =>
                    prevFiles.filter((file) => !fileIds.includes(file.id))
                );
                setSelectedFiles([]); // Clear selected files after moving
            },
            (err) => {
                toast.error(err.single_message || "Error moving files");
            }
        );
    };
    const handleDragEnd = (result) => {
        const { source, destination, draggableId } = result;

        if (!destination) return;

        // Dragging from "completed-files" to "folders"
        if (
            source.droppableId === "completed-files" &&
            destination.droppableId.startsWith("folder-")
        ) {
            const folderId = parseInt(destination.droppableId.replace("folder-", ""));

            // Use selected files if they exist, otherwise use the dragged file
            const draggedFileId = parseInt(draggableId);
            const filesToMove = selectedFiles.length > 0 ? selectedFiles : [draggedFileId];

            handleFileDropped(folderId, filesToMove);
        }
    };
    return (


        <div className="right_section font-poppins">
            <div className="clients-detail-area py-3">
                <BusinessTabs />
                <DragDropContext onDragEnd={handleDragEnd}>
                    <div className="row files_area mt-2">
                        <div className="col-md-7 left_files p-0">
                            <div className="row">
                                <Grids active={active} setActive={setActive} />
                                <AddFiles
                                    folderName={folderName}
                                    privacyOption={privacyOption}
                                    handleClick={addFolderHandle}
                                    handleInputChange={handleInputChange}
                                    handleDropdownChange={handleDropdownChange}
                                />
                                <div className="col-lg-12"><hr className="mt-4 mb-0" /></div>
                            </div>
                            {active === "Grid" ? (
                                <BusinessFileListing fileListing={fileListing} setFileListing={setFileListing} handleFileListing={handleFileListing} />
                            ) : (
                                <BusinessTableFileListing fileListing={fileListing} />
                            )}
                            {/* <FileListing fileListing={fileListing} /> */}
                        </div>
                        <BusinessUploadFile uploadedFileList={uploadedFileList} setUploadedFileList={setUploadedFileList} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                    </div>
                </DragDropContext>
            </div>
        </div>

    )
}
export default Business_Files;