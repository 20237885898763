import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../http/http";
import { toast } from "react-toastify";
import { GlobalContext } from "../../App";

const SelectUser = ({ show, onHide, type, activeRow, folderFileList }) => {
    const { setShowLoader } = useContext(GlobalContext);
    const [userList, setUserList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [userPermissions, setUserPermissions] = useState({});
    const [initialUserList, setInitialUserList] = useState([]);



    // ✅ Fetch user list and set both states
    const handleUserList = async () => {
        http(
            {
                method: "POST",
                url: `user-file-permissions`,
                isSecure: true,
                body: {
                    model_type: type,
                    model_id: activeRow,
                },
            },
            (res) => {
                const fetchedUsers = res?.data?.data || [];
                setInitialUserList(fetchedUsers); // Keep full track
                setUserList(fetchedUsers); // Used for UI updates
            }
        );
    };

    // ✅ Handle permission update
    const handlePermissionChange = (userId, permissionType) => {
        setUserList((prevList) =>
            prevList.map((user) =>
                user.id === userId
                    ? {
                        ...user,
                        permissions: user.permissions.map((perm) =>
                            perm.value === permissionType
                                ? { ...perm, is_selected: !perm.is_selected }
                                : perm
                        ),
                    }
                    : user
            )
        );

        // Also update initialUserList to fully track changes
        setInitialUserList((prevList) =>
            prevList.map((user) =>
                user.id === userId
                    ? {
                        ...user,
                        permissions: user.permissions.map((perm) =>
                            perm.value === permissionType
                                ? { ...perm, is_selected: !perm.is_selected }
                                : perm
                        ),
                    }
                    : user
            )
        );
    };

    // ✅ Handle "Select All" toggle
    const handleSelectAll = () => {
        const allSelected = userList.every((user) =>
            user.permissions.every((perm) => perm.is_selected)
        );

        const updatedList = userList.map((user) => ({
            ...user,
            permissions: user.permissions.map((perm) => ({
                ...perm,
                is_selected: !allSelected,
            })),
        }));

        setUserList(updatedList);
        setInitialUserList(updatedList);
        setSelectAll(!selectAll);
    };

    // ✅ Handle form submission
    const addFolderHandle = () => {
        const permissionsPayload = initialUserList.map((user) => ({
            user_id: user.id,
            permission: user.permissions
                .filter((p) => p.is_selected)
                .map((p) => p.value),
        }));

        const payload = {
            model_type: type,
            model_id: activeRow,
            permissions: permissionsPayload,
        };

        http(
            { method: "POST", url: `file-permissions`, isSecure: true, body: payload },
            (res) => {
                toast.success(res.single_message);
                setShowLoader(false);
                onHide();
            },
            (err) => {
                toast.error(err.single_message);
                setShowLoader(false);
            }
        );
    };

    // ✅ Fetch users when modal opens
    useEffect(() => {
        if (show) handleUserList();
    }, [show]);



    return (
        <Modal show={show} onHide={onHide} centered className="font-poppins" backdrop="static" keyboard={false}>
            <div className="mainmodalarea">
                <div className="modal-header d-flex align-items-start gap-4 border-0 justify-content-between">
                    <div>
                        <h4 className="fs-20 fw-semibold">4 Files made visible</h4>
                        <p className="mt-1 text-grays">Would you like to inform any of your collaborators?</p>
                    </div>
                    <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                </div>
                <hr className="mx-4 mt-2" />
                <div className="modal-body">
                    <div className="select_all d-flex align-items-center gap-2 justify-content-between">
                        <h4 className="fs-18 fw-semibold">Select User</h4>
                        <div className="d-flex align-items-center gap-2">
                            <label htmlFor="select-all-toggle" className="fs-16 fw-semibold w-fit">Select All</label>
                            <div className="switch-field">
                                <input
                                    id="select-all-toggle"
                                    className="switch"
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                                <label htmlFor="select-all-toggle"></label>
                            </div>
                        </div>
                    </div>

                    <div className="table_part">
                        <div className="table-responsive mt-3" style={{ maxHeight: "400px", overflowY: "scroll" }}>
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Name</th>
                                        <th>View</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList.length > 0 ? (
                                        userList.map((user) => (
                                            <tr key={user.id}>
                                                <td>
                                                    <input type="checkbox" checked={user.permissions.every(p => p.is_selected)} />
                                                </td>
                                                <td>{user.name}</td>
                                                <td>
                                                    <input type="checkbox"
                                                        checked={user.permissions.some(p => p.value === 1 && p.is_selected)}
                                                        onChange={() => handlePermissionChange(user.id, 1)}
                                                    />
                                                </td>
                                                <td>
                                                    <input type="checkbox"
                                                        checked={user.permissions.some(p => p.value === 2 && p.is_selected)}
                                                        onChange={() => handlePermissionChange(user.id, 2)}
                                                    />
                                                </td>
                                                <td>
                                                    <input type="checkbox"
                                                        checked={user.permissions.some(p => p.value === 3 && p.is_selected)}
                                                        onChange={() => handlePermissionChange(user.id, 3)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : <tr><td colSpan="5" className="text-center text-muted">No users found</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="btn_group">
                        <button className='add-new mt-4 ms-auto' onClick={addFolderHandle}>Submit</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SelectUser;
