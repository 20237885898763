import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../http/http";
import { toast } from "react-toastify";
import { GlobalContext } from "../../App";

const CommonAddFileModal = ({ show, onHide, type, folderFileList, handleFolderFileListing }) => {
    const { setShowLoader } = useContext(GlobalContext);
    const storedId = JSON.parse(localStorage.getItem("clientId"));
    const businessStoredId = JSON.parse(localStorage.getItem("BusinessId"));
    const [folderName, setFolderName] = useState("");
    const [privacyOption, setPrivacyOption] = useState("1");

    const [error, setError] = useState("");
    // const handleInputChange = (e) => setFolderName(e.target.value);
    const handleInputChange = (e) => {
        setFolderName(e.target.value);
        setError(""); // Clear error message on input change
    };
    const handleDropdownChange = (e) => {
        setPrivacyOption(e.target.value);
    };
    const addFolderHandle = () => {

        if (!folderName.trim()) {
            setError("Folder name is required");
            return; // Don't submit if folder name is empty
        }
        const payload = {
            name: folderName,
            parent_id: folderFileList?.id,
            model_type: type === "corporate" ? "corporate" : "client",
            model_id: type === "corporate" ? businessStoredId : storedId,
            status: privacyOption,
        };
        http({ method: "POST", url: `folders`, isSecure: true, body: payload, },
            (res) => {
                toast.success(res.single_message);
                handleFolderFileListing();
                setFolderName("");
                onHide();
            },
            (err) => {
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="md"
            contentClassName="mx-auto p-3 h-auto"
            className="modal pin-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header p-0 justify-content-between align-items-center border-0">
                <h4 className="fs-20">Add Folder</h4>
                <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
            </div>

            <div className="services-form row">
                <div className="col-lg-12 mt-3 p-0">

                    <div className="modal-footer border-0 justify-content-center p-0">
                        <label htmlFor="name">Add Folder</label>
                        <input
                            type="text"
                            className="form-input mt-2"
                            value={folderName}
                            onChange={handleInputChange}
                            placeholder="Enter folder name"
                        />
                        {error && <div className="text-danger text-start fs-12 w-100">{error}</div>}
                        <label className="mt-2">Privacy</label>
                        <select
                            className="form-input mt-2"
                            value={privacyOption}
                            onChange={handleDropdownChange}
                        >
                            {/* <option value="0">Draft</option> */}
                            <option value="1">Share to Everyone</option>
                            <option disabled value="2">Private</option>
                        </select>

                    </div>
                    <button onClick={addFolderHandle} className="btn-blue ms-auto mt-3">Add</button>

                </div>
            </div>
        </Modal>
    )
}

export default CommonAddFileModal
