import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Add_NewHst from "./Add_NewHst";
import Add_NewHstStyle from "./Add_NewHstStyle";
import Add_HstOpen from "./Add_HstOpen";
import Add_ArcSetup from "./Add_ArcSteup";
import Add_Payroll from "./Add_Payroll";
import Add_ArcAccess from "./Add_ArcAccess";
import Add_AdvanceFees from "./Add_AdvanceFees";
import Add_Agreement from "./Add_Agreement";
import Add_AgreementReson from "./Add_AgreementReson";
import Add_ArcGrid from "./Add_ArcGrid";

const Services = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
        getValues
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { fetchSerData, servicesModal, setServicesModal, hstList, showHstList, hstStyleList,
        showHstStyle, showArsetupList, arcSetupList, showHstOpenList, hstOpenList, showPayRollList,
        payRollList, showArcAccessList, ArcAccessList, showAdvanceList, AdvanceFeeList, showAgreementList,
        AgreemetList, showArcGridList, arcGridList, showAgreementReasonList, AgreementReasonList } = props;
    const [serviceData, setServiceData] = useState([]);
    const [isHSTOpen, setIsHSTOpen] = useState(false);
    const [isHSTStyleOpen, setIsHSTStyleOpen] = useState(false);
    const [isHstOpenFirst, setIsHstOpenFirst] = useState(false);
    const [isArcOpen, setIsArcOpen] = useState(false);
    const [isPayRoolOpen, setIsPayRollOpen] = useState(false);
    const [isArcAccessOpen, setIsArcAccessOpen] = useState(false);
    const [isAdvanceFeeOpen, setIsAdvanceFeeOpen] = useState(false);
    const [isAgreementOpen, setIsAgreementOpen] = useState(false);
    const [isAgreementReasonOpen, setIsAgreementReasonOpen] = useState(false);
    const [isArcGridOpen, setIsArcGridOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customFormList, setCustomFormList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searchHstReturnValue, setSearchHstReturnValue] = useState('');
    const [searchHstStyleValue, setSearchHstStyleValue] = useState('');
    const [searchPayRollValue, setSearchPayRollValue] = useState('');
    const [searchArcSetupValue, setSearchArcSetupValue] = useState('');
    const [searchArcAccessValue, setSearchArcAccessValue] = useState('');
    const [searchArcGridValue, setSearchArcGridValue] = useState('');
    const [searchAgreementValue, setSearchAgreementValue] = useState('');
    const [searchAgreementReasonValue, setSearchAgreementReasonValue] = useState('');
    const [searchAgreementFeesValue, setSearchAgreementFeesValue] = useState('');

    const filteredHstOpenList = hstOpenList && hstOpenList.filter((data) =>
        data.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const filteredHstReturnList = hstList && hstList.filter((data) =>
        data.name.toLowerCase().includes(searchHstReturnValue.toLowerCase())
    );
    const filteredHstStyleList = hstStyleList && hstStyleList.filter((data) =>
        data.name.toLowerCase().includes(searchHstStyleValue.toLowerCase())
    );
    const filteredPayRollList = payRollList && payRollList.filter((data) =>
        data.name.toLowerCase().includes(searchPayRollValue.toLowerCase())
    );
    const filteredArcSetupList = arcSetupList && arcSetupList.filter((data) =>
        data.name.toLowerCase().includes(searchArcSetupValue.toLowerCase())
    );

    const filteredArcAccessList = ArcAccessList && ArcAccessList.filter((data) =>
        data.name.toLowerCase().includes(searchArcAccessValue.toLowerCase())
    );
    const filteredArcGridList = arcGridList && arcGridList.filter((data) =>
        data.name.toLowerCase().includes(searchArcGridValue.toLowerCase())
    );
    const filteredAgreementList = AgreemetList && AgreemetList.filter((data) =>
        data.name.toLowerCase().includes(searchAgreementValue.toLowerCase())
    );
    const filteredAgreementReasonList = AgreementReasonList
        ? AgreementReasonList.filter((data) =>
            data.name.toLowerCase().includes((searchAgreementReasonValue || "").toLowerCase())
        )
        : [];
    const filteredAgreementFeesList = AdvanceFeeList && AdvanceFeeList.filter((data) =>
        data.name.toLowerCase().includes(searchAgreementFeesValue.toLowerCase())
    );



    const handleCheckboxCustomForm = (event) => {
        const value = parseInt(event.target.value, 10); // Parse the value as an integer

        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const handleService = () => {


        // Ensure serviceData is an array or default to an empty array
        const existingServiceData = Array.isArray(serviceData) ? serviceData : [];

        // Create an array to hold the selected form IDs
        const formIds = [];

        // Populate formIds with the selected options
        selectedOptions.forEach((id) => {
            formIds.push(id);
        });

        const newUser = {
            ...formValues,
            form_id: formIds, // Append formIds to the newUser object
        };

        // Add the new user to the existing service data array
        const updatedServiceData = [...existingServiceData, newUser];

        http(
            {
                method: "POST",
                url: `corporate/service/${id}/add`,
                isSecure: true,
                body: updatedServiceData, // Send the updated service data array
            },
            (res) => {
                // Assuming the server returns the updated data
                setServiceData(res?.data?.data || []); // Ensure the data is an array
                toast.success(res.single_message);
                setServicesModal(false);
                reset();
                fetchSerData();
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                }
            }
        );
    };


    const handleCustomFormList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFormList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleCustomFormList();
    }, []);

    const onHideHandler = () => {
        setServicesModal(false);

    };
    return (
        <Modal
            show={servicesModal}
            onHide={onHideHandler}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal operating-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >


            <div className="modal-header border-0 justify-content-between align-items-start">
                <h4 className="fs-20 fw-semibold">Add Service Details</h4>
                <Modal.Header closeButton className="close-icon border-0 py-0"></Modal.Header>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit(handleService)}>
                    <div className="services-form row">
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="business_planing_id" className="fs-18">
                                HST Open<span className="text-danger">*</span>
                            </label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("hst_open_id")
                                            ? hstOpenList && hstOpenList.find((h) => h.id === parseInt(getValues("hst_open_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search hst open..."
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredHstOpenList && filteredHstOpenList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("hst_open_id", data.id, { shouldValidate: true });
                                                            setSearchValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsHstOpenFirst(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.hst_open_id && (
                                    <span className="error text-danger">{errors.hst_open_id.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="business_planing_id" className="fs-18">
                                HST Return<span className="text-danger">*</span>
                            </label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("hst_return_id")
                                            ? hstList && hstList.find((h) => h.id === parseInt(getValues("hst_return_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search hst return..."
                                                    value={searchHstReturnValue}
                                                    onChange={(e) => setSearchHstReturnValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredHstReturnList && filteredHstReturnList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("hst_return_id", data.id, { shouldValidate: true });
                                                            setSearchHstReturnValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsHSTOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.hst_open_id && (
                                    <span className="error text-danger">{errors.hst_open_id.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">HST Style <span className="text-danger">*</span></label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("hst_style_id")
                                            ? hstStyleList && hstStyleList.find((h) => h.id === parseInt(getValues("hst_style_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search hst return..."
                                                    value={searchHstStyleValue}
                                                    onChange={(e) => setSearchHstStyleValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredHstStyleList && filteredHstStyleList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("hst_style_id", data.id, { shouldValidate: true });
                                                            setSearchHstStyleValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsHSTStyleOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.hst_open_id && (
                                    <span className="error text-danger">{errors.hst_open_id.message}</span>
                                )}
                            </div>
                            {errors.hst_style_id && <span className="error">{errors.hst_style_id.message}</span>}
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <label className="fs-18">Payroll Account</label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("payroll_account_id")
                                            ? payRollList && payRollList.find((h) => h.id === parseInt(getValues("payroll_account_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search hst return..."
                                                    value={searchPayRollValue}
                                                    onChange={(e) => setSearchPayRollValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredPayRollList && filteredPayRollList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("payroll_account_id", data.id, { shouldValidate: true });
                                                            setSearchPayRollValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsPayRollOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.hst_open_id && (
                                    <span className="error text-danger">{errors.hst_open_id.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <label className="fs-18">ARC Setup</label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("arc_setup_id")
                                            ? arcSetupList && arcSetupList.find((h) => h.id === parseInt(getValues("arc_setup_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search hst return..."
                                                    value={searchArcSetupValue}
                                                    onChange={(e) => setSearchArcSetupValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredArcSetupList && filteredArcSetupList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("arc_setup_id", data.id, { shouldValidate: true });
                                                            setSearchArcSetupValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsArcOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.hst_open_id && (
                                    <span className="error text-danger">{errors.hst_open_id.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <label className="fs-18">ARC Access</label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("arc_accesses_id")
                                            ? ArcAccessList && ArcAccessList.find((h) => h.id === parseInt(getValues("arc_accesses_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search hst return..."
                                                    value={searchArcAccessValue}
                                                    onChange={(e) => setSearchArcAccessValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredArcAccessList && filteredArcAccessList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("arc_accesses_id", data.id, { shouldValidate: true });
                                                            setSearchArcAccessValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsArcAccessOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.hst_open_id && (
                                    <span className="error text-danger">{errors.hst_open_id.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-3">
                            <label className="fs-18">ARC Grid Saved</label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("arc_grid_id")
                                            ? arcGridList && arcGridList.find((h) => h.id === parseInt(getValues("arc_grid_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search hst return..."
                                                    value={searchArcGridValue}
                                                    onChange={(e) => setSearchArcGridValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredArcGridList && filteredArcGridList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("arc_grid_id", data.id, { shouldValidate: true });
                                                            setSearchArcGridValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsArcGridOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.hst_open_id && (
                                    <span className="error text-danger">{errors.hst_open_id.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 mt-3">
                            <label className="fs-18">ARC Details</label>
                            <textarea
                                name="arc_detail"
                                id="arc_detail"
                                className="mt-2"
                                cols="20"
                                rows="5"

                                {...register("arc_detail", {

                                })}
                            ></textarea>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <label className="fs-18">Agreement Signed</label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("agreement_sign_id")
                                            ? AgreemetList && AgreemetList.find((h) => h.id === parseInt(getValues("agreement_sign_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search hst return..."
                                                    value={searchAgreementValue}
                                                    onChange={(e) => setSearchAgreementValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredAgreementList && filteredAgreementList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("agreement_sign_id", data.id, { shouldValidate: true });
                                                            setSearchAgreementValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsAgreementOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 mt-3">
                            <label className="fs-18">Agreement Reason</label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("agreement_reason")
                                            ? AgreementReasonList &&
                                            AgreementReasonList.find(
                                                (h) => h.id === parseInt(getValues("agreement_reason"))
                                            )?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search agreement reason..."
                                                    value={searchAgreementReasonValue || ""} // Ensure value is always a string
                                                    onChange={(e) => setSearchAgreementValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredAgreementReasonList &&
                                                filteredAgreementReasonList.map((data) => (
                                                    <li key={data.id}>
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={() => {
                                                                setValue("agreement_reason", data.id, { shouldValidate: true });
                                                                setSearchAgreementReasonValue(""); // Clear search field
                                                            }}
                                                        >
                                                            {data.name}
                                                        </button>
                                                    </li>
                                                ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li>
                                                <p
                                                    className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                    onClick={() => setIsAgreementReasonOpen(true)} // Reset to an empty string
                                                >
                                                    + Add More
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-lg-4 col-md-6 mt-3">
                            <label className="fs-18">Advance Fee</label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("advance_fee")
                                            ? AdvanceFeeList && AdvanceFeeList.find((h) => h.id === parseInt(getValues("advance_fee")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search hst return..."
                                                    value={searchAgreementFeesValue}
                                                    onChange={(e) => setSearchAgreementFeesValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredAgreementFeesList && filteredAgreementFeesList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("advance_fee", data.id, { shouldValidate: true });
                                                            setSearchAgreementFeesValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsAdvanceFeeOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <label className="fs-18">Agreement Signing Amount</label>
                            <input
                                className="form-input mt-2"
                                // type="number"
                                name="advance_amount"
                                id="advance_amount"
                                onKeyPress={(e) => {
                                    // Prevent non-numeric input
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder="Type here..."
                                {...register("advance_amount", {

                                })}
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label className="fs-18">Reason If Not Signed</label>
                            <textarea
                                name="advance_reason"
                                id="advance_reason"
                                className="mt-2"
                                cols="20"
                                rows="5"
                                {...register("advance_reason", {

                                })}
                            ></textarea>
                        </div>
                        {/* <div className=" mt-3">
                            <label htmlFor="Required Forms" className="inv-title text-black mb-2">Required Forms</label>
                            {customFormList && customFormList.map((option) => (
                                <div key={option.id} className="d-flex align-items-center gap-2 mt-2">
                                    <input
                                        type="checkbox"
                                        value={option.id}
                                        checked={selectedOptions.includes(option.id)}
                                        onChange={handleCheckboxCustomForm}
                                    />
                                    <label className="text-black">{option.data[0]?.title}</label>
                                </div>
                            ))}
                        </div> */}
                        <div className="mcol-md-12 mt-3 mb-2">
                            <button type="submit" className="btn-blue ms-auto">Add</button>
                        </div>

                    </div>
                </form>
            </div>


            <Add_NewHst
                setIsHSTOpen={setIsHSTOpen}
                isHSTOpen={isHSTOpen}
                showHstList={showHstList}
            />
            <Add_NewHstStyle
                setIsHSTStyleOpen={setIsHSTStyleOpen}
                isHSTStyleOpen={isHSTStyleOpen}
                showHstStyle={showHstStyle}
            />
            <Add_HstOpen
                isHstOpenFirst={isHstOpenFirst}
                setIsHstOpenFirst={setIsHstOpenFirst}
                showHstOpenList={showHstOpenList}
            />
            <Add_ArcSetup
                isArcOpen={isArcOpen}
                setIsArcOpen={setIsArcOpen}
                showArsetupList={showArsetupList}
            />
            <Add_Payroll
                isPayRoolOpen={isPayRoolOpen}
                setIsPayRollOpen={setIsPayRollOpen}
                showPayRollList={showPayRollList}
            />
            <Add_ArcAccess
                isArcAccessOpen={isArcAccessOpen}
                setIsArcAccessOpen={setIsArcAccessOpen}
                showArcAccessList={showArcAccessList}
            />
            <Add_AdvanceFees
                isAdvanceFeeOpen={isAdvanceFeeOpen}
                setIsAdvanceFeeOpen={setIsAdvanceFeeOpen}
                showAdvanceList={showAdvanceList}
            />
            <Add_Agreement
                isAgreementOpen={isAgreementOpen}
                setIsAgreementOpen={setIsAgreementOpen}
                showAgreementList={showAgreementList}
            />
            <Add_AgreementReson
                isAgreementReasonOpen={isAgreementReasonOpen}
                setIsAgreementReasonOpen={setIsAgreementReasonOpen}
                showAgreementReasonList={showAgreementReasonList}
            />
            <Add_ArcGrid
                isArcGridOpen={isArcGridOpen}
                setIsArcGridOpen={setIsArcGridOpen}
                showArcGridList={showArcGridList}
            />

        </Modal>

    )
}
export default Services;