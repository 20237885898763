import React, { useState, useContext, useEffect } from 'react'
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../App";
import Delete from '../../feature/Delete';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Auth from '../../../auth/Auth';


const BusinessUploadFile = ({ uploadedFileList, setUploadedFileList, selectedFiles, setSelectedFiles, onFileDropped, onDragEnd }) => {
    const businessStoredId = JSON.parse(localStorage.getItem("BusinessId"));
    const { setShowLoader } = useContext(GlobalContext);
    const [fileLabel, setFileLabel] = useState("");
    const [fileExpireDate, setFileExpireDate] = useState("");
    const [fileList, setFileList] = useState([]); // Track uploaded files
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const getTomorrowDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1); // Add 1 day
        return tomorrow.toISOString().split("T")[0]; // Format as yyyy-mm-dd
    };
    const fileIcons = {
        xls: require("../../../assets/images/xlsimage.png"),
        pdf: require("../../../assets/images/pdfimage.png"),
        doc: require("../../../assets/images/docimage.png"),
        default: require("../../../assets/images/imageplaceholder.png"), // Fallback for unknown extensions
    };
    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes >= 1024 * 1024) {
            return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
        } else if (sizeInBytes >= 1024) {
            return `${(sizeInBytes / 1024).toFixed(2)} KB`;
        } else {
            return `${sizeInBytes} B`;
        }
    };
    const timeElapsed = (createdAt) => {
        const now = new Date();
        const createdDate = new Date(createdAt);
        const diff = now - createdDate; // Difference in milliseconds
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (seconds < 60) {
            return `${seconds} seconds ago`;
        } else if (minutes < 60) {
            return `${minutes} minutes ago`;
        } else if (hours < 24) {
            return `${hours} hours ago`;
        } else if (days < 30) {
            return `${days} days ago`;
        } else if (months < 12) {
            return `${months} months ago`;
        } else {
            return `${years} years ago`;
        }
    };
    const isAllSelected = uploadedFileList?.length > 0 && selectedFiles.length === uploadedFileList.length;
    const handleCheckboxChange = (fileId) => {
        setSelectedFiles((prevSelected) =>
            prevSelected.includes(fileId)
                ? prevSelected.filter((id) => id !== fileId) // Deselect file
                : [...prevSelected, fileId] // Select file
        );
    };
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            // Select all files
            const allFileIds = uploadedFileList && uploadedFileList.map((file) => file.id);
            setSelectedFiles(allFileIds);
        } else {
            // Deselect all files
            setSelectedFiles([]);
        }
    };

    const handleDeleteIconClick = (fileId) => {
        setSelectedFileId(fileId); // Set the task ID to be deleted
        setOpenDeleteModal(true); // Open the delete modal
    };
    const handleDeleteConfirm = () => {
        setOpenDeleteModal(false); // Close the modal
        handleFileDelete(selectedFileId); // Call the delete function
    };

    /** ✅ Handle File Selection */
    const handleFileUpload = async (event) => {
        const files = Array.from(event.target.files).map((file) => ({
            fileObject: file,
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2),
            type: file.type.split("/").pop().toUpperCase(),
            extension: file.name.split('.').pop().toLowerCase(),
            url: URL.createObjectURL(file),
            progress: 0,  // ✅ Initial progress set to 0
            status: "Pending",
        }));

        // ✅ Update UI with selected files
        setFileList((prevList) => [...prevList, ...files]);

        // ✅ Start uploading each file
        for (const file of files) {
            await handleUpload(file);
        }
    };

    /** ✅ Upload File with Progress Tracking */
    const handleUpload = (file) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            // formData.append("name", file.name);
            formData.append("model_type", "corporate");
            formData.append("model_id", businessStoredId);
            formData.append("status", 1);
            formData.append("file", file.fileObject);

            const xhr = new XMLHttpRequest();
            let url = process.env.REACT_APP_LOCAL_URL + "/files";

            xhr.open("POST", url, true);
            xhr.setRequestHeader("Accept", "application/json");
            const token = Auth.token();
            if (token) {
                xhr.setRequestHeader("Authorization", `Bearer ${token}`);
            }

            // ✅ Track Upload Progress (Handled by Browser)
            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = Math.round((event.loaded / event.total) * 100);
                    setFileList((prevList) =>
                        prevList.map((f) =>
                            f.fileObject === file.fileObject
                                ? { ...f, progress, status: progress === 100 ? "Completed" : "Uploading" }
                                : f
                        )
                    );
                }
            };

            // ✅ Handle Upload Completion
            xhr.onload = () => {
                if (xhr.status === 200) {
                    try {
                        const response = JSON.parse(xhr.responseText); // ✅ Parse JSON safely

                        const errorMsg = response?.single_message ?? 'Upload failed';
                        console.log(response);
                        if (response.success) { // ✅ Ensure success flag is true
                            toast.success(`${file.name} uploaded successfully`);

                            setFileList((prevList) =>
                                prevList.filter((f) => f.fileObject !== file.fileObject) // ✅ Remove uploaded file from UI
                            );

                            handleFolderFileListing(); // ✅ Refresh uploaded files list
                            resolve();
                        } else {
                            handleUploadError(file, errorMsg);
                            //   reject(new Error(response.message || "Upload failed")); // ✅ Provide a reason
                        }
                    } catch (error) {
                        console.error("Error parsing response:", error);
                        handleUploadError(file, "Invalid JSON response");
                        reject(new Error("Invalid JSON response")); // ✅ Provide a reason
                    }
                } else {
                    const errorMessage = `Upload failed with status ${xhr.status}`;
                    handleUploadError(file, errorMessage);
                    // reject(new Error(errorMessage)); // ✅ Provide a reason
                }
            };


            // ✅ Handle Upload Error
            // xhr.onerror = () => {
            //     handleUploadError(file);
            //     reject();
            // };

            xhr.send(formData);
        });
    };

    /** ✅ Handle Upload Failure */
    const handleUploadError = (file, errorMessage = "") => {
        setFileList((prevList) =>
            prevList.map((f) =>
                f.fileObject === file.fileObject
                    ? { ...f, progress: 0, status: "Failed" }
                    : f
            )
        );

        toast.error(`${errorMessage} ${file.name}`);
    };

    // Handle file drop
    const handleFileDrop = async (e) => {
        e.preventDefault();

        // Get the dropped files from the dataTransfer object
        const files = Array.from(e.dataTransfer.files).map((file) => ({
            fileObject: file,
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2), // Convert size to MB
            type: file.type.split("/").pop().toUpperCase(),
            extension: file.name.split('.').pop().toLowerCase(),
            url: URL.createObjectURL(file),
            progress: 0,  // Initial progress set to 0
            status: "Pending",
        }));

        // Check if files were dropped
        if (files.length > 0) {
            // Update UI with the dropped files
            setFileList((prevList) => [...prevList, ...files]);

            // Start uploading each file
            for (const file of files) {
                await handleUpload(file); // Start upload for each file
            }
        }
    };

    const handleRemoveFile = (index) => {
        setFileList((prevList) => prevList.filter((_, i) => i !== index));
    };



    const handleFolderFileListing = async () => {
        const modelType = "corporate";
        try {
            http({ method: "GET", url: `file?model_type=${modelType}&model_id=${businessStoredId}`, isSecure: true, },
                (res) => {
                    setUploadedFileList(res?.data?.data)
                }
            );
        } catch (error) { }
    };

    const handleFileDelete = async (fileId) => {
        try {
            await http({
                method: "DELETE",
                url: `files/${fileId}`, // Include the file ID in the API call
                isSecure: true,
            },
                (res) => {
                    toast.success(res.single_message)
                    handleFolderFileListing();
                    setFolderFileList((prevList) => ({
                        ...prevList,
                        files: prevList.files.filter((file) => file.id !== fileId), // Remove the deleted file
                    }));
                },
                (err) => {
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }

            );
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    useEffect(() => {
        handleFolderFileListing();
    }, []);

    // useEffect(() => {
    //     const completedFiles = fileList.filter((file) => file.status === "Completed");
    //     if (completedFiles.length > 0) {
    //         handleUpload(completedFiles);
    //     }
    // }, [fileList]);
    return (
        <div className="col-md-5 right_files end-0 show-files">
            <div className="noteform-area ps-5 pe-2 py-3 position-relative h_100vh">
                <div className="vr-line"></div>
                <div className="files_head">
                    <h5 className="fs-20 fw-semibold">Upload Files</h5>
                    {/* <div className="mt-3">
                        <label className="folder_input position-relative">File Label Name</label>
                        <input type="text" name="name" className="form-input-white mt-2" placeholder="Enter label name" value={fileLabel} onChange={(e) => setFileLabel(e.target.value)} />
                    </div>
                    <div className="mt-3">
                        <label className="folder_input position-relative">Expire Date</label>
                        <input type="date" name="expiry_date" min={getTomorrowDate()} className="form-input-white mt-2" value={fileExpireDate} onChange={(e) => setFileExpireDate(e.target.value)} />
                    </div> */}

                    <div class="files_upload main-wrapper" onDragOver={(e) => e.preventDefault()}
                        onDrop={handleFileDrop}>
                        <div class="upload-main-wrapper mx-auto text-center">
                            <div class="upload-wrapper justify-content-center add-ind cursor-pointer" onChange={handleFileUpload}>
                                <input type="file" multiple id="file-upload" />
                                <label for="file-upload" class="file-upload-text cursor-pointer justify-content-center">
                                    <i class="fa fa-upload me-2 fs-16 text-white"></i>
                                    <span class="fs-16 text-white">Browse</span>
                                </label>
                            </div>
                            <h4 class="fs-14 mt-2">Drag &amp; drop here</h4>
                            <p class="upload-description">Supports: JPEG, JPG, PDF, XLS, DOC Max Size: 10 Mb</p>
                        </div>


                    </div>
                </div>
                <div className="files-content mt-4">
                    <h6 className="fs-16 text-muted">{fileList.filter((file) => file.status === "Uploading").length} Files Uploading...</h6>
                    {fileList.map((file, index) => (
                        <div class="up-box p-3 rounded-4 shadow_main bg-white mt-3 border-none" key={index}>
                            <div class="file-upload-item">
                                <div class="d-flex align-items-center justify-content-start gap-3">
                                    {['jpg', 'jpeg', 'png', 'gif'].includes(file.extension) ? (
                                        // If it's an image, display the image itself
                                        <img
                                            src={file.url} // Use the URL of the uploaded image
                                            alt={`${file.name} preview`} // Alt text for accessibility
                                            width="40"
                                            height="40"
                                        />
                                    ) : (
                                        // For non-image files, display the corresponding icon
                                        <img
                                            src={fileIcons[file.extension] || fileIcons.default} // Icon based on file extension
                                            alt={`${file.extension} icon`} // Alt text for accessibility
                                            width="40"
                                            height="40"
                                        />
                                    )}
                                    <div class="file-info">
                                        <div class="d-flex align-items-center justify-content-between gap-3">
                                            <div>
                                                <div class="file-name">Uploading: </div>
                                                <div class="file-size mt-1"> {file.name} | {file.size} KB</div>
                                            </div>
                                            <div class="text-end">
                                                <i class="fa fa-times fs-14 text-muted cursor-pointer" title="Remove File" onClick={() => handleRemoveFile(index)}></i>
                                                <div class="progress-percentage fs-12 text-black mt-1">{file.progress}%</div>
                                            </div>
                                        </div>
                                        <div className="progress-container">
                                            <svg className="rc-progress-line" viewBox="0 0 100 2" preserveAspectRatio="none"
                                            >
                                                <path className="rc-progress-line-trail" d="M 1,1 L 99,1" stroke="#d3d3d3" strokeWidth="2"
                                                />
                                                <path className="rc-progress-line-path" d={`M 1,1 L ${file.progress},1`} stroke="#007bff" strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="comp-files mt-4">
                    <Droppable droppableId="completed-files">
                        {(provided) => (
                            <div
                                className="comp-files mt-4"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <div className="complete-head d-flex align-items-center justify-content-between flex-wrap gap-2">
                                    <h4 className="fs-16">Completed Files</h4>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="checkbox" id="selectAll"
                                            checked={isAllSelected}
                                            onChange={handleSelectAll} />
                                        <label htmlFor="selectall" className="fs-13 text-muted">
                                            Select all
                                        </label>
                                    </div>
                                </div>
                                <div className="box_scroll">
                                    {uploadedFileList?.length > 0 ? (
                                        uploadedFileList && uploadedFileList.map((file, index) => (
                                            <Draggable key={file.id} draggableId={`${file.id}`} index={index}>

                                                {(provided) => (
                                                    <div
                                                        className="complete-box mt-3 p-3 rounded-4 shadow_main bg-white border-none gap-2 d-flex align-items-center"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <input type="checkbox"
                                                            id={`checkbox-${file.id}`}
                                                            checked={selectedFiles.includes(file.id)}
                                                            onChange={() => handleCheckboxChange(file.id)} />
                                                        <img
                                                            src={fileIcons[file.extension] || fileIcons.default}
                                                            alt={`${file.extension} icon`}
                                                            width="60"
                                                            height="60"
                                                        />
                                                        <div className="comp-text d-flex align-items-center justify-content-between gap-2">
                                                            <div>
                                                                <h5 className="fs-14 fw-medium">
                                                                    {file?.name}
                                                                </h5>
                                                                <span className="d-flex align-items-center gap-2">
                                                                    <p className="fs-12 text-muted">
                                                                        Size: {formatFileSize(file?.size)}
                                                                    </p>
                                                                    |
                                                                    <p className="fs-12 text-muted">
                                                                        Uploaded: {timeElapsed(file?.created_at)}
                                                                    </p>
                                                                </span>
                                                            </div>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="30"
                                                                height="30"
                                                                viewBox="0 0 30 30"
                                                                onClick={(e) => {
                                                                    e.stopPropagation(); // Prevent event bubbling
                                                                    handleDeleteIconClick(file.id); // Call the delete handler
                                                                }}
                                                            >
                                                                <g
                                                                    id="Group_1055"
                                                                    data-name="Group 1055"
                                                                    transform="translate(16079 13588)"
                                                                >
                                                                    <rect
                                                                        id="Rectangle_276"
                                                                        data-name="Rectangle 276"
                                                                        width="30"
                                                                        height="30"
                                                                        rx="8"
                                                                        transform="translate(-16079 -13588)"
                                                                        fill="#fad3d4"
                                                                    />
                                                                    <path
                                                                        id="Path_3653"
                                                                        data-name="Path 3653"
                                                                        d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z"
                                                                        transform="translate(-16469.299 -14914.999)"
                                                                        fill="#e5252a"
                                                                        fillRule="evenodd"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    ) : (
                                        <div className="mt-3 p-3 rounded-4 shadow_main bg-white border-none">
                                            <h5 className="fs-14 text-muted text-center">No files available.</h5>
                                        </div>
                                    )}
                                </div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
            <Delete
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onDelete={handleDeleteConfirm}
            />
        </div>
    )
}

export default BusinessUploadFile
