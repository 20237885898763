import React from 'react'

const CommonButton = ({ onClick }) => {
    return (
        <div>
            <button type='button' className="add-new" onClick={onClick} >Add Folder</button>
        </div>
    )
}

export default CommonButton
