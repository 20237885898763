import React, { useState, useContext } from 'react';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Delete from '../../../feature/Delete';
import { toast } from "react-toastify";
import { http } from "../../../http/http";
import { GlobalContext } from "../../../../App";

const FileListing = ({ fileListing, setFileListing, handleFileListing }) => {
    const { setShowLoader } = useContext(GlobalContext);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState(null);
    const navigate = useNavigate();
    const handleFolderClick = (folderId) => {
        navigate(`/admin/client/file/file-detail/${folderId}`);
    };

    const handleDeleteIconClick = (folderId) => {
        setSelectedFolderId(folderId); // Set the selected folder ID
        setOpenDeleteModal(true); // Open the confirmation modal
    };

    const handleDeleteConfirm = () => {
        setOpenDeleteModal(false); // Close the modal
        handleFileDelete(selectedFolderId); // Call the delete function
    };
    const handleFileDelete = (folderId) => {
        try {
            http({
                method: "DELETE",
                url: `folders/${folderId}`, // API endpoint for deletion
                isSecure: true,
            },
                (res) => {
                    toast.success(res.single_message);
                    setFileListing((prevList) =>
                        prevList.filter((folder) => folder.id !== folderId)
                    );
                    handleFileListing();

                }, (err) => {
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }


            );
        } catch (error) {
            console.error("Error deleting folder:", error);
        }
    };

    return (
        <div className="row">
            {fileListing.length > 0 ? (
                fileListing.map((folder) => (
                    <Droppable key={folder.id} droppableId={`folder-${folder.id}`}>
                        {(provided) => (
                            <div
                                className="col-lg-4 mt-4"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleFolderClick(folder?.id)}
                            >
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fillRule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../../assets/images/user-pic.png")} alt="" />
                                            <a className="link_btn">
                                                <img src={require("../../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">
                                                    {folder?.files?.length || 0}
                                                </p>
                                            </a>
                                            <a
                                                className="dot_btn"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent event bubbling
                                                }}
                                            >
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                            {!(folder.name === "Client-Relations" && folder.parent_id === null) && (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="30"
                                                    viewBox="0 0 30 30"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteIconClick(folder.id); // Call delete handler
                                                    }}
                                                >
                                                    <g
                                                        id="Group_1055"
                                                        data-name="Group 1055"
                                                        transform="translate(16079 13588)"
                                                    >
                                                        <rect
                                                            id="Rectangle_276"
                                                            data-name="Rectangle 276"
                                                            width="30"
                                                            height="30"
                                                            rx="8"
                                                            transform="translate(-16079 -13588)"
                                                            fill="#fad3d4"
                                                        />
                                                        <path
                                                            id="Path_3653"
                                                            data-name="Path 3653"
                                                            d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z"
                                                            transform="translate(-16469.299 -14914.999)"
                                                            fill="#e5252a"
                                                            fillRule="evenodd"
                                                        />
                                                    </g>
                                                </svg>
                                            )}

                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">{folder?.name}</h5>
                                        <p className="fs-12 text-muted">
                                            {moment(folder?.created_at).format("MMM DD, YYYY, hh:mm a")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Droppable>
                ))
            ) : (
                <div className="col-12">
                    <p className="text-muted text-center">No folders available.</p>
                </div>
            )}
            <Delete
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onDelete={handleDeleteConfirm}
            />
        </div>
    );
};

export default FileListing;
