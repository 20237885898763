import React, { useEffect, useState, useCallback, useContext, useRef, useMemo } from "react";
import { http } from "../../http/http";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";
import { debounce } from 'lodash';
import { GlobalContext } from "../../../App";
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import Add_Business_Interaction from "./businessTaskModal/Add_Business_Interaction";
import Add_Business_Priority from "./businessTaskModal/Add_Business_Priority";
import Add_Business_Status from "./businessTaskModal/Add_Business_Status";
import Add_Busines_Assisgn from "./businessTaskModal/Add_Busines_Assisgn";
import JoditEditor from "jodit-react";
import DOMPurify from 'dompurify'; // Import the DOMPurify library
import { handlePermissionData, handlePermissionIdsData } from '../../http/help';
import Auth from "../../../auth/Auth";
import Delete from "../../feature/Delete";
const config = {
    readonly: false, // Set to true if you want the editor to be read-only
    useSearch: false,
    spellcheck: false,
    enter: "P",
    defaultMode: "1",
    toolbarAdaptive: false,
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    minHeight: 200,
    maxHeight: 500,
    minWidth: null,
    buttons: "bold,strikethrough,underline,italic,|,font,fontsize,brush,link,|,ul,ol,align,undo,redo",
    placeholder: "",
    controls: {
        fontsize: {
            list: [
                "8", "9", "10", "11", "12", "14", "16", "18", "24", "30", "36", "48", "60", "72", "96", "100"
            ],
        },
        font: {
            command: "fontname",
            list: {
                "": "Default",
                "'Open Sans',sans-serif": "Open Sans",
                "Helvetica,sans-serif": "Helvetica",
                "Arial,Helvetica,sans-serif": "Arial",
                "Georgia,serif": "Georgia",
                "Impact,Charcoal,sans-serif": "Impact",
                "Tahoma,Geneva,sans-serif": "Tahoma",
                "'Times New Roman',Times,serif": "Times New Roman",
                "Verdana,Geneva,sans-serif": "Verdana",
            },
        },
    },
};
// Configuration for the second JoditEditor with additional image options
const config1 = {
    readonly: false,
    toolbarSticky: false,
    minHeight: 300,
    maxHeight: 700,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: "bold,italic,underline,|,outdent,indent,|,superscript,subscript,|,cut,copy,paste,|,hr,symbol,fullsize,|,image", // Removed the "link" button
    placeholder: "Second Editor...",
    uploader: {
        insertImageAsBase64URI: true, // Enable base64 image insertion
    },
}

const Business_Task_Detail = () => {
    const { id } = useParams();
    const editor1Ref = useRef(null);
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });

    const watchedStaffIds = watch("staff_id", []);
    const navigate = useNavigate();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const storedBusinessId = JSON.parse(localStorage.getItem("BusinessId"));
    const [taskViewData, setTaskViewData] = useState([]);
    const [editTaskModal, setEditTaskModal] = useState(false);
    const [editTaskDetail, setEditTaskDetail] = useState({
        documents: [],
        start_date: '', // Ensure start_date is initialized
        end_date: '',
        start_time: '',
        end_time: '',
        estimation_time: '',
        time_spent: '',
        selectedFormIds: [],
    });
    const [deleteImageIDs, setDeleteImageIDs] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [taskDetails, setTaskDetails] = useState({
        interaction_type_id: 0,
        priority_id: 0,
        status_task_id: 0,
    });
    const [userInviteList, setUserInviteList] = useState([])
    const [assigneeIds, setAssigneeIds] = useState([]);
    const [showEditAssignOpen, setShowEditAssignOpen] = useState(false);
    const [interactionList, setInteractionList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [assignData, setAssignData] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [comment, setComment] = useState('');
    const [commentList, setCommentList] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));
    const location = useLocation();
    const [showInderaction, setShowInderaction] = useState(false);
    const [showPriority, setShowPriority] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [assigned, setAssigned] = useState(false);
    const [showAssignOpen, setShowAssignOpen] = useState(false);
    const [showAssisgnModal, setShowAssignModal] = useState(false);
    const [pendingUpdate, setPendingUpdate] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [showCustomFormModal, setShowCustomFormModal] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Track the selected tab
    const [showSurvey, setShowSurvey] = useState(false);
    const [surveyModels, setSurveyModels] = useState([]);
    const [currentTask, setCurrentTask] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null)
    const [formListing, setFormListing] = useState([])
    const [editForm, setEditForm] = useState(null);
    const [surveyFormId, setSurveyFormId] = useState(null);
    const [isFormUpdate, setIsFormUpdate] = useState(false);
    const [activeTab, setActiveTab] = useState('comments');
    const [userList, setUserList] = useState([]);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [showUserList, setShowUserList] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [images, setImages] = useState([]); // Array to store image files
    const [imagePreviews, setImagePreviews] = useState([]); // Array to store image previews
    const [subCorporateTaskList, setSubCorporateTAskList] = useState([]);
    const [searchSubTaskTerm, setSearchSubTaskTerm] = useState('');
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [openDropdownStatusId, setOpenDropdownStatusId] = useState(null)
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [openDropdown, setOpenDropdown] = useState(null);
    const [filteredTasks, setFilteredTasks] = useState(subCorporateTaskList);
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState("All");
    const [isPriorityOpen, setIsPriorityOpen] = useState(false);
    const [selectedAssignee, setSelectedAssignee] = useState("All");
    const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
    const [filteredAssignees, setFilteredAssignees] = useState([]);
    const [assigneeSearchInput, setAssigneeSearchInput] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [searchTerm, setSearchTerm] = useState("");
    const initialSelectedOption = interactionList.find(option => option.id === taskDetails.interaction_type_id);
    const initialPrioritySelectedOption = priorityList.find(option => option.id === taskDetails.priority_id);
    const initialStatusSelectedOption = statusList.find(option => option.id === taskDetails.status_task_id);
    const [dropdownState, setDropdownState] = useState({
        isOpen: false,
        search: '',
        selectedOption: initialSelectedOption,
        isPriorityOpen: false,
        prioritySearch: '',
        prioritySelectedOption: initialPrioritySelectedOption || null,
        isStatusOpen: false,
        statusSearch: '',
        statusSelectedOption: initialStatusSelectedOption || null
    });

    const [timeFormats, setTimeFormats] = useState({
        start_time_format: 'AM',
        end_time_format: 'AM',
    });
    const [corporateTaskLogsData, setCorporateTaskLogsData] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [openLogId, setOpenLogId] = useState(null);
    const [customFormList, setCustomFormList] = useState([]);
    const handleClose = () => setShow(false);
    const fileInputRef = useRef(null);
    const isDropdownOpen = (taskId) => openDropdownStatusId === taskId;
    const displayAssignedToLabel = selectedAssignee === 'All' ? 'Assigned' : selectedAssignee;
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null);


    const toggleLog = (logId) => {
        setOpenLogId((prevId) => (prevId === logId ? null : logId)); // Toggle open/close
    };

    const convertHexToRGBA = (hex, opacity) => {
        if (typeof hex !== 'string' || hex === null || hex.length === 0) {
            console.error('Invalid hex value:', hex);
            throw new TypeError('Hex value must be a non-empty string');
        }

        let r = 0, g = 0, b = 0;

        // 3 digits hex
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits hex
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        } else {
            console.error('Invalid hex length:', hex);
            throw new Error('Hex value must be either 4 or 7 characters long');
        }

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    const sanitizeHtml = (html) => {
        // Configure DOMPurify to remove the <img> tags
        return DOMPurify.sanitize(html, {
            FORBID_TAGS: ['img'], // Specify the tags to forbid
        });
    };

    const handleShow = (images, index) => {
        setSelectedImage(images || []); // Set images or an empty array if images is null or undefined
        setCurrentImageIndex(index);
        setShow(true);
    };



    // Navigate to the next image
    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % selectedImage.length);
    };

    // Navigate to the previous image
    const handlePrev = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? selectedImage.length - 1 : prevIndex - 1
        );
    };



    const handleDropdownOpen = (type) => {
        // Check if the type is 'interaction' and if the user has permission to edit
        if (type === "interaction") {
            if (!Auth.innerPermission("task_update")) {
                toast.error("You are not authorized to access the resource");
                return; // Stop further execution if no permission
            }
        }
        if (type === "priority" && !Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Stop further execution if no permission
        }

        if (type === "status" && !Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Stop further execution if no permission
        }
        setDropdownState(prevState => ({
            ...prevState,
            isOpen: type === 'interaction' ? !prevState.isOpen : prevState.isOpen,
            isPriorityOpen: type === 'priority' ? !prevState.isPriorityOpen : prevState.isPriorityOpen,
            isStatusOpen: type === 'status' ? !prevState.isStatusOpen : prevState.isStatusOpen
        }));
    };

    useEffect(() => {
        if (initialSelectedOption) {
            setDropdownState(prevState => ({ ...prevState, selectedOption: initialSelectedOption }));
        }
        if (initialPrioritySelectedOption) {
            setDropdownState(prevState => ({ ...prevState, prioritySelectedOption: initialPrioritySelectedOption }));
        }
        if (initialStatusSelectedOption) {
            setDropdownState(prevState => ({ ...prevState, statusSelectedOption: initialStatusSelectedOption }));
        }

    }, [initialSelectedOption, initialPrioritySelectedOption, initialStatusSelectedOption, setDropdownState]);

    const handleModalOpen = (taskData) => {
        setCurrentTask(taskData);
        setShowCustomFormModal(true);
    };

    const handleFillFormsClick = (taskData) => {
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        handleModalOpen(taskData); // Open the modal if permission is granted
    };
    const handleTabSelect = async (index) => {
        try {
            setSelectedTabIndex(index);
            setShowSurvey(true);

            const form = currentTask?.links?.[index];

            if (!form?.id) {
                console.error(`No valid form or ID found for index ${index}`);
                return;
            }

            setSurveyFormId(form.id);
            setSelectedForm(form);

            const formListingItem = formListing.find((item) => item.id === form.id);

            // Fetch API Data
            const apiData = await fetchSurveyDataForEdit(formListingItem);
            if (!apiData) return;

            const existingData = apiData.data?.data?.data || {};

            // Update Form Mode (Edit or Create)
            setIsFormUpdate(Object.keys(existingData).length > 0);

            // Create Survey Model
            const surveyModel = new Model({
                title: form.form_data?.[0]?.title || "",
                pages: form.form_data?.[0]?.pages?.map((page) => ({
                    ...page,
                    elements: page.elements.map((element) => ({
                        ...element,
                        value: existingData[element.name] ?? element.value,
                        defaultValue: existingData[element.name] ?? element.defaultValue,
                    })),
                })) || [],
            });

            // Update State with New Survey Model
            setSurveyModels((prevModels) => {
                const updatedModels = [...prevModels];
                updatedModels[index] = surveyModel;
                return updatedModels;
            });

        } catch (error) {
            console.error("Error in handleTabSelect:", error);
        }
    };

    const formListingData = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `custom_form/corporate/listing`,
                    isSecure: true,

                },
                (res) => {
                    setFormListing(res?.data?.data)
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    useEffect(() => {

        formListingData();
    }, []);

    const fetchSurveyDataForEdit = async (formListingItem) => {
        try {
            // Debugging: Log the ID being used
            return new Promise((resolve, reject) => {
                http(
                    {
                        method: "GET",
                        url: `custom_form/corporate/${formListingItem?.id}/edit`,  // Use the id from the formListingItem passed in
                        isSecure: true,
                    },
                    (res) => {

                        resolve(res?.data);  // Return the nested data object
                        setEditForm(res?.data)
                    },
                    (err) => {
                        // Handle errors according to the response
                        if (err.status === 401) {
                            toast.error(err.single_message);
                            setShowLoader(false);
                        } else if (err.status === 400) {
                            toast.error(err.single_message);
                            setShowLoader(false);
                        } else {
                            toast.error(err.single_message);
                            setShowLoader(false);
                        }
                    }

                );
            });
        } catch (error) {
            console.error('Error fetching survey data for edit:', error);
            return null;
        }
    };

    const deleteCustomForm = () => {
        try {
            http(
                {
                    method: "DELETE",
                    url: `custom_form/corporate/${selectedForm?.id}/delete`,
                    isSecure: true,

                },
                (res) => {
                    toast.success(res.single_message)
                    window.location.reload();
                    setShowCustomFormModal(false);
                    setShowSurvey(false); // Hide the survey form
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const handleCompleteSurvey = (sender) => {
        try {
            // Capture the filled survey data
            const surveyData = sender.data;

            // Dynamically retrieve id, title, and module_name
            const moduleName = taskViewData?.links?.[0]?.module_name;

            const url = isFormUpdate
                ? `custom_form/corporate/update/${surveyFormId}`
                : `custom_form/corporate/${surveyFormId}/save`;

            http(
                {
                    method: "POST",
                    url: url,
                    isSecure: true,
                    body: {
                        "module_name": moduleName, // Dynamic module name
                        "data": {
                            "data": surveyData // Captured survey data
                        }
                    }
                },
                (res) => {
                    toast.success(res.single_message);
                    if (!isFormUpdate) {

                        // After first save, mark the form as updateable
                        setIsFormUpdate(true);
                    }
                    handleCorporateTaskLogs();

                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error('Error completing survey:', error);
        }
    };

    const formatTimeAgo = (date) => {
        const now = moment();
        const commentDate = moment(date);
        const diffInMinutes = now.diff(commentDate, 'minutes');
        const diffInHours = now.diff(commentDate, 'hours');
        const diffInDays = now.diff(commentDate, 'days');
        const diffInMonths = now.diff(commentDate, 'months');
        const diffInYears = now.diff(commentDate, 'years');

        if (diffInMinutes < 60) {
            return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        } else if (diffInHours < 24) {
            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;
            return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else if (diffInDays < 30) {
            return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
        } else if (diffInMonths < 12) {
            return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
        } else {
            return `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ago`;
        }
    };

    const handleFormatChange = (e) => {
        // Check if user has the permission to update the task
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Stop execution if no permission
        }
        const { name, value } = e.target;
        setTimeFormats({ ...timeFormats, [name]: value });

        if (name === 'start_time_format') {
            updateStartTimeFormat(value);
        }
        if (name === 'end_time_format') {
            updateEndTimeFormat(value);
        }
    };
    const handleInteractionChange = (option) => {
        if (option.id === "add-more") {
            setShowInderaction(true);
            setDropdownState(prevState => ({ ...prevState, selectedOption: null }));
            setValue("interaction_type_id", "0");
        } else {
            setValue("interaction_type_id", option.id);
            setDropdownState(prevState => ({ ...prevState, selectedOption: option }));
            updateIntreactinType(option.id);
        }
    };
    const filteredOptions = interactionList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.search.toLowerCase())
    );
    const handleSelect = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            selectedOption: option,
            isOpen: false,
            search: ''
        }));
        handleInteractionChange(option);
    };

    /* Add PriorityType Function */

    const handleSelectPriority = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            prioritySelectedOption: option,
            isPriorityOpen: false,
            prioritySearch: ''
        }));
        handlePriorityChange(option);
    };
    const handlePriorityChange = (option) => {
        if (option.id === "add-more") {
            setShowPriority(true);
            setDropdownState(prevState => ({ ...prevState, prioritySelectedOption: null }));
            setValue("priority_id", "0");
        } else {
            setValue("priority_id", option.id);
            setDropdownState(prevState => ({ ...prevState, prioritySelectedOption: option }));
            updatePriorityType(option.id);
        }
    };

    const filteredOptionsPriority = priorityList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.prioritySearch.toLowerCase())
    );

    /* Add PriorityType Function End */

    /* Add StatusType Function */

    const handleStatusChange = (option) => {
        if (option.id === "add-more") {
            setShowStatus(true);
            // Reset the select to the default value
            setDropdownState(prevState => ({ ...prevState, statusSelectedOption: null }));
            setValue("status_task_id", "0");
        } else {
            setValue("status_task_id", option.id);
            setDropdownState(prevState => ({ ...prevState, statusSelectedOption: option }));
            updateStatusType(option.id)
        }
    };

    const handleSelectStatus = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            statusSelectedOption: option,
            isStatusOpen: false,
            statusSearch: ''
        }));
        handleStatusChange(option);
    };

    const filteredOptionsStatus = statusList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.statusSearch.toLowerCase())
    );

    /* Add StatusType Function End */

    /* Add Assign Staff Function */

    const displayStaffNames = () => {
        if (selectedStaff.length > 0) {
            return selectedStaff.map(staff => (
                <span
                    key={staff.id}
                    className="mt-2 bg-blue-light assignee_name"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={staff.name}
                >
                    {staff.name ? staff.name.charAt(0).toUpperCase() : ''}
                </span>
            ));
        }
        return (
            <span className="select-box fw-medium" onClick={handleAssigneeClick}>
                Unassigned
            </span>
        );
    };

    useEffect(() => {
        // Pre-select staff if needed
        const preSelectedStaff = userInviteList.filter(staff => assigneeIds.includes(staff.id));
        setSelectedStaff(preSelectedStaff);
    }, [assigneeIds, userInviteList]);

    const toggleAssignee = (id) => {
        setAssigneeIds(prevIds => {
            let updatedIds;
            if (prevIds.includes(id)) {
                updatedIds = prevIds.filter(assigneeId => assigneeId !== id);
            } else {
                updatedIds = [...prevIds, id];
            }
            setPendingUpdate(true);
            return updatedIds;
        });
    };

    useEffect(() => {
        if (pendingUpdate) {
            updateStaffType(assigneeIds);
            setPendingUpdate(false);
        }
    }, [assigneeIds, pendingUpdate]);

    const toggleAssignOpen = () => {
        setShowAssignOpen(prevState => !prevState);
    };
    const handleAssigneeClick = () => {
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        toggleAssignOpen(); // Open the assignee dropdown if permission is granted
    };
    /* Add Assign Staff Function End */

    const handleInputChange = (e) => {
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        const { name, value } = e.target;
        setEditTaskDetail((prevDetail) => {
            const updatedDetail = {
                ...prevDetail,
                custom_field: {
                    ...prevDetail.custom_field,
                    [name]: value,
                },
            };
            if (name === 'start_date') {
                updatedDetail.start_date = value;
            }
            if (name === 'end_date') {
                updatedDetail.end_date = value;
            }
            if (name === 'start_time') {
                updatedDetail.start_time = value;
            }
            if (name === 'end_time') {
                updatedDetail.end_time = value;
            }
            if (name === 'estimation_time') {
                updatedDetail.estimation_time = value;
            }
            if (name === 'time_spent') {
                updatedDetail.time_spent = value;
            }
            return updatedDetail;
        });

        // Debounced API call function
        debouncedApiCall(name, value);
    };

    // Use useCallback to ensure the debounced function is stable
    const debouncedApiCall = useCallback(debounce((name, value) => {
        if (name === 'start_date') {
            updateStartDate(value); // Call API when start_date changes
        }
        if (name === 'end_date') {
            updateEndDate(value); // Call API when end_date changes
        }
        if (name === 'start_time') {
            updateStartTime(value); // Call API when start_time changes
        }
        if (name === 'end_time') {
            updateEndTime(value); // Call API when end_time changes
        }
        if (name === 'estimation_time') {
            updateEstimateTime(value); // Call API when estimation_time changes
        }
        if (name === 'time_spent') {
            updateSpendTime(value); // Call API when time_spent changes
        }
    }, 1000), []);

    // const handleFileChange = (event) => {
    //     const files = Array.from(event.target.files);
    //     setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    // };

    const handleTaskView = (ids = "") => {
        setShowLoader(true);
        const newId = ids ? ids : id
        try {
            http(
                {
                    method: "GET",
                    url: `corporate/task/${storedBusinessId}/${newId}/view`,
                    isSecure: true,
                },
                (res) => {
                    setTaskViewData(res?.data);
                    setShowLoader(false);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
            setShowLoader(false);
        }

    }

    useEffect(() => {
        handleTaskView();
    }, []);

    const handleEditTask = async (id) => {
        try {
            http(
                {
                    method: "GET",
                    url: `corporate/task/${storedBusinessId}/${id}/edit`,
                    isSecure: true,
                },

                (res) => {
                    setTaskViewData(res?.data);
                    const selectedFormIds = res?.data?.forms?.map((form) => form.id) || [];

                    // Set the edit task detail with selectedFormIds
                    setTaskViewData(res?.data);
                    setEditTaskDetail({
                        ...res?.data,
                        selectedFormIds, // Include selected form IDs
                    });

                    // Populate form values for the UI if necessary
                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });

                    setDataLoaded(true); // Mark data as loaded
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }

            );
        } catch (error) {
            console.error('Error fetching task data:', error);
        }
    };

    useEffect(() => {
        if (taskViewData?.id && !dataLoaded) {
            handleEditTask(taskViewData.id,);
        }
    }, [taskViewData, dataLoaded]);

    const oepnEditModal = () => {
        setEditTaskModal(true);
    }
    // Function to handle the click event for editing the task
    const handleEditClick = () => {
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        oepnEditModal();  // Open the edit modal if permission is granted
    }

    const deleteImage = async (documentId, isNew = false) => {

        if (isNew) {
            setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== documentId));
        } else {
            setDeleteImageIDs(prevState => [...prevState, documentId]);
            setEditTaskDetail(prevState => ({
                ...prevState,
                documents: prevState.documents.filter(doc => doc.id !== documentId)
            }));
        }
    };

    const handleInteractionData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `interaction_type/listing`,
                    isSecure: true,
                },
                (res) => {

                    setInteractionList(res?.data?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };
    const handlePriorityData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `priority/listing`,
                    isSecure: true,

                },
                (res) => {
                    setPriorityList(res?.data?.data);

                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };
    // const handleAssisgnData = async () => {
    //     try {
    //         http(
    //             {
    //                 method: "GET",
    //                 url: `staff/listing`,
    //                 isSecure: true,
    //             },
    //             (res) => {
    //                 setAssignData(res?.data?.data);
    //             },
    //         )
    //     }
    //     catch (error) {
    //         console.error('Error fetching staff data:', error);
    //     }
    // };
    const handleStatusData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `status_task/listing`,
                    isSecure: true,
                },
                (res) => {
                    setStatusList(res?.data?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };


    const updateTaskHandle = async () => {
        setShowLoader(true);
        try {
            const formData = new FormData();
            formData.append('name', editTaskDetail?.name);
            formData.append('description', editTaskDetail?.description);
            formData.append('interaction_type_id', taskDetails.interaction_type_id);
            formData.append('priority_id', taskDetails.priority_id);
            formData.append('status_task_id', taskDetails.status_task_id);
            formData.append('start_date', editTaskDetail?.start_date);
            formData.append('start_time', editTaskDetail?.start_time);
            formData.append('start_time_format', timeFormats?.start_time_format);
            formData.append('end_date', editTaskDetail?.end_date);
            formData.append('end_time', editTaskDetail?.end_time);
            formData.append('end_time_format', timeFormats?.end_time_format);
            formData.append('estimation_time', editTaskDetail?.estimation_time);
            formData.append('time_spent', editTaskDetail?.time_spent);
            formData.append('image_delete_ids', deleteImageIDs.join(','));
            // Check and append 'is_parent_task'
            if (editTaskDetail?.is_parent_task) {
                formData.append('is_parent_task', editTaskDetail.is_parent_task);
            }


            assigneeIds.forEach(id => {
                formData.append('staff_id[]', id);
            });

            selectedFiles.forEach(file => {
                formData.append('file[]', file);
            });
            (editTaskDetail.selectedFormIds || []).forEach((formId) => {
                formData.append("form_ids[]", formId); // Send as an array
            });
            // Append custom fields
            Object.keys(editTaskDetail.custom_field || {}).forEach((key) => {
                formData.append(`custom_field[${key}]`, editTaskDetail.custom_field[key]);
            });

            http(
                {
                    method: "POST",
                    // url: endpoint,
                    url: `corporate/task/update/${storedBusinessId}/${id}`,
                    isSecure: true,
                    body: formData,
                    contentType: "multipart/form-data",
                },
                (res) => {
                    toast.success(res.single_message)
                    setSelectedFiles([]);
                    setDeleteImageIDs([])
                    setEditTaskModal(false);
                    handleTaskView();
                    setShowLoader(false);
                    window.location.reload();
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        } catch (error) {
            console.error('Error fetching task data:', error);
            setShowLoader(false);
        }


    };


    // Handle user selection from the list
    const handleUserClick = (userName) => {
        if (editor1Ref.current) {
            const editor = editor1Ref.current.editor; // Get the Jodit Editor instance

            if (editor) {
                // Ensure the editor has focus
                editor.focus();

                // Use the Jodit Editor's API to insert HTML
                editor.selection.insertHTML(`@${userName} `);

                setShowUserList(false); // Hide the user list after selection
            }
        }
    };

    const handleDeleteImage = (index) => {
        setImages(images.filter((_, idx) => idx !== index));
        setImagePreviews(imagePreviews.filter((_, idx) => idx !== index));
    };




    const addCommentHandle = async (event) => {
        event.preventDefault()
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        if (!comment || comment.trim() === "") {
            toast.error("Comment is required!");
            return;
        }
        setShowLoader(true);

        try {
            // Get the full HTML content from Jodit editor
            let commentContent = editor1Ref.current.value;

            // Step 1: Clean the comment content (remove only the <img> tags)
            const cleanedComment = cleanCommentContent(commentContent);

            // Extract mentioned users from the comment
            const mentionedUsers = extractMentions(cleanedComment);

            // Dynamically construct the task route link using the current domain and path
            const domainName = getDomainName(); // Get the domain dynamically
            const path = location.pathname; // Get the current path name
            const taskRouteLink = `${domainName}${path}`;

            // Prepare the email array if there are mentions
            const emails = mentionedUsers.length > 0 ? mentionedUsers.map((user) => user.email) : [];

            // Create FormData object to handle file uploads and other data
            const formData = new FormData();

            // Append emails separately as array elements
            mentionedUsers.forEach((user) => {
                formData.append('email[]', user.email); // Append each email as 'email[]'
            });

            if (taskViewData?.name) {
                formData.append('task_title', taskViewData.name);
            }
            formData.append('task_route_link', taskRouteLink);
            formData.append('user_id', userID?.user?.id);
            formData.append('comment', cleanedComment); // Append the cleaned comment content

            // Step 2: Extract Base64 images and other embedded files from the editor's content
            const embeddedFiles = extractBase64ImagesAndFiles(commentContent); // Use the original content to extract files

            // Loop through the embedded files and append to FormData
            if (embeddedFiles.length > 0) {
                embeddedFiles.forEach((file, index) => {
                    const { base64, extension } = file;
                    const blob = base64ToBlob(base64);

                    if (blob) {

                        formData.append('document_upload[]', blob, `file${index}.${extension}`);
                    } else {
                        console.error('Failed to append file: Invalid Blob');
                    }
                });
            } else {
                console.log('No embedded files found in the content.');
            }

            // Send the FormData to the desired URL
            await http(
                {
                    method: 'POST',
                    url: `task/${id}/comment/add`, // Replace with your new URL
                    isSecure: true,
                    contentType: "multipart/form-data",
                    body: formData
                },
                (res) => {
                    toast.success(res.single_message)
                    setComment('');
                    setImages([]);
                    setImagePreviews([]);
                    setShowLoader(false);
                    listCommentHandle(); // Refresh the comment list
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            setShowLoader(false);
            console.error('Error handling comment or API request:', error);
        }
    };
    const handleCommentChange = (newContent) => {
        setComment(newContent);

        // Check if the `@` character is typed
        if (newContent.includes('@')) {
            handleCommentUserList(); // Fetch the user list when '@' is typed
            setShowUserList(true);   // Show the user list
        } else {
            setShowUserList(false);  // Hide the user list if '@' is not present
        }
    };


    const getDomainName = () => {
        // Get the protocol (http or https)
        const protocol = window.location.protocol;
        // Get the hostname (e.g., guru.assisterp.com, text.assisterp.com)
        const hostname = window.location.hostname;
        // Combine to form the full domain name
        return `${protocol}//${hostname}`;
    }
    const handleCommentUserList = async () => {
        try {
            http(
                {
                    method: 'GET',
                    url: `task/comment/user-listing`,
                    isSecure: true,
                },
                (res) => {
                    setUserList(res?.data); // Set the user list from API response
                }
            );
        } catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    const cleanCommentContent = (html) => {
        // Remove <img> tags with base64 data from the comment content only
        return html.replace(/<img[^>]*src="data:image\/[^;]+;base64[^">]*"[^>]*>/g, '').trim();
    };

    const base64ToBlob = (base64) => {
        try {
            const byteString = atob(base64.split(',')[1]);
            const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        } catch (error) {
            console.error('Error converting base64 to Blob:', error);
            return null; // Return null in case of an error
        }
    };

    const extractBase64ImagesAndFiles = (htmlContent) => {
        const regex = /<img[^>]+src="([^">]+)"|data:([^;]+);base64,([^">]+)/g;
        const files = [];
        let match;

        while ((match = regex.exec(htmlContent)) !== null) {
            let base64, mimeType;
            if (match[1]) {
                // Handle image tags with base64
                base64 = match[1];
            } else if (match[2] && match[3]) {
                // Handle other base64 data
                mimeType = match[2];
                base64 = `data:${mimeType};base64,${match[3]}`;
            }

            if (base64) {
                const extension = mimeTypeToExtension(base64.split(',')[0].split(':')[1].split(';')[0]);
                files.push({ base64, extension });
            }
        }

        return files;
    };

    // Map MIME types to file extensions
    const mimeTypeToExtension = (mimeType) => {
        const mimeTypes = {
            'image/png': 'png',
            'image/jpeg': 'jpg',
            'image/gif': 'gif',
            'application/pdf': 'pdf',
            'application/msword': 'doc',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        };

        return mimeTypes[mimeType] || 'bin'; // Default to 'bin' for unknown file types
    };


    // Function to extract mentioned usernames and emails
    const extractMentions = (commentText) => {
        const userMentions = [];

        // Match all occurrences of @ followed by non-whitespace characters
        const mentionMatches = commentText.match(/@\w+/g);

        if (mentionMatches) {
            mentionMatches.forEach((mention) => {
                const username = mention.substring(1); // Remove the '@' character

                // Find the user by username from the userList
                const user = userList.find((u) => u.name.toLowerCase().includes(username.toLowerCase()));

                if (user) {
                    userMentions.push({ email: user.email, name: user.name });
                }
            });
        }

        return userMentions;
    };

    const listCommentHandle = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `task/${id}/comment`,
                    isSecure: true,
                },
                (res) => {
                    setCommentList(res?.comments);
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateIntreactinType = (interactionTypeId) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_interaction_type/${id}`,
                    isSecure: true,
                    body: {
                        interaction_type_id: interactionTypeId,
                    }
                },
                (res) => {
                    toast.success(res.single_message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updatePriorityType = (priorityTypeId) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_priority/${id}`,
                    isSecure: true,
                    body: {
                        priority_id: priorityTypeId,
                    }
                },
                (res) => {
                    toast.success(res.single_message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStatusType = (StatusTypeId) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_status/${id}`,
                    isSecure: true,
                    body: {
                        status_task_id: StatusTypeId,
                    }
                },
                (res) => {
                    toast.success(res.single_message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStaffType = (StaffTypeId) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_assignee/${id}`,
                    isSecure: true,
                    body: {
                        staff_id: StaffTypeId,
                    }
                },
                (res) => {
                    toast.success(res.single_message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStartDate = (startDate) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_start_date/${id}`,
                    isSecure: true,
                    body: {
                        start_date: startDate,
                    }
                },
                (res) => {
                    // toast.success(res.message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }
    const updateEndDate = (EndDate) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_end_date/${id}`,
                    isSecure: true,
                    body: {
                        end_date: EndDate,
                    }
                },
                (res) => {
                    // toast.success(res.message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStartTime = (StartTime) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_start_time/${id}`,
                    isSecure: true,
                    body: {
                        start_time: StartTime,
                    }
                },
                (res) => {
                    // toast.success(res.message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateEndTime = (EndTime) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_end_time/${id}`,
                    isSecure: true,
                    body: {
                        end_time: EndTime,
                    }
                },
                (res) => {
                    // toast.success(res.message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStartTimeFormat = (StartTimeFormat) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_start_time_format/${id}`,
                    isSecure: true,
                    body: {
                        start_time_format: StartTimeFormat,
                    }
                },
                (res) => {
                    // toast.success(res.message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }
    const updateEndTimeFormat = (EndTimeFormat) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_end_time_format/${id}`,
                    isSecure: true,
                    body: {
                        end_time_format: EndTimeFormat,
                    }
                },
                (res) => {
                    // toast.success(res.message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateEstimateTime = (EstimateTime) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_estimation_time/${id}`,
                    isSecure: true,
                    body: {
                        estimation_time: EstimateTime,

                    }
                },
                (res) => {
                    // toast.success(res.message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateSpendTime = (SpendTime) => {
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_time_spent/${id}`,
                    isSecure: true,
                    body: {
                        time_spent: SpendTime,

                    }
                },
                (res) => {
                    // toast.success(res.message);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }
    const deleteTask = () => {
        // if (!Auth.innerPermission("task_delete")) {
        //     toast.error("You are not authorized to access the resource");
        //     return; // Prevent further execution if no permission
        // }

        setShowLoader(true);
        try {
            http(
                {
                    method: "DELETE",
                    url: `client/task/${id}/destroy`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.single_message);
                    setShowLoader(false);
                    navigate("/admin/client/corporate_task")
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    useEffect(() => {
        handleInteractionData();
        handlePriorityData();
        handleUserList();
        handleStatusData();
        listCommentHandle();

    }, []);
    const subCorporateTaskListHandle = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `corporate/subtask/${storedBusinessId}/${id}/listing`,
                    isSecure: true,
                },
                (res) => {
                    const tasks = res?.data?.data;
                    setSubCorporateTAskList(tasks);

                    // Initialize selectedStatuses with the current statuses of fetched tasks
                    const initialStatuses = tasks.reduce((acc, task) => {
                        acc[task.id] = task.statustask; // Use the status task object from each task
                        return acc;
                    }, {});
                    setSelectedStatuses(initialStatuses);
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }
    useEffect(() => {
        subCorporateTaskListHandle();

    }, []);




    useEffect(() => {
        if (editTaskDetail) {
            setTaskDetails(prevTaskDetails => ({
                ...prevTaskDetails,
                interaction_type_id: editTaskDetail?.interactiontype?.id || 0,
                priority_id: editTaskDetail?.priority?.id || 0,
                status_task_id: editTaskDetail?.statustask?.id || 0,
            }));
        }
    }, [editTaskDetail?.interactiontype?.id, editTaskDetail?.priority?.id, editTaskDetail?.statustask?.id]);

    useEffect(() => {
        if (editTaskDetail) {
            setTimeFormats({
                start_time_format: editTaskDetail.start_time_format || 'AM',
                end_time_format: editTaskDetail.end_time_format || 'AM',
            });
        }
    }, [editTaskDetail]);

    useEffect(() => {
        if (editTaskDetail && editTaskDetail.assignees) {
            const ids = editTaskDetail.assignees.map(assignee => assignee.id);
            setAssigneeIds(ids);
        }
    }, [editTaskDetail?.start_time_format, editTaskDetail?.end_time_format]);

    const handleAddCorporateSubtask = () => {
        localStorage.setItem('corporate_task_details', JSON.stringify(editTaskDetail));
        navigate('/admin/client/corporate_task/add_tasks?type=corporate_subtask'); // Define the navigation path
    };
    const toggleDropdown = (id) => {
        setOpenDropdownId((prevId) => (prevId === id ? null : id));
    };
    const handleSearchChange = (e) => {
        setSearchSubTaskTerm(e.target.value);
    };

    // Filtered data based on the search term
    const filteredData = (userInviteList || []).filter((staff) =>
        staff.name?.toLowerCase().includes(searchSubTaskTerm.toLowerCase())
    );


    // Function to handle the staff selection
    const handleStaffSelect = (staff, corporate_taskId) => {


        // Debugging: Check if corporate_taskId is missing and log detailed error
        if (!corporate_taskId) {
            console.error('Error: Task ID is missing when calling handleStaffSelect.');
            toast.error('Task ID is missing. Please select a task first.');
            return;
        }

        setOpenDropdownId(null); // Close the dropdown after selecting

        // Call the function to update the subtask staff assignment
        updateSubTaskStaffType(staff.id, corporate_taskId);
    };

    // Function to update the subtask with the selected staff member's ID
    const updateSubTaskStaffType = (staffId, corporate_taskId) => {
        // Ensure corporate_taskId is passed correctly
        if (!corporate_taskId) {
            console.error(
                'Task ID is required but is undefined inside updateSubTaskStaffType.'
            );
            toast.error('Task ID is missing.');
            return;
        }

        try {
            http(
                {
                    method: 'POST',
                    url: `task/update_assignee/${corporate_taskId}`, // Use the passed task ID
                    isSecure: true,
                    body: {
                        staff_id: [staffId], // Send the selected staff ID in an array as requested
                    },
                },
                (res) => {
                    toast.success(res.single_message);
                    subCorporateTaskListHandle();
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error('Unexpected error updating staff assignment:', error);

        }
    };
    // Toggle the dropdown for a specific task
    const toggleStatusDropdown = (taskId) => {
        setOpenDropdownStatusId((prevId) => (prevId === taskId ? null : taskId)); // Open if closed, close if open
    };

    // Handle status select
    const handleStatusSelect = (option, taskId) => {


        if (!taskId) {
            console.error('Error: Task ID is missing when calling handleStatusSelect.');
            toast.error('Task ID is missing. Please select a task first.');
            return;
        }

        // Close the dropdown
        setOpenDropdownStatusId(null);

        // Update the selected status state
        setSelectedStatuses(prevStatuses => ({
            ...prevStatuses,
            [taskId]: option, // Update the selected status for the task
        }));

        // Call the function to update the subtask status
        updateSubTaskStatusType(option.id, taskId);
    };

    // Function to update the subtask status
    const updateSubTaskStatusType = (StatusId, taskId) => {
        if (!taskId) {
            console.error('Task ID is required but is undefined inside updateSubTaskStatusType.');
            toast.error('Task ID is missing.');
            return;
        }

        try {
            http(
                {
                    method: 'POST',
                    url: `task/update_status/${taskId}`,
                    isSecure: true,
                    body: {
                        status_task_id: StatusId,
                    },
                },
                (res) => {
                    toast.success(res.single_message);
                    subCorporateTaskListHandle();
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error('Unexpected error updating status:', error);

        }
    };



    // Memoized list of unique assignees
    const uniqueAssignees = useMemo(() => [
        ...new Set(
            subCorporateTaskList?.flatMap(task =>
                task.assignees.map(assignee => `${assignee.first_name} ${assignee.last_name}`)
            )
        )
    ], [subCorporateTaskList]);

    // Effect to filter assignees based on the search input
    useEffect(() => {
        setFilteredAssignees(
            uniqueAssignees.filter(assignee =>
                assignee.toLowerCase().includes(assigneeSearchInput.toLowerCase())
            )
        );
    }, [assigneeSearchInput, uniqueAssignees]);

    // Handler for the search input change
    const handleSearchInputChange = (e) => {
        setAssigneeSearchInput(e.target.value); // Update search input state with the entered value
    };
    const handleDropdownClick = (dropdownName) => {
        if (openDropdown === dropdownName) {
            setOpenDropdown(null); // Close dropdown if it's already open
        } else {
            setOpenDropdown(dropdownName); // Open the clicked dropdown and close others
            // event.stopPropagation();
        }
    };

    const handleStatusClick = () => handleDropdownClick('status');
    const handlePriorityClick = () => handleDropdownClick('priority');
    const handleNameClick = () => handleDropdownClick('Assigned');



    const handleStatusOptionClick = (value) => {
        setSelectedStatus(value);
        setIsStatusOpen(false);
    };

    const handlePriorityOptionClick = (value) => {
        setSelectedPriority(value);
        setIsPriorityOpen(false);
    };

    const handleAssigneeOptionClick = (value) => {
        setSelectedAssignee(value);
        setIsAssigneeOpen(false);
        setOpenDropdown(null);
    };
    const stopPropagation = (event) => {
        event.stopPropagation();
    };


    useEffect(() => {
        let filtered = subCorporateTaskList;

        // Assuming `filtered` is your array of tasks
        if (selectedAssignee !== "All") {
            filtered = filtered.filter(task =>
                // Check if any assignee's full name matches the selected assignee
                task.assignees.some(assignee =>
                    `${assignee.first_name} ${assignee.last_name}` === selectedAssignee
                )
            );
        }

        if (selectedPriority !== "All") {
            filtered = filtered.filter(task => task.priority?.id == selectedPriority);
        }

        if (selectedStatus !== "All") {
            filtered = filtered.filter(task => task.statustask?.id == selectedStatus);
        }



        setFilteredTasks(filtered);
    }, [selectedStatus, selectedPriority, selectedAssignee, subCorporateTaskList]);


    const handleSubtasksTabClick = () => {
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        setActiveTab("subtasks"); // Change the tab if permission is granted
    };

    const handleCorporateTaskLogs = () => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    // url: `task/${storedBusinessId}/${id}/formlogs`,
                    url: `corporate/task/${storedBusinessId}/${id}/formlogs`,
                    isSecure: true,
                },
                (res) => {
                    setCorporateTaskLogsData(res?.data);
                    setShowLoader(false);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
            setShowLoader(false);
        }

    }

    useEffect(() => {
        handleCorporateTaskLogs();
    }, []);


    const handleCustomFormList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFormList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleCustomFormList();
    }, []);

    const processFiles = (newFiles) => {
        const newFilePreviews = newFiles.map((file) => {
            const fileType = file.type;
            let preview = '';

            if (fileType === 'application/pdf') {
                preview = require('../../../assets/images/pdfimage.png'); // Replace with PDF image path
            } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                preview = require('../../../assets/images/docimage.png'); // Replace with DOC image path
            } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                preview = require('../../../assets/images/xlsimage.png'); // Replace with XLSX image path
            } else {
                preview = URL.createObjectURL(file);
            }
            return { file, preview }; // Return file with preview metadata
        });

        // Update state with new files and previews
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setImagePreviews((prevPreviews) => [...prevPreviews, ...newFilePreviews]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Optional: Add visual feedback for drag enter
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Optional: Remove visual feedback for drag leave
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const newFiles = Array.from(e.dataTransfer.files);

        // Avoid adding duplicates (match by name or some unique property)
        const filteredFiles = newFiles.filter(
            (file) => !editTaskDetail.documents.some((doc) => doc.name === file.name)
        );

        processFiles(filteredFiles);
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);

        // Avoid duplicates
        const filteredFiles = newFiles.filter(
            (file) => !editTaskDetail.documents.some((doc) => doc.name === file.name)
        );

        processFiles(filteredFiles);
    };

    const handleCheckboxCustomForm = (e) => {
        const { value, checked } = e.target;
        const formId = parseInt(value, 10); // Ensure the form ID is an integer

        setEditTaskDetail((prevDetail) => {
            let updatedFormIds;
            if (checked) {
                // Add the form ID if checked
                updatedFormIds = [...(prevDetail.selectedFormIds || []), formId];
            } else {
                // Remove the form ID if unchecked
                updatedFormIds = (prevDetail.selectedFormIds || []).filter((id) => id !== formId);
            }

            return {
                ...prevDetail,
                selectedFormIds: updatedFormIds, // Update the selected form IDs
            };
        });
    };

    const handleOpenFile = (filePath) => {
        const isImage = filePath.match(/\.(jpeg|jpg|gif|png)$/i); // Check if the file is an image
        if (isImage) {
            setSelectedImage(filePath); // Set the selected image
            setIsImageModalOpen(true); // Open the modal
        } else {
            window.open(filePath, "_blank"); // Open non-image files in a new tab
        }
    };
    const handleUserList = async () => {

        http(
            {
                method: "GET",
                url: `adminusers`,
                isSecure: true,
            },
            (res) => {
                if (res?.data) {
                    setUserInviteList(res.data.data); // Set the contact list data
                    // setPageUserCount(res.data.last_page); // Set total pages for pagination
                } else {
                    setUserInviteList([]); // If no data, set an empty list
                }
            }
        );
    };
    const handleDeleteIconClick = (taskId) => {
        if (!Auth.innerPermission("task_delete")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        setSelectedTaskId(taskId); // Set the task ID to be deleted
        setOpenDeleteModal(true); // Open the delete modal
    };

    const handleDeleteConfirm = () => {
        setOpenDeleteModal(false); // Close the modal
        deleteTask(selectedTaskId); // Call the delete function
    };
    const openSubTask = (ids) => {
        handleTaskView(ids);
        navigate(`/admin/client/corporate_task/business_task_detail/${ids}`);
        window.location.reload();
    };

    return (
        <div className="right_section font-poppins">
            <div className="tasks-detail-area">
                <div className="row">
                    <div className="col-md-8 mt-4 ps-0">
                        <div className="row align-items-center task-detail-head">
                            <div className="col-sm-12 mt-2 l_dethead">
                                <h2 className="fs-20 fw-bold"> Task Details</h2>
                            </div>
                            <div className="col-md-6 my-3 d-flex align-items-center gap-3">
                                {/* <div className="ttl-name d-flex align-items-center justify-content-center">
                                    <p>{taskViewData?.client_self?.first_name.charAt(0).toUpperCase()}{taskViewData?.client_self?.last_name.charAt(0).toUpperCase()}</p>
                                </div> */}
                                <div className="ttl-name d-flex align-items-center justify-content-center">
                                    <p>
                                        {(taskViewData?.client_self?.first_name ?? "--").charAt(0).toUpperCase()}
                                        {(taskViewData?.client_self?.last_name ?? "--").charAt(0).toUpperCase()}
                                    </p>
                                </div>

                                <div className="ttl-detail">
                                    <h4 className="fs-18 fw-semibold">{taskViewData?.client_self?.first_name} {taskViewData?.client_self?.last_name}</h4>

                                    {
                                        taskViewData?.task_type === "1" ? (
                                            <p className="fs-16 text-secondary">Individual</p>
                                        ) : taskViewData?.task_type === "2" ? (
                                            <p className="fs-16 text-secondary">Business</p>
                                        ) : taskViewData?.task_type === "3" && (
                                            <p className="fs-16 text-secondary">Self</p>
                                        )}
                                </div>
                            </div>
                            <div className="col-md-6 my-3 r_dethead">
                                <ul className="ps-0 d-flex align-items-center gap-2 justify-content-end">
                                    <li onClick={() => handleFillFormsClick(taskViewData)}>
                                        <span className="file-form cursor-pointer"
                                        //  style={style}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="13.999" viewBox="0 0 12.599 13.999">
                                                <path id="Path_506" data-name="Path 506" d="M402.367,1279h5.866a3.067,3.067,0,0,1,3.366,3.381v7.231a3.056,3.056,0,0,1-3.366,3.388h-5.866a3.072,3.072,0,0,1-3.367-3.388v-7.231A3.083,3.083,0,0,1,402.367,1279Zm.189,3.262v-.007h2.092a.541.541,0,0,1,.388.161.549.549,0,0,1,0,.777.541.541,0,0,1-.388.161h-2.092a.546.546,0,0,1,0-1.092Zm0,4.256h5.488a.546.546,0,0,0,0-1.092h-5.488a.546.546,0,0,0,0,1.092Zm0,3.2h5.488a.531.531,0,0,0,.35-.182.55.55,0,0,0,0-.735.531.531,0,0,0-.35-.182h-5.488a.5.5,0,0,0-.3.056.5.5,0,0,0-.225.2.56.56,0,0,0,0,.588.541.541,0,0,0,.525.252Z" transform="translate(-399 -1279)" fill="#202529" fill-rule="evenodd" />
                                            </svg>
                                            Fill Required Forms
                                        </span>
                                    </li>
                                    <li>
                                        <span className="whitespace-nowrap text-black"
                                        //  style={style}
                                        >
                                            {/* {taskViewData?.priority?.name} */}

                                            {taskViewData.priority && taskViewData.priority.color_code && taskViewData.priority.name && (
                                                <span
                                                    style={{
                                                        backgroundColor: convertHexToRGBA(taskViewData.priority.color_code, 0.2),
                                                        padding: '3px 10px',
                                                        borderRadius: '8px',
                                                        textAlign: "center",
                                                        width: "fit-content",
                                                        fontSize: "14px",
                                                        height: "30px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        color: taskViewData.priority.color_code,
                                                    }}
                                                >
                                                    {taskViewData.priority.name}
                                                </span>
                                            )}
                                        </span>
                                    </li>
                                    <li>
                                        <a className="file-edit"
                                            // onClick={() => handleEditTask(taskViewData?.id)}
                                            onClick={() => handleEditClick()}
                                        >
                                            <img alt="" src={require('../../../assets/images/edit-icon.png')} />
                                        </a>
                                    </li>
                                    <li>
                                        <a className="file-delete"
                                            onClick={() => handleDeleteIconClick(taskViewData.id)}
                                        >
                                            <img alt="" src={require('../../../assets/images/delete-icon.png')} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 task-detail-area mt-2">
                                <h4 className="fs-18 fw-semibold">{taskViewData?.corporate?.name} <span className="text-blue fs-14 fw-normal">{taskViewData?.statustask?.name}</span></h4>
                                <p className="fs-14">
                                    Created by <b>{taskViewData?.corporate?.name} </b> {formatTimeAgo(taskViewData.created_at)}
                                </p>
                                {/* <p className="fs-18 mt-3 mb-2">{taskViewData?.description}</p> */}
                                <p
                                    className="mt-3 "
                                    dangerouslySetInnerHTML={{
                                        __html: taskViewData?.description,
                                    }}
                                />
                            </div>
                            <div>
                                <div className="d-flex align-items-start gap-3 flex-wrap task-pics mt-3">
                                    {taskViewData &&
                                        taskViewData?.documents?.map((taskImage, index) => {
                                            const filePath = taskImage?.document_path;
                                            const isImage = filePath.match(/\.(jpeg|jpg|gif|png)$/i); // Check if the file is an image
                                            const isPDF = filePath.match(/\.pdf$/i); // Check if the file is a PDF
                                            const isDOC = filePath.match(/\.docx?$/i); // Check if the file is a Word document
                                            const isXLS = filePath.match(/\.xlsx?$/i); // Check if the file is an Excel file

                                            return (
                                                <div
                                                    key={`file-${index}`}
                                                    className="file-item cursor-pointer"
                                                    onClick={() => handleOpenFile(filePath)}
                                                >
                                                    {isImage ? (
                                                        <img
                                                            alt="Preview"
                                                            src={filePath}
                                                            style={{ width: 100, height: 100, objectFit: "cover", cursor: "pointer" }}
                                                        />
                                                    ) : isPDF ? (
                                                        <img
                                                            alt="PDF Preview"
                                                            src={require("../../../assets/images/pdfimage.png")}
                                                            style={{ width: 100, height: 100, cursor: "pointer" }}
                                                        />
                                                    ) : isDOC ? (
                                                        <img
                                                            alt="DOC Preview"
                                                            src={require("../../../assets/images/docimage.png")}
                                                            style={{ width: 100, height: 100, cursor: "pointer" }}
                                                        />
                                                    ) : isXLS ? (
                                                        <img
                                                            alt="XLS Preview"
                                                            src={require("../../../assets/images/xlsimage.png")}
                                                            style={{ width: 100, height: 100, cursor: "pointer" }}
                                                        />
                                                    ) : (
                                                        <span>Unsupported file type</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                </div>

                                <Modal
                                    show={isImageModalOpen}
                                    onHide={() => setIsImageModalOpen(false)}
                                    size="xl"
                                    centered
                                    className="bg-transparent m-4 fullimage"
                                >
                                    <Modal.Body className="text-center">
                                        <div className="slider-container d-flex align-items-center gap-4">

                                            <img className="modal-content" src={selectedImage} alt="Full Preview" style={{ width: "100%" }} />

                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>

                            <div className="col-md-12">
                                <hr className="task-hr" />
                            </div>
                        </div>
                        <div className="row comments-area">
                            <div className="col-md-12 client-tabs d-flex justify-content-between align-items-center gap-4 flex-wrap">
                                <ul className="nav nav-tabs border-0 d-flex align-items-center flex-wrap">
                                    <li className="nav-item me-2 mt-2">
                                        <button
                                            className={`nav-link fs-16 ${activeTab === 'comments' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('comments')}
                                        >
                                            Comments
                                        </button>
                                    </li>
                                    <li className="nav-item me-2 mt-2">
                                        <button
                                            className={`nav-link fs-16 ${activeTab === 'logs' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('logs')}
                                        >
                                            Logs
                                        </button>
                                    </li>
                                    <li className="nav-item me-2 mt-2">
                                        <button
                                            className={`nav-link fs-16 ${activeTab === 'subtasks' ? 'active' : ''}`}
                                            // onClick={() => setActiveTab('subtasks')}
                                            onClick={handleSubtasksTabClick}
                                        >
                                            Subtasks
                                        </button>
                                    </li>
                                </ul>
                                {activeTab === 'subtasks' && (
                                    <button className="add-new-button" onClick={handleAddCorporateSubtask}>
                                        <img src={require("../../../assets/images/plusicon.png")} alt="Add Subtask" />
                                        Add Subtask
                                    </button>
                                )}

                            </div>
                        </div>


                        {activeTab === 'comments' && (
                            <>
                                {/* Comments List Section */}
                                <div className="row">
                                    <div className="col-md-12 mt-3">
                                        <div className="comment-title mb-3">
                                            <h1 className="fs-20 fw-bold">Comments</h1>
                                        </div>
                                        <div className="comm-scroll">
                                            {commentList && commentList.length > 0 ? (
                                                commentList.map((comment, index) => (
                                                    <div className="row comment-boxes mb-2" key={index}>
                                                        <div className="col-md-12 mt-1 d-flex align-items-start gap-3 bg-comment rounded-3 py-3">
                                                            <div className="l_comment">

                                                            </div>
                                                            <div className="r_comment">
                                                                <h4 className="fs-18 fw-semibold">{comment?.user?.name}</h4>
                                                                <p className="fs-12">Commented about {formatTimeAgo(comment.created_at)}</p>
                                                                <div className="msg-text my-1">

                                                                    <p
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: sanitizeHtml(comment.comment), // Use sanitized HTML
                                                                        }}
                                                                    >

                                                                    </p>


                                                                </div>
                                                                {Array.isArray(comment.document_paths) ? (

                                                                    comment.document_paths.map((imagePath, imgIndex) => (

                                                                        <img
                                                                            key={imgIndex}
                                                                            alt="picleft"
                                                                            className="picleft mb-2"
                                                                            src={imagePath}
                                                                            onClick={() => handleShow(comment.document_paths, imgIndex)}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    ))
                                                                ) : comment.document_paths ? (
                                                                    <img
                                                                        alt="picleft"
                                                                        className="picleft mb-2"
                                                                        src={comment.document_paths}
                                                                        onClick={() => handleShow([comment.document_paths], 0)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                ) : (
                                                                    <p>No images available.</p>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="row no-comments mt-3">
                                                    <p className="col-md-12 fs-18 text-secondary">No comments found.</p>
                                                </div>
                                            )}
                                        </div>

                                        {/* Slider Modal */}
                                        <Modal
                                            show={show}
                                            onHide={handleClose}
                                            size="xl"
                                            centered
                                            className="bg-transparent m-4 fullimage"
                                        >
                                            {/* <Modal.Header closeButton></Modal.Header> */}
                                            <Modal.Body>
                                                {selectedImage && selectedImage.length > 0 ? (
                                                    <div className="slider-container d-flex align-items-center gap-4">
                                                        <button onClick={handlePrev} className="slider-prev">
                                                            &#10094;
                                                        </button>
                                                        <img
                                                            className="modal-content"
                                                            src={selectedImage[currentImageIndex]}
                                                            alt="Full size"
                                                            style={{ width: '100%' }}
                                                        />
                                                        <button onClick={handleNext} className="slider-next">
                                                            &#10095;
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <p>No images available.</p>
                                                )}
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>

                                {/* Add Comment Section */}
                                <div className="row mt-2 comments-area mt-3">
                                    <div className="comment-title mb-3">
                                        <h1 className="fs-20 fw-bold">Add Comment</h1>
                                    </div>
                                    <div className="comment-area position-relative">
                                        <div className="position-relative comment-jodi">
                                            <JoditEditor
                                                ref={editor1Ref}
                                                config={config1}
                                                value={comment} // Bind the editor's value to the comment state
                                                onChange={handleCommentChange} // Handle content change

                                            />
                                            <button className="btn-blue d-flex w-fit justify-content-end mt-3 ms-auto" onClick={addCommentHandle}>Submit Comment</button>

                                            {showUserList && userList.length > 0 && (
                                                <div className="user-list bg-white position-absolute p-2">
                                                    {userList.map((user) => (
                                                        <div
                                                            key={user.id}
                                                            onClick={() => handleUserClick(user?.name)}
                                                            className="user-item"
                                                            style={{ cursor: 'pointer' }} // Cursor change to indicate clickable
                                                        >
                                                            <ul className="ps-0">
                                                                <li className="d-flex align-items-start justify-content-between gap10">
                                                                    <div className="l-block d-flex align-items-center gap-2">
                                                                        <div className="nm-blocks">
                                                                            <img src={user?.image_path} className="user-image" />
                                                                        </div>
                                                                        <div className="nm-text">
                                                                            <h3>{user?.name}</h3>
                                                                            <p>{user?.email}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="r-block">
                                                                        <p>{user?.first_name}</p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        {/* <input

                                            style={{ display: 'none' }}

                                            type="file"
                                            name="file"
                                            id="imageUploadInput"
                                            onChange={handleFileChangeComment}
                                            multiple
                                        />
                                        <div className="sendarea d-flex align-items-center gap-2">
                                            <svg className="cursor-pointer" onClick={triggerFileInput} xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14.301 22.473">
                                                <path
                                                    id="XMLID_197_"
                                                    d="M69.192,0h0a5.107,5.107,0,0,0-5.107,5.107V14.3a3.064,3.064,0,1,0,6.129,0V9a1.021,1.021,0,0,0-2.043,0V14.3a1.021,1.021,0,1,1-2.043,0V5.107a3.064,3.064,0,0,1,6.129,0V15.322a5.107,5.107,0,1,1-10.215,0V5.107a1.021,1.021,0,1,0-2.043,0V15.322a7.15,7.15,0,1,0,14.3,0V5.107A5.113,5.113,0,0,0,69.192,0Z"
                                                    transform="translate(-59.999)"
                                                    fill="#202529"
                                                />
                                            </svg>
                                            <svg className="cursor-pointer" onClick={addCommentHandle} xmlns="http://www.w3.org/2000/svg" width="18" height="23" viewBox="0 0 22.473 22.473">
                                                <g id="send" transform="translate(0 0)">
                                                    <g id="Icon_00000145049271370995700300000003487412756501951400_" transform="translate(0 0)">
                                                        <path
                                                            id="Path_3581"
                                                            data-name="Path 3581"
                                                            d="M16.076,6.4,8.66,11.545.9,8.959A1.32,1.32,0,0,1,.915,6.45L20.747.063a1.32,1.32,0,0,1,1.662,1.662L16.022,21.557a1.32,1.32,0,0,1-2.51.013l-2.6-7.794Z"
                                                            transform="translate(0 0)"
                                                            fill="#004fc5"
                                                            fillRule="evenodd"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div> */}
                                    </div>
                                    {/* {imagePreviews.map((preview, index) => (
                                        <div key={index} className="uploaded-image-preview mt-2">
                                            <img src={preview} alt="Uploaded Preview" className="img-fluid" />
                                            <div className="cross-pic" onClick={() => handleDeleteImage(index)} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                                    <g id="Group_1589" data-name="Group 1589" transform="translate(-1173 -1120)">
                                                        <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#ccc" />
                                                        <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#fff" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    ))} */}
                                </div>
                            </>
                        )}

                        {activeTab === 'logs' && (
                            <div className="row">
                                <div className="col-md-12 comment-title mt-3 mb-1">
                                    <h1 className="fs-20 fw-bold">New Logs</h1>
                                </div>

                                {corporateTaskLogsData?.task_activity_logs?.map((log) => (

                                    <div className="col-md-12" key={log.id}>
                                        <div className="bg-white rounded-3 shadowmain p-3 mt-3">
                                            <div className="newloghead d-flex align-items-center justify-content-between gap-2" onClick={() => toggleLog(log.id)}>
                                                <div className="d-flex align-items-center gap-2">
                                                    <i className={`fa-solid ${openLogId === log.id ? 'fa-chevron-down' : 'fa-chevron-right'} fs-18`}></i>
                                                    <h6 className="fw-regular fs-16 d-flex">
                                                        <b className="d-flex align-items-center">
                                                            {moment(log?.changes?.updated_at?.new || log?.changes?.updated_at?.old).format('MMM DD, hh:mm A')} <a className="text-blue d-block mx-1">{log.user?.name}</a>
                                                        </b>{" "}
                                                        updated the <b className="mx-1">{log?.changes?.name?.new || log?.changes?.name?.old || log?.task_title}</b> Form.
                                                    </h6>
                                                </div>
                                            </div>

                                            <div className={`newlogcontent mt-4 ${openLogId === log.id ? 'showlogs' : ''}`}>
                                                <hr className="my-3" />

                                                <div className="l_logs d-flex align-items-center justify-content-between gap-2">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <img src={require("../../../assets/images/user-pic.png")} alt="User" />
                                                        <h4 className="fs-18 fw-bold">{log.user?.name}</h4>
                                                    </div>
                                                    {/* <a href="#" className={`fs-14 text-blue ${openLogId === log.id ? 'showlogs' : ''}`}>View Detail</a> */}
                                                </div>

                                                {log?.changes?.name?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old Title: <b >{log?.changes?.name?.old}</b> and New title: <b>{log?.changes?.name?.new}</b> has been updated.</h2>
                                                    </div>
                                                )}
                                                {log?.changes?.description?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">
                                                            Old Description: <b dangerouslySetInnerHTML={{ __html: log.changes.description?.old }} />
                                                            and New Description: <b dangerouslySetInnerHTML={{ __html: log.changes.description?.new }} />
                                                            has been updated.
                                                        </h2>

                                                    </div>
                                                )}
                                                {log?.changes?.interaction_type?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old Intreaction: <b >{log?.changes?.interaction_type?.old}</b> and New Intreaction: <b>{log?.changes?.interaction_type?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.priority?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old Priority: <b >{log?.changes?.priority?.old}</b> and New Priority: <b>{log?.changes?.priority?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.status_task?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old Status: <b >{log?.changes?.status_task?.old}</b> and New Status: <b>{log?.changes?.status_task?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.start_date?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old Start Date: <b >{log?.changes?.start_date?.old}</b> and New Start Date: <b>{log?.changes?.start_date?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.start_time?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old Start Time: <b >{log?.changes?.start_time?.old}</b> and New Start Time: <b>{log?.changes?.start_time?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.start_time_format?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old Start Time Format: <b >{log?.changes?.start_time_format?.old}</b> and New Start Time Format: <b>{log?.changes?.start_time_format?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.end_date?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old End Date: <b >{log?.changes?.end_date?.old}</b> and New End Date: <b>{log?.changes?.end_date?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.end_time?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old End Time: <b >{log?.changes?.end_time?.old}</b> and New End Time: <b>{log?.changes?.end_time?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.end_time_format?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old End Time Format: <b >{log?.changes?.end_time_format?.old}</b> and New End Time Format: <b>{log?.changes?.end_time_format?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.estimation_time?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old Estmation Time: <b >{log?.changes?.estimation_time?.old}</b> and New Estmation Time: <b>{log?.changes?.estimation_time?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                                {log?.changes?.time_spent?.old && (
                                                    <div className="r_logs logbox bg-white mt-1">
                                                        <img src={require("../../../assets/images/datanotfound.png")} alt="No data found" />
                                                        <h2 className="fs-16 text-muted fw-regular">Old Time Spend: <b >{log?.changes?.time_spent?.old}</b> and New Time Spend: <b>{log?.changes?.time_spent?.new}</b> has been updated.</h2>
                                                        {/* <a className="t_blue fs-14 ms-5">View Detail</a> */}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {corporateTaskLogsData?.linked_forms_logs?.map((formLog) => (
                                    <div className="col-md-12 mt-3" key={formLog.id}>
                                        <div className="">

                                            {formLog.activity_logs?.map((log) => (
                                                <div className="bg-white rounded-3 shadowmain p-3 mt-3" key={log.id}>
                                                    <div
                                                        className="newloghead d-flex align-items-center justify-content-between gap-2"
                                                        onClick={() => toggleLog(log.id)}
                                                    >
                                                        <div className="d-flex align-items-center gap-2">
                                                            <i className={`fa-solid ${openLogId === log.id ? 'fa-chevron-down' : 'fa-chevron-right'} fs-18`}></i>

                                                            <h6 className="fw-regular fs-16 d-flex">
                                                                <b className="d-flex align-items-center">
                                                                    {moment(log?.updated_at).format('MMM DD, hh:mm A')} <a className="text-blue d-block mx-1">{log?.userwith_activity_logs?.name}</a>
                                                                </b>{" "}
                                                                updated the <b className="mx-1">{
                                                                    log.changes?.title
                                                                        ? log.changes.title.new || log.changes.title.old
                                                                        : formLog.title // Use formLog.title directly without extra curly braces
                                                                }</b> Form.
                                                            </h6>

                                                        </div>
                                                        {/* <a href="#" className={`fs-14 text-blue ${openLogId === log.id ? 'showlogs' : ''}`}>
                                                            View Detail
                                                        </a> */}
                                                    </div>

                                                    <div className={`newlogcontent mt-2 ${openLogId === log.id ? 'showlogs' : ''}`}>
                                                        <hr className="my-3" />
                                                        <div className="l_logs d-flex align-items-center gap-2">
                                                            <img src={require("../../../assets/images/user-pic.png")} alt="User" />
                                                            <h4 className="fs-18 fw-bold">{log?.userwith_activity_logs?.name}</h4>
                                                        </div>

                                                        {/* Display changes in log */}
                                                        {log?.changes?.data ? (
                                                            <div className="bg-white mt-1 logbox">
                                                                <img src={require("../../../assets/images/datanotfound.png")} alt="" />
                                                                <h2 className="fs-16 text-muted fw-regular">
                                                                    {Object.keys(log.changes.data).map((key) => (
                                                                        <span key={key}>
                                                                            Old: <b>{log.changes.data[key]?.old || 'N/A'}</b> and New: <b>{log.changes.data[key]?.new || 'N/A'}</b> has been updated.<br />
                                                                        </span>
                                                                    ))}
                                                                </h2>
                                                            </div>
                                                        ) : (
                                                            <div className="bg-white mt-2 logbox">
                                                                <h2 className="fs-18 text-muted fw-regular">No changes available.</h2>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}

                            </div>
                        )}


                        {activeTab === 'subtasks' && (
                            <div className="row">
                                <div className="col-md-12 comment-title mt-3 mb-1">
                                    <h1 className="fs-20 fw-bold">Subtasks</h1>
                                </div>
                                <div className="col-md-12 drop_box d-flex align-items-center gap-3 justify-content-end mt-3">
                                    <div className="drop_box">
                                        <div className="dropdown" onClick={handleNameClick}>
                                            <div className="dropdown__selected">
                                                <label className="fs-14">{displayAssignedToLabel}</label>

                                                <span className="dropdown__icon">
                                                    {openDropdown === 'Assigned' ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                            <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                            <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                        </svg>
                                                    )}
                                                </span>
                                            </div>
                                            <div className={`dropdown__options ${openDropdown === 'Assigned' ? 'dropdown__options--open' : ''}`} onClick={stopPropagation}>
                                                <input
                                                    type="text"
                                                    value={assigneeSearchInput} // Bind the input value to the search state
                                                    onChange={handleSearchInputChange} // Handle input change
                                                    onMouseDown={stopPropagation}
                                                    placeholder="Search"
                                                />
                                                <div
                                                    key="all-name"
                                                    className="dropdown__option"
                                                    onClick={() => handleAssigneeOptionClick("All")}
                                                >
                                                    All
                                                </div>
                                                {filteredAssignees.map(name => (
                                                    <div
                                                        key={name}
                                                        className="dropdown__option"
                                                        onClick={() => handleAssigneeOptionClick(name)}
                                                    >
                                                        {name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="drop_box">
                                        <div className="dropdown" onClick={handlePriorityClick}>
                                            <div className="dropdown__selected">
                                                <label className="fs-14">
                                                    {selectedPriority === 'All'
                                                        ? 'Priority'
                                                        : priorityList.find((option) => option.id == selectedPriority)?.name || 'Priority'}
                                                </label>
                                                <span className="dropdown__icon">
                                                    {openDropdown === 'priority' ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                            <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                            <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                        </svg>
                                                    )}
                                                </span>
                                            </div>
                                            <div className={`dropdown__options ${openDropdown === 'priority' ? 'dropdown__options--open' : ''}`}>
                                                <div
                                                    key="all-priority"
                                                    className="dropdown__option"
                                                    onClick={() => handlePriorityOptionClick('All')}
                                                >
                                                    All
                                                </div>
                                                {priorityList.map((option) => (
                                                    <div
                                                        key={option.id}
                                                        className="dropdown__option"
                                                        onClick={() => handlePriorityOptionClick(option.id)}
                                                    >
                                                        {option.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="drop_box">
                                        <div className="dropdown" onClick={handleStatusClick}>
                                            <div className="dropdown__selected">
                                                <label className="fs-14">
                                                    {selectedStatus === 'All'
                                                        ? 'Status'
                                                        : statusList.find((option) => option.id == selectedStatus)?.name || 'Status'}
                                                </label>
                                                <span className="dropdown__icon">
                                                    {openDropdown === 'status' ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                            <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                            <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                        </svg>
                                                    )}
                                                </span>
                                            </div>
                                            <div className={`dropdown__options ${openDropdown === 'status' ? 'dropdown__options--open' : ''}`}>
                                                <div
                                                    key="all-status"
                                                    className="dropdown__option"
                                                    onClick={() => handleStatusOptionClick("All")}
                                                >
                                                    All
                                                </div>
                                                {statusList.map(option => (
                                                    <div
                                                        key={option.id}
                                                        className="dropdown__option"
                                                        onClick={() => handleStatusOptionClick(option.id)}
                                                    >
                                                        {option.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3 table_part">
                                    <div className="table-responsive subtask-table">
                                        <table className="w-100">
                                            <thead className="pb-2">
                                                <tr>
                                                    <th>Subtask No.</th>
                                                    <th>Subtask</th>
                                                    <th>Time Spent</th>
                                                    <th>Assigned to</th>
                                                    <th>Priority</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredTasks && filteredTasks.map((subcorporatetaskdetail) => (
                                                    <tr onClick={() => openSubTask(subcorporatetaskdetail?.id)}>
                                                        <td>{subcorporatetaskdetail?.task_unique_id}</td>
                                                        <td>{subcorporatetaskdetail?.name}</td>
                                                        <td>{subcorporatetaskdetail?.time_spent}</td>
                                                        <td className="tableuser">
                                                            {Array.isArray(subcorporatetaskdetail.assignees) && subcorporatetaskdetail.assignees.length > 0 && (
                                                                <>
                                                                    <div className="d-flex align-items-center gap-1">
                                                                        <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                        <p className="fs-16">{subcorporatetaskdetail.assignees[0]?.first_name} {subcorporatetaskdetail.assignees[0]?.last_name}</p>
                                                                        <a onClick={() => toggleDropdown(subcorporatetaskdetail.id)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28.001 28">
                                                                                <g id="write" transform="translate(-7.998 -8)">
                                                                                    <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                                    <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                                    <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>

                                                                    <div className="">
                                                                        {openDropdownId === subcorporatetaskdetail.id && (
                                                                            <div className="rounded-3 bg-white mt-3 position-absolute start-0 subdrop" style={{ zIndex: 1000 }}
                                                                                onClick={(e) => e.stopPropagation()}>
                                                                                <ul className="p-0">
                                                                                    {/* Search Input */}
                                                                                    <div className="srchinp position-relative">
                                                                                        <input
                                                                                            type="text"
                                                                                            value={searchSubTaskTerm}
                                                                                            onChange={handleSearchChange}
                                                                                            className="custom-select-search form-input bg-transparent w-100 ps-5"
                                                                                            placeholder="Search..."
                                                                                        />
                                                                                        <i className="fa fa-search" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', width: 'fit-content' }}></i>
                                                                                    </div>

                                                                                    {/* Map over assignData */}
                                                                                    {filteredData.length > 0 ? (
                                                                                        filteredData.map((staff, index) => (
                                                                                            <li
                                                                                                key={index}
                                                                                                className="my-1"
                                                                                                onClick={() => handleStaffSelect(staff, subcorporatetaskdetail.id)}  // Add onClick to handle selection
                                                                                                style={{ cursor: 'pointer' }} // Optional: change cursor to pointer to indicate clickable
                                                                                            >
                                                                                                <p>{staff.name}</p>
                                                                                            </li>
                                                                                        ))
                                                                                    ) : (
                                                                                        <li className="my-1">
                                                                                            <p>No results found</p>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </>

                                                            )}
                                                        </td>
                                                        {/* <td><span className="normal-badge">Normal</span></td> */}
                                                        <td>
                                                            {subcorporatetaskdetail.priority && (
                                                                <span
                                                                    style={{
                                                                        backgroundColor: convertHexToRGBA(subcorporatetaskdetail.priority.color_code, 0.2),
                                                                        padding: '3px 10px',
                                                                        borderRadius: '8px',
                                                                        textAlign: "center",
                                                                        width: "fit-content",
                                                                        fontSize: "14px",
                                                                        color: subcorporatetaskdetail.priority.color_code,
                                                                    }}
                                                                >
                                                                    {subcorporatetaskdetail.priority.name}
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className="dropposition">
                                                            <div
                                                                className="statusbtn-new"
                                                                onClick={() => toggleStatusDropdown(subcorporatetaskdetail.id)} // Toggle dropdown on click
                                                            >



                                                                <label>
                                                                    {selectedStatuses[subcorporatetaskdetail?.id]?.name || 'Select Status'} {/* Display selected status */}
                                                                </label>
                                                                <svg
                                                                    // className={`rot"}`}
                                                                    className={`${isDropdownOpen(subcorporatetaskdetail.id) ? '' : 'rot'}`}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="9.447"
                                                                    height="7.875"
                                                                    viewBox="0 0 9.447 7.875"
                                                                >
                                                                    <path
                                                                        id="drop_down"
                                                                        d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z"
                                                                        transform="translate(-1658 -1171.987)"
                                                                        fill="#212529"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            {/* Toggle 'show' class based on state */}
                                                            <div
                                                                className={`st-dropdown mainshadow rounded-md bg-white ${isDropdownOpen(subcorporatetaskdetail.id) ? 'show' : ''}`}
                                                            >
                                                                <ul className="p-0">

                                                                    {filteredOptionsStatus.map((option) => (
                                                                        <li
                                                                            key={option.id}
                                                                            className="fs-14"
                                                                            onClick={() => handleStatusSelect(option, subcorporatetaskdetail.id)} // Update status on click
                                                                        >
                                                                            {option.name}
                                                                        </li>
                                                                    ))}
                                                                </ul>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-md-4 overflow-hidden h_100vh">
                        <div className="right-task-edit overflow-y-scroll">
                            <div className="row mt-4 px-4">
                                <label className="fs-18 px-1 cursor-pointer">Interaction Type</label>
                                <div className={`select-box t_blue ${dropdownState.isOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('interaction')}>
                                    {dropdownState.selectedOption ? dropdownState.selectedOption.name : "Select"}
                                </div>
                                {dropdownState.isOpen && (
                                    <div className="options-container pb-2">
                                        <div className="srchinput position-relative">
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={dropdownState.search}
                                                onChange={e => setDropdownState(prevState => ({ ...prevState, search: e.target.value }))}
                                                className="search-field t_blue"
                                            />
                                            <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                onClick={() => setDropdownState(prevState => ({ ...prevState, isOpen: false }))}
                                            ></i>
                                        </div>
                                        {filteredOptions.map(option => (
                                            <div
                                                key={option.id}
                                                className="option"
                                                onClick={() => handleSelect(option)}

                                            >
                                                {option.name}
                                            </div>
                                        ))}
                                        <div
                                            className="option text-blue text-start d-flex align-items-center gap-1 fw-medium"
                                            onClick={() => {
                                                handleDropdownOpen(null);
                                                setShowInderaction(true);
                                            }}
                                        >
                                            <span className="addplus">+</span> Add More
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row mt-4 px-4 relative">
                                <label className="fs-18 px-1">Priority</label>

                                <div className={`select-box ${dropdownState.isPriorityOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('priority')}>
                                    {dropdownState.prioritySelectedOption ? dropdownState.prioritySelectedOption.name : "Select"}
                                </div>
                                {dropdownState.isPriorityOpen && (
                                    <div className="options-container pb-2">
                                        <div className=" srchinput position-relative">
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={dropdownState.prioritySearch}
                                                onChange={e => setDropdownState(prevState => ({ ...prevState, prioritySearch: e.target.value }))}
                                                className="search-field t_blue"
                                            />
                                            <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                onClick={() => setDropdownState(prevState => ({ ...prevState, isPriorityOpen: false }))}
                                            ></i>
                                        </div>
                                        {filteredOptionsPriority.map(option => (
                                            <div
                                                key={option.id}
                                                className="option d-flex align-items-center justify-content-between gap-2"
                                                onClick={() => handleSelectPriority(option)}

                                            >
                                                {option.name}

                                                <div
                                                    className="color-circle"
                                                    style={{
                                                        backgroundColor: option.color_code,
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '100%',
                                                        border: option.color_code ? 'none' : '1px solid #ccc'
                                                    }}
                                                ></div>
                                            </div>
                                        ))}
                                        <div
                                            className="option text-blue text-start d-flex align-items-center gap-1 fw-medium"
                                            onClick={() => {
                                                handleDropdownOpen(null);
                                                setShowPriority(true);
                                            }}
                                        >
                                            <span className="addplus">+</span> Add More
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row mt-4 px-4 position-relative">
                                <label className="fs-18 px-1 cursor-pointer" onClick={handleAssigneeClick}>
                                    Assignee
                                </label>
                                <div className="d-flex align-items-center gap-1 flex-wrap select-box">{displayStaffNames()}</div>
                                {showAssignOpen && (
                                    <div className="options-container me-2">

                                        <div className="srchinput position-relative">
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                placeholder="Search staff..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <i
                                                className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                onClick={() => setShowAssignOpen(false)} // Close the modal
                                            ></i>
                                        </div>
                                        <ul className="px-3">
                                            {userInviteList &&
                                                userInviteList
                                                    .filter((staff) =>
                                                        staff.name && typeof staff.name === "string"
                                                            ? staff.name.toLowerCase().includes(searchTerm.toLowerCase())
                                                            : false
                                                    )
                                                    .map((staff) => (
                                                        <li
                                                            key={staff.id}
                                                            className="d-flex justify-content-between align-items-center my-1"
                                                        >
                                                            <label className="fw-semibold text-black">
                                                                <img
                                                                    src={require("../../../assets/images/user-pic.png")}
                                                                    className="me-2"
                                                                    alt="user-pic"
                                                                />
                                                                {staff?.name}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                name="staff_id"
                                                                id={`staff_id_${staff.id}`}
                                                                value={staff.id} // Ensure the value is correctly set to staff.id
                                                                checked={assigneeIds.includes(staff.id)}
                                                                onChange={() => toggleAssignee(staff.id)}
                                                            />
                                                        </li>
                                                    ))}
                                            {/* <li className="option text-blue d-flex align-items-center gap-1 text-blue mt-1 text-start px-0 fw-medium" onClick={() => setShowAssignModal(true)}>
                                                <span className="addplus">+</span> Add More
                                            </li> */}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="row mt-4 px-4 position-relative">
                                <label className="fs-18 px-1 cursor-pointer">Status</label>
                                <div className={`select-box ${dropdownState.isStatusOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('status')}>
                                    {dropdownState.statusSelectedOption ? dropdownState.statusSelectedOption.name : "Select"}
                                </div>
                                {dropdownState.isStatusOpen && (
                                    <div className="options-container me-2">
                                        <div className="srchinput position-relative">
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={dropdownState.statusSearch}
                                                onChange={e => setDropdownState(prevState => ({ ...prevState, statusSearch: e.target.value }))}
                                                className="search-field"
                                            />
                                            <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                onClick={() => setDropdownState(prevState => ({ ...prevState, isStatusOpen: false }))}
                                            ></i>
                                        </div>
                                        {filteredOptionsStatus.map(option => (
                                            <div
                                                key={option.id}
                                                className="option"
                                                onClick={() => handleSelectStatus(option)}
                                            >
                                                {option.name}
                                            </div>
                                        ))}
                                        <div
                                            className="option text-blue text-start d-flex align-items-center gap-1 py-2 mb-2 fw-medium"
                                            onClick={() => {
                                                handleDropdownOpen(null);
                                                setShowStatus(true);
                                            }}
                                        >
                                            <span className="addplus">+</span> Add More
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row px-4 date mt-4">
                                <h4 className="fs-18 px-1 fw-bold text-black">Start Date</h4>
                                <div className="col-md-7 ps-0 pe-2">
                                    <label>Date</label>
                                    <input
                                        type="date"
                                        name="start_date"
                                        className="form-input mt-1"
                                        value={editTaskDetail.start_date}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-md-3 ps-0 pe-2">
                                    <label>Time</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar={null}
                                        name="start_time"
                                        placeholder="HH:MM"
                                        className="form-input mt-1"
                                        value={editTaskDetail.start_time}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-md-2 ps-0 pe-1">
                                    <label>Format</label>
                                    <select
                                        className="form-input mt-1 px-3 bg-white"
                                        name="start_time_format"
                                        value={timeFormats.start_time_format}
                                        onChange={handleFormatChange}
                                    >
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                </div>
                                <div className="col-md-12 ps-0 pe-2">
                                    <p className="text-danger text-end fs-14 mt-2 cursor-pointer">Clear</p>
                                </div>
                            </div>

                            <div className="row px-4 date mt-4">
                                <h4 className="fs-16 px-1 fw-bold text-black">End Date</h4>
                                <div className="col-md-7 ps-0 pe-2">
                                    <label>Date</label>
                                    <input
                                        type="date"
                                        name="end_date"
                                        className="form-input mt-1"
                                        value={editTaskDetail.end_date}
                                        onChange={handleInputChange}

                                    />
                                </div>
                                <div className="col-md-3 ps-0 pe-2">
                                    <label>Time</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar={null}
                                        name="end_time"
                                        className="form-input mt-1 "
                                        placeholder="HH:MM"
                                        value={editTaskDetail.end_time}
                                        onChange={handleInputChange}

                                    />
                                </div>
                                <div className="col-md-2 ps-0 pe-1">
                                    <label>Format</label>
                                    <select
                                        className="form-input mt-1 px-3 bg-white"
                                        name="end_time_format"
                                        value={timeFormats.end_time_format}
                                        onChange={handleFormatChange}
                                    >
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                </div>
                                <div className="col-md-12 ps-0 pe-2">
                                    <p className="text-danger text-end fs-14 mt-2 cursor-pointer">Clear</p>
                                </div>
                            </div>
                            <div className="row px-4 date mt-4">
                                <h4 className="fs-16 px-1 fw-bold text-black">Estimation Time</h4>
                                <div className="row py-2 px-0">
                                    <div className="col-md-12 ps-0 pe-2">
                                        <input
                                            type="text"
                                            name="estimation_time"
                                            className="form-input mt-1 bg-white"
                                            placeholder="1w 1d 1h 1m"
                                            value={editTaskDetail.estimation_time}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row px-4 date mt-4">
                                <h4 className="fs-16 px-1 fw-bold text-black">Time Spent</h4>
                                <div className="row py-2 px-0">
                                    <div className="col-md-12 ps-0 pe-2">
                                        <input
                                            type="text"
                                            name="time_spent"
                                            className="form-input mt-1 bg-white"
                                            placeholder="1w 1d 1h 1m"
                                            value={editTaskDetail.time_spent}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={editTaskModal}
                onHide={() => setEditTaskModal(false)}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal modal-lg email-modal"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-body p-4 position-relative">
                    <form
                        onSubmit={handleSubmit(updateTaskHandle)}
                    >
                        <div className="row">
                            <div className="col-md-12 left-task-modal p-0">
                                <div className="d-flex align-items-end justify-content-between gap-4">
                                    <div className="w-100">
                                        <h4 className="fs-20">Edit Task</h4>
                                        <input
                                            type="text"
                                            className="w-bold text-black p-0 fs-3 h-0 det-input w-100"
                                            placeholder="Add Details"
                                            name="name"
                                            value={editTaskDetail?.name} // Bind the value from editTaskDetail
                                            onChange={(e) => {
                                                setEditTaskDetail({ ...editTaskDetail, name: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <Modal.Header closeButton className="close-icon border-0 p-0" />
                                </div>
                                <hr />
                                <div className="mt-3">
                                    <div>
                                        {/* <textarea
                                            name="description"
                                            id="description"
                                            cols="30"
                                            rows="15"
                                            placeholder="Type here..."
                                            className="bg-white border-1 border-gray"
                                            value={editTaskDetail?.description} // Bind the value from editTaskDetail
                                            onChange={(e) => {
                                                setEditTaskDetail({ ...editTaskDetail, description: e.target.value });
                                            }}
                                        >
                                        </textarea> */}
                                        <JoditEditor
                                            value={editTaskDetail?.description}
                                            // config={{
                                            //     readonly: false, // Change this to true if you want the editor to be read-only
                                            //     placeholder: 'Type here...',
                                            //     // You can add more Jodit configuration options here
                                            // }}
                                            config={config}
                                            onChange={(newContent) => setEditTaskDetail({ ...editTaskDetail, description: newContent })}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="main-wrapper">
                                        <div
                                            className="upload-main-wrapper d-flex align-items-center justify-content-between w-100 border-1 border-dashed gap-2"
                                            onDragOver={handleDragOver}
                                            onDragEnter={handleDragEnter}
                                            onDragLeave={handleDragLeave}
                                            onDrop={handleDrop}

                                        >
                                            <h4 className="fs-14">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="13" height="20" viewBox="0 0 13 20">
                                                    <path id="XMLID_197_" d="M68.356,0h0a4.663,4.663,0,0,0-3.283,1.331,4.469,4.469,0,0,0-1.36,3.214v8.182a2.786,2.786,0,0,0,5.571,0V8.006a.929.929,0,0,0-1.857,0v4.721a.929.929,0,0,1-1.857,0V4.545a2.681,2.681,0,0,1,.816-1.928,2.8,2.8,0,0,1,1.97-.8,2.76,2.76,0,0,1,2.786,2.727v9.091A4.6,4.6,0,0,1,66.5,18.182a4.663,4.663,0,0,1-3.283-1.331,4.468,4.468,0,0,1-1.36-3.214V4.545a.929.929,0,0,0-1.857,0v9.091a6.256,6.256,0,0,0,1.9,4.5A6.528,6.528,0,0,0,66.5,20,6.44,6.44,0,0,0,73,13.636V4.545A4.6,4.6,0,0,0,68.356,0Z" transform="translate(-59.999)" fill="#202529" />
                                                </svg>
                                                Drag &amp; drop here
                                            </h4>
                                            <div className="upload-wrapper cursor-pointer" onClick={() => fileInputRef.current.click()}>
                                                <input type="file" id="file" name="file" onChange={handleFileChange} multiple ref={fileInputRef}
                                                    style={{ display: "none" }} />
                                                <i className="fa fa-upload me-2"></i>
                                                <span className="file-upload-text cursor-pointer">Browse</span>
                                            </div>
                                        </div>
                                        {/* <div className="image-preview-area">
                                            {editTaskDetail?.documents.map((preview, index) => (
                                                <div key={`preview-${preview.id}`} className="image-preview position-relative">
                                                    <img src={preview?.document_path} alt="Preview" style={{ width: 100, height: 100 }} />
                                                    <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(preview.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                            <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                            {selectedFiles.map((file, index) => (
                                                <div key={`file-${index}`} className="image-preview position-relative">
                                                    <img src={URL.createObjectURL(file)} alt="Preview" style={{ width: 100, height: 100 }} />
                                                    <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(index, true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                            <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                        </div> */}
                                        <div className="image-preview-area">
                                            {/* Existing documents */}
                                            {editTaskDetail?.documents.map((preview, index) => {
                                                const filePath = preview?.document_path;
                                                const isImage = filePath.match(/\.(jpeg|jpg|gif|png)$/i); // Check if the file is an image
                                                const isPDF = filePath.match(/\.pdf$/i); // Check if the file is a PDF
                                                const isDOC = filePath.match(/\.docx?$/i); // Check if the file is a Word document
                                                const isXLS = filePath.match(/\.xlsx?$/i); // Check if the file is an Excel file

                                                return (
                                                    <div key={`preview-${index}`} className="image-preview position-relative">
                                                        {isImage ? (
                                                            <img
                                                                src={filePath}
                                                                alt="Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isPDF ? (
                                                            <img
                                                                src={require("../../../assets/images/pdfimage.png")}
                                                                alt="PDF Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isDOC ? (
                                                            <img
                                                                src={require("../../../assets/images/docimage.png")}
                                                                alt="DOC Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isXLS ? (
                                                            <img
                                                                src={require('../../../assets/images/xlsimage.png')}
                                                                alt="XLS Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : (
                                                            <span>Unsupported file type</span>
                                                        )}
                                                        <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(preview.id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                                <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                    <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                    <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                );
                                            })}

                                            {/* New file previews */}
                                            {selectedFiles.map((file, index) => {
                                                const fileName = file.name;
                                                const isImage = fileName.match(/\.(jpeg|jpg|gif|png)$/i); // Check if the file is an image
                                                const isPDF = fileName.match(/\.pdf$/i); // Check if the file is a PDF
                                                const isDOC = fileName.match(/\.docx?$/i); // Check if the file is a Word document
                                                const isXLS = fileName.match(/\.xlsx?$/i); // Check if the file is an Excel file

                                                return (
                                                    <div key={`file-${index}`} className="image-preview position-relative">
                                                        {isImage ? (
                                                            <img
                                                                src={URL.createObjectURL(file)}
                                                                alt="Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isPDF ? (
                                                            <img
                                                                src={require("../../../assets/images/pdfimage.png")}
                                                                alt="PDF Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isDOC ? (
                                                            <img
                                                                src={require("../../../assets/images/docimage.png")}
                                                                alt="DOC Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isXLS ? (
                                                            <img
                                                                src={require('../../../assets/images/xlsimage.png')}
                                                                alt="XLS Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : (
                                                            <span>Unsupported file type</span>
                                                        )}
                                                        <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(index, true)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                                <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                    <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                    <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <label htmlFor="Required Forms" className="inv-title text-black mb-2">Required Forms</label>
                                    <div className="d-flex flex-column mt-3">
                                        {customFormList &&
                                            customFormList.map((form) => (
                                                <div key={form.id} className="d-flex align-items-center gap-2 mt-2">
                                                    <input
                                                        type="checkbox"
                                                        value={form.id}
                                                        checked={editTaskDetail.selectedFormIds?.includes(form.id)} // Preselect if the form ID is in selectedFormIds
                                                        onChange={(e) => handleCheckboxCustomForm(e)} // Handle checkbox changes
                                                    />
                                                    <label className="text-black">{form.data[0]?.title}</label>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="modal-btns gap-4 mt-4 d-flex align-items-center justify-content-between gap-2">
                                    {/* <div className="view-full">
                                        <button className="view-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="15" height="15" viewBox="0 0 15 15">
                                                <path id="expand" d="M5.309.938a.938.938,0,0,1-.937.938h-2.5v2.5A.938.938,0,1,1,0,4.371V.938A.938.938,0,0,1,.938,0H4.371A.938.938,0,0,1,5.309.938ZM4.371,13.125h-2.5v-2.5a.938.938,0,0,0-1.875,0v3.434A.938.938,0,0,0,.938,15H4.371a.938.938,0,0,0,0-1.875Zm9.691-3.434a.938.938,0,0,0-.937.938v2.5h-2.5a.938.938,0,0,0,0,1.875h3.434A.938.938,0,0,0,15,14.063V10.629A.937.937,0,0,0,14.063,9.691Zm0-9.691H10.629a.938.938,0,0,0,0,1.875h2.5v2.5a.938.938,0,0,0,1.875,0V.938A.937.937,0,0,0,14.063,0Z" fill="#77a0df" />
                                            </svg>
                                            View in full page
                                        </button>
                                    </div> */}
                                    <span className="d-flex gap-2">
                                        <a onClick={(e) => {
                                            e.preventDefault(); // Prevent the default link behavior
                                            setEditTaskModal(false); // Close the modal
                                        }} >
                                            <button className="btn-cancel">Cancel</button>
                                        </a>
                                        <a >
                                            <button className="btn-blue" type="submit" >Update</button>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form >
                </div >
            </Modal >

            <Modal
                show={showCustomFormModal}
                onHide={() => setShowCustomFormModal(false)}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal modal-lg email-modal"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-body position-relative">
                    <div className="modal-head d-flex align-items-center justify-content-between gap-3 px-3 pt-3">
                        <h4 className="fs-20 fw-semibold cursor-pointer">Fill Required Forms</h4>
                        <svg className="cursor-pointer" onClick={() => setShowCustomFormModal(false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="circle-xmark" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.707,14.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12l2.293,2.293Z" fill="#212529" />
                        </svg>
                    </div>
                    <form>
                        <div className="tabs-section mt-2">
                            <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                                <div className="p-2">
                                    <TabList>
                                        {currentTask?.links?.map((form, index) => (
                                            <Tab key={index}>
                                                {form.form_data?.length > 0 && form.form_data[0]?.title
                                                    ? form.form_data[0].title
                                                    : form.title || `Form ${index + 1}`}
                                            </Tab>
                                        ))}
                                    </TabList>
                                </div>

                                <div className="tabs-contentmain pb-3">
                                    {currentTask?.links?.map((form, index) => (
                                        <TabPanel key={index}>
                                            <div className="row p-0">
                                                {selectedForm?.id === form.id && surveyModels[index] && (
                                                    <div className="survey-container">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger mt-3 del-survey"
                                                            onClick={() => deleteCustomForm()}
                                                        >
                                                            Delete Survey Form
                                                        </button>
                                                        <Survey model={surveyModels[index]} onComplete={handleCompleteSurvey} />
                                                    </div>
                                                )}
                                            </div>
                                        </TabPanel>
                                    ))}
                                </div>
                            </Tabs>
                        </div>
                    </form>
                </div >
            </Modal >
            <Add_Business_Interaction
                showInderaction={showInderaction}
                setShowInderaction={setShowInderaction}
                handleInteractionData={handleInteractionData}
            />
            <Add_Business_Priority
                showPriority={showPriority}
                setShowPriority={setShowPriority}
                handlePriorityData={handlePriorityData}

            />
            <Add_Business_Status
                showStatus={showStatus}
                setShowStatus={setShowStatus}
                handleStatusData={handleStatusData}

            />
            <Add_Busines_Assisgn
                showAssisgnModal={showAssisgnModal}
                setShowAssignModal={setShowAssignModal}
                handleUserList={handleUserList}

            />
            <Delete
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onDelete={handleDeleteConfirm}
            />
        </div>
    )
}
export default Business_Task_Detail;