import React, { useState } from "react";

const AddFiles = ({ folderName, privacyOption, handleDropdownChange, handleClick, handleInputChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const toggleEdit = () => setIsEditing(true);
    return (
        <div className="row align-items-end">
            <div className="col-2">
                <div className="create_folder" onClick={toggleEdit}>
                    <a className="position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="158" height="136" viewBox="0 0 158 136">
                            <g id="Group_1527" data-name="Group 1527" transform="translate(-138 -198)">
                                <g id="Icon" transform="translate(136.75 195.25)">
                                    <path id="Path_3625" data-name="Path 3625" d="M139.041,138.75a20.22,20.22,0,0,0,20.209-20.216V56.047a20.22,20.22,0,0,0-20.209-20.216H99.269a1.83,1.83,0,0,1-1.6-.948c-3.535-6.366-10.641-19.15-14.176-25.517A12.857,12.857,0,0,0,72.254,2.75h-50.8A20.22,20.22,0,0,0,1.25,22.966v95.568A20.22,20.22,0,0,0,21.459,138.75Z" transform="translate(0 0)" fill="#bfd1ee" fillRule="evenodd"
                                    />
                                    <path id="Path_3626" data-name="Path 3626" d="M13.919,4.25l8.167,14.7H58.623a27.515,27.515,0,0,1,20.216,8.829V24.466A20.224,20.224,0,0,0,58.623,4.25Z" transform="translate(80.411 9.527)" fill="#bfd1ee" fillRule="evenodd"
                                    />
                                </g>
                                <g id="add" transform="translate(204 251)">
                                    <path id="Path_3627" data-name="Path 3627" d="M2.3,7.19A5.789,5.789,0,0,1,8.112,2H18.179a5.782,5.782,0,0,1,5.807,5.153,57.938,57.938,0,0,1,.3,7.138.735.735,0,0,1-.738.717.751.751,0,0,1-.748-.77A56.479,56.479,0,0,0,22.507,7.3a4.3,4.3,0,0,0-4.328-3.813H8.112A4.3,4.3,0,0,0,3.777,7.329a58.175,58.175,0,0,0,0,11.636,4.306,4.306,0,0,0,4.337,3.847h3.547a.743.743,0,1,1,0,1.487H8.117A5.793,5.793,0,0,1,2.3,19.1,59.656,59.656,0,0,1,2.3,7.19Z" fill="#004fc5"
                                    />
                                    <path id="Path_3628" data-name="Path 3628" d="M36,49.318a.743.743,0,0,0,.743.743h2.792A5.787,5.787,0,0,0,45.346,44.9q.055-.56.1-1.1a.737.737,0,0,0-.737-.8.75.75,0,0,0-.745.689q-.044.525-.1,1.065a4.3,4.3,0,0,1-4.331,3.821H36.743A.743.743,0,0,0,36,49.318Z" transform="translate(-21.363 -25.762)" fill="#004fc5"
                                    />
                                    <path id="Path_3629" data-name="Path 3629" d="M19,23.832a.743.743,0,0,1,.743-.743h3.345V19.743a.743.743,0,1,1,1.487,0v3.345H27.92a.743.743,0,1,1,0,1.487H24.575V27.92a.743.743,0,1,1-1.487,0V24.575H19.743A.743.743,0,0,1,19,23.832Z" transform="translate(-10.682 -10.682)" fill="#004fc5"
                                    />
                                </g>
                                <path id="Rectangle_333" data-name="Rectangle 333" d="M0,0H158a0,0,0,0,1,0,0V14a14,14,0,0,1-14,14H14A14,14,0,0,1,0,14V0A0,0,0,0,1,0,0Z" transform="translate(138 306)" fill="#99b7e7"
                                />
                            </g>
                        </svg>
                        <p className="fs-12">Create New Folder</p>
                    </a>
                </div>
            </div>
            <div className="col-1 folder_hr d-flex align-items-center justify-content-center">
                <div className="line"></div>
            </div>
            <div className="col-9">
                {isEditing && (
                    <>
                        <label className="mt-2">Privacy</label>
                        <select
                            className="form-select mt-2"
                            value={privacyOption}
                            onChange={handleDropdownChange}
                        >
                            {/* <option value="0">Draft</option> */}
                            <option value="1">Share to Everyone</option>
                            <option disabled value="2">Private</option>
                        </select>
                        <label>Folder Name</label>
                        <div className="folder_input position-relative">
                            <input
                                type="text"
                                className="form-input mt-2"
                                value={folderName}
                                onChange={handleInputChange}
                                placeholder="Enter folder name"
                            />
                            <button className="add-new"
                                onClick={handleClick}
                            >
                                Add
                            </button>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
};

export default AddFiles;
