
import React, { useState, useContext, useEffect } from 'react'
import Upload_Files from '../../feature/Upload_Files'
import { toast } from "react-toastify";
import { http } from "../../http/http";
import { GlobalContext } from "../../../App";
import { useParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import Delete from '../../feature/Delete';
import CommonButton from '../../feature/CommonButton';
import CommonAddFileModal from '../../feature/CommonAddFileModal';
import SelectUser from '../../feature/SelectUser';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const BusinessDetailFiles = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const businessStoredId = JSON.parse(localStorage.getItem("BusinessId"));
    const { setShowLoader } = useContext(GlobalContext);
    const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
    const [folderFileList, setFolderFileList] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(null); // Initialize with folder status
    const [showModal, setShowModal] = useState(false);
    const [showSubFolderModal, setShowSubFolderModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [activeRow, setActiveRow] = useState(null);
    const [activeType, setActiveType] = useState(null);

    const handleFolderClick = (folderId) => {
        handleFolderFileListing(folderId);
        navigate(`/admin/client/corporate_file/file-detail/${folderId}`);

    };

    const fileIcons = {
        xls: require("../../../assets/images/xlsimage.png"),
        pdf: require("../../../assets/images/pdfimage.png"),
        doc: require("../../../assets/images/docimage.png"),
        default: require("../../../assets/images/imageplaceholder.png"), // Fallback for unknown extensions
    };
    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes >= 1024 * 1024) {
            return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
        } else if (sizeInBytes >= 1024) {
            return `${(sizeInBytes / 1024).toFixed(2)} KB`;
        } else {
            return `${sizeInBytes} B`;
        }
    };
    const timeElapsed = (createdAt) => {
        const now = new Date();
        const createdDate = new Date(createdAt);
        const diff = now - createdDate; // Difference in milliseconds
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (seconds < 60) {
            return `${seconds} seconds ago`;
        } else if (minutes < 60) {
            return `${minutes} minutes ago`;
        } else if (hours < 24) {
            return `${hours} hours ago`;
        } else if (days < 30) {
            return `${days} days ago`;
        } else if (months < 12) {
            return `${months} months ago`;
        } else {
            return `${years} years ago`;
        }
    };

    // Handle Folder Click
    const handleShowModal = (event, folderId, type) => {
        // Check if 'event' is a DOM event before calling stopPropagation
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        setSelectedId(folderId)
        setActiveType(type);
        setShowModal(true); // Open the modal
    };




    const handleCloseModal = () => {
        setShowModal(false);
        // setActiveRow(null);
        setSelectedId(null)
    };

    const handleShowSubModal = () => {
        setShowSubFolderModal(true);
    }

    const handleCloseSubModal = () => {
        setShowSubFolderModal(false);
    }

    const handleUploadFileClick = () => {
        setOpenUploadFileModal(true); // Open the modal
    };
    const handleDeleteConfirm = () => {
        setOpenDeleteModal(false); // Close the modal
        handleFileDelete(selectedFileId); // Call the delete function
    };
    const handleDeleteIconClick = (fileId) => {
        setSelectedFileId(fileId); // Set the task ID to be deleted
        setOpenDeleteModal(true); // Open the delete modal
    };
    const handleEditClick = (id, name) => {
        setIsEditing(id); // Set the ID of the folder being edited
        setInputValue(name); // Populate the input field with the current name
        setSelectedStatus(Number(folderFileList?.status));
    };
    const handleFileUpload = async (fileList, fileLabel, fileExpireDate) => {
        console.log("click")
        const formData = new FormData();
        formData.append("name", fileLabel);
        formData.append("folder_id", id);
        formData.append("model_type", "corporate");
        formData.append("model_id", businessStoredId);
        formData.append("expiry_date", fileExpireDate);
        formData.append("status", "1");

        fileList.forEach((file, index) => {
            console.log(`Appending file: ${file.name}, Size: ${file.size}`);
            formData.append(`file`, file.fileObject);
        });
        http(
            { method: "POST", url: `files`, isSecure: true, contentType: "multipart/form-data", body: formData, },
            (res) => {
                toast.success(res.single_message);
                setOpenUploadFileModal(false);
                handleFolderFileListing();
            },
            (err) => {
                console.error("Error response:", err);
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };

    const handleFolderFileListing = async (folderId = "") => {
        try {
            http({ method: "GET", url: `files?folder_id=${folderId ? folderId : id}`, isSecure: true, },
                (res) => {
                    setFolderFileList(res?.data);
                }
            );
        } catch (error) { }
    };

    const handleFileDelete = async (fileId) => {
        try {
            await http({
                method: "DELETE",
                url: `files/${fileId}`, // Include the file ID in the API call
                isSecure: true,
            },
                (res) => {
                    // Update the folderFileList state after deletion
                    setFolderFileList((prevList) => ({
                        ...prevList,
                        files: prevList.files.filter((file) => file.id !== fileId), // Remove the deleted file
                    },
                        (err) => {
                            if (err.status === 401) {
                                toast.error(err.single_message);
                                setShowLoader(false);
                            } else if (err.status === 400) {
                                toast.error(err.single_message);
                                setShowLoader(false);
                            }
                        }


                    ));
                });
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };
    const handleFolderUpdate = async (folderId) => {
        setShowLoader(true);
        try {
            await http(
                {
                    method: "PUT",
                    url: `folders/${folderId}`, // Use folderId as a query parameter
                    isSecure: true,
                    body: {
                        name: inputValue, // Use the updated input value
                        status: selectedStatus,
                    },
                },
                (res) => {
                    toast.success(res.single_message);
                    setShowLoader(false);
                    setInputValue("");
                    setIsEditing(false);
                    handleFolderFileListing();
                },
                (err) => {
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error("Error updating folder:", error);
        }
    };

    const handleFileDropped = (folderId, fileIds) => {
        const payload = {
            file_ids: fileIds, // Send all selected file IDs
            target_folder_id: folderId,
        };

        http(
            { method: "POST", url: `move-files`, isSecure: true, body: payload },
            (res) => {
                toast.success(res.single_message);
                handleFolderFileListing(folderId);

            },
            (err) => {
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };


    const handleDragEnd = (result) => {
        const { source, destination, draggableId } = result;

        if (!destination) return; // Exit if dropped outside

        // Check if the file is dropped inside a folder
        if (destination.droppableId.startsWith("folder-")) {
            const folderId = destination.droppableId.replace("folder-", ""); // Extract folder ID
            handleFileDropped(folderId, [draggableId]); // Move file to folder
        }
    };
    useEffect(() => {
        handleFolderFileListing();
    }, []);
    return (
        <div className="right_section font-poppins">
            <div className="clients-detail-area py-3">
                {/* <ClientTabs /> */}
                {/* <div className="file_detailhead row">
                    <div className="col-lg-12 d-flex align-items-center justify-content-between gap-2">
                        <h4 className='fs-20 fw-semibold'>Files Details {folderFileList.id}</h4>
                        <button className='btn_dark' onClick={() => handleShowModal('event', folderFileList?.id, 'folder')}>Make Private</button>
                    </div>
                </div> */}

                <div className="file_detailhead row">
                    <div className="col-lg-12 d-flex align-items-center justify-content-between gap-2">
                        <h4 className='fs-20 fw-semibold'>Files Details</h4>
                        <div className='d-flex align-items-center gap-2'>
                            <p className='d-flex align-items-center fw-semibold' onClick={() => navigate(-1)}><i className="fa fa-arrow-left me-2 cursor-pointer"></i>Back</p>
                            <button className='btn_dark' onClick={() => handleShowModal('event', folderFileList?.id, 'folder')}>Make Private</button>
                        </div>
                    </div>
                </div>
                <div className="files_detailfolder mt-4 row">
                    <div className="col-4 d-flex align-items-start gap-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="176.042" height="136" viewBox="0 0 176.042 136">
                            <g id="folder_2_" data-name="folder (2)" transform="translate(-54 -100)">
                                <path id="Path_3650" data-name="Path 3650" d="M194.187,236H69.864A15.882,15.882,0,0,1,54,220.136V115.864A15.882,15.882,0,0,1,69.864,100H121.52a15.986,15.986,0,0,1,13.64,7.718l9.535,15.384h49.492a15.882,15.882,0,0,1,15.864,15.864v81.169A15.882,15.882,0,0,1,194.187,236Z" transform="translate(0 0)" fill="#fea025" />
                                <path id="Path_3651" data-name="Path 3651" d="M195.686,292.975H69.756a12.567,12.567,0,0,1-10.247-4.767,12.184,12.184,0,0,1-1.736-10.754l18.014-63.34C77.879,206.76,85.624,201,93.422,201h125.93a12.568,12.568,0,0,1,10.247,4.766,12.183,12.183,0,0,1,1.736,10.754l-18.014,63.34C211.231,287.214,203.484,292.975,195.686,292.975Z" transform="translate(-1.827 -56.975)" fill="#ffc12b" />
                            </g>
                        </svg>
                        <div>
                            <span className='d-flex align-items-center gap-4'>
                                <p className='fs-20 fw-semibold text_ellnew'>{folderFileList?.name}</p>
                                {!(folderFileList.name === "agreements" && folderFileList.parent_id === null) && (
                                    <svg
                                        onClick={() => handleEditClick(folderFileList.id, folderFileList.name)}
                                        className="cursor-pointer"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 30 30"
                                    >
                                        <g id="Group_1054" data-name="Group 1054" transform="translate(-971 -344)">
                                            <rect
                                                id="Rectangle_276"
                                                data-name="Rectangle 276"
                                                width="30"
                                                height="30"
                                                rx="8"
                                                transform="translate(971 344)"
                                                fill="#ededee"
                                            />
                                            <g id="write" transform="translate(971.004 344)">
                                                <path
                                                    id="Path_3528"
                                                    data-name="Path 3528"
                                                    d="M8.3,26.037,8,28.468a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z"
                                                    transform="translate(0 -7.297)"
                                                    fill="#202529"
                                                />
                                                <path
                                                    id="Path_3529"
                                                    data-name="Path 3529"
                                                    d="M41.979,9.951,40.471,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.858a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z"
                                                    transform="translate(-20.425 0)"
                                                    fill="#202529"
                                                />
                                                <path
                                                    id="Path_3530"
                                                    data-name="Path 3530"
                                                    d="M36.874,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.707A.583.583,0,0,0,36.874,52.583Z"
                                                    transform="translate(-14.877 -31.168)"
                                                    fill="#202529"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                )}
                                <CommonButton onClick={handleShowSubModal} />
                            </span>

                            <p className='fs-16 fw-regular text-secondary'>{moment(folderFileList?.created_at).format('MMM DD, YYYY, hh:mm a')}</p>
                            <span className='d-flex align-items-center gap-2 mt-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.6" viewBox="0 0 18 15.6">
                                    <g id="Folder" transform="translate(-1 -3)">
                                        <path id="Path_3559" data-name="Path 3559" d="M17.2,7.2H4A.6.6,0,0,1,4,6h7.872l-1-2A1.8,1.8,0,0,0,9.258,3H2.8A1.8,1.8,0,0,0,1,4.8v12a1.8,1.8,0,0,0,1.8,1.8H17.2A1.8,1.8,0,0,0,19,16.8V9a1.8,1.8,0,0,0-1.8-1.8Z" transform="translate(0)" fill="#202529" />
                                    </g>
                                </svg>
                                <p className='fs-16 fw-medium'> {folderFileList?.files?.length} Files Added</p>
                            </span>
                        </div>
                    </div>
                    <div className="col-8">
                        {isEditing && (
                            <>
                                <div className="mt-3">
                                    <label className="mt-2">Privacy</label>
                                    <select
                                        className="form-select mt-2"
                                        value={selectedStatus} // Pre-select the status
                                        onChange={(e) => setSelectedStatus(Number(e.target.value))} // Ensure the value is stored as a number
                                    >
                                        {/* <option value={0}>Draft</option> */}
                                        <option value={1}>Share to Everyone</option>
                                        <option disabled value={2}>Private</option>
                                    </select>
                                </div>

                                <div className='mt-3'>
                                    <label>Folder Name</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <div className="folder_input position-relative w-100">
                                            <input
                                                type="text"
                                                className="form-input mt-2"
                                                name="name"
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)} // Update the input value dynamically
                                                placeholder="Enter folder name"
                                            />

                                            <button
                                                className="add-new"
                                                onClick={() => handleFolderUpdate(isEditing)} // Pass folderId when calling the function
                                            >
                                                Update
                                            </button>
                                        </div>
                                        <i
                                            className="fa fa-times mt-2 fs-24 text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setIsEditing(false)} // Cancel editing
                                        ></i>
                                    </div>
                                </div>

                            </>
                        )}
                    </div>

                </div>
                <hr className='my-4 mx-3' />
                <div class="row">
                    <div className="col-lg-12">
                        <div className="folder_upload main-wrapper">
                            <div class="upload-main-wrapper mx-auto text-center">
                                <div class="upload-wrapper justify-content-center add-ind cursor-pointer">
                                    <button className="add-new" onClick={() => handleUploadFileClick()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className='me-2' width="19" height="17" viewBox="0 0 19 17">
                                            <g id="upload-big-arrow" transform="translate(0 -23.714)">
                                                <g id="Group_432" data-name="Group 432" transform="translate(4.355 23.714)">
                                                    <path id="Path_3516" data-name="Path 3516" d="M105.211,29.639h1.908V35.3a1.039,1.039,0,0,0,1.039,1.039h2.836a1.039,1.039,0,0,0,1.039-1.039V29.639h1.907a.78.78,0,0,0,.552-1.332l-4.364-4.365a.782.782,0,0,0-1.1,0l-4.364,4.365a.78.78,0,0,0,.551,1.332Z" transform="translate(-104.431 -23.714)" fill="#fff" />
                                                </g>
                                                <g id="Group_433" data-name="Group 433" transform="translate(0 31.854)">
                                                    <path id="Path_3517" data-name="Path 3517" d="M18.055,220.029a.941.941,0,0,0-.944.937v3.919a2.14,2.14,0,0,1-2.147,2.129H4.035a2.14,2.14,0,0,1-2.146-2.129v-3.919a.945.945,0,0,0-1.889,0v3.919a4.024,4.024,0,0,0,4.035,4H14.965a4.024,4.024,0,0,0,4.035-4v-3.919A.941.941,0,0,0,18.055,220.029Z" transform="translate(0 -220.029)" fill="#fff" />
                                                </g>
                                            </g>
                                        </svg>
                                        Browse</button>
                                </div>
                            </div>
                            <span className='d-flex align-items-center gap-2 justify-content-between mt-3'>
                                <p class="upload-description">Supports: JPEG, JPG, PDF, XLS, DOC </p>
                                <p class="upload-description">Max Size: 10 Mb</p>
                            </span>
                        </div>
                    </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd} >
                    <div className="row">
                        {folderFileList?.children?.length > 0 ? (
                            folderFileList.children.map((folder) => (
                                <Droppable key={folder.id} droppableId={`folder-${folder.id}`}>
                                    {(provided) => (
                                        <div
                                            className="col-lg-4 mt-4"
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleFolderClick(folder?.id)}
                                        >
                                            <div className="bg-white p-3 shadow_main rounded-4">
                                                <div className="d-flex align-items-start justify-content-between gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                                        <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                            <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                            <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fillRule="evenodd" />
                                                        </g>
                                                    </svg>
                                                    <div className="l-head d-flex align-items-center gap-2">
                                                        <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                                        <a className="link_btn">
                                                            <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                            <p className="fs-14 text-muted ms-1">
                                                                {folder?.files?.length || 0}
                                                            </p>
                                                        </a>
                                                        <a
                                                            className="dot_btn"
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent event bubbling
                                                            }}
                                                        >
                                                            <div className="dot_icon">
                                                                {folder?.status === 1 ? (
                                                                    <i
                                                                        className="fa fa-eye-slash"
                                                                        onClick={() => handleShowModal("event", folder.id, 'folder')}
                                                                    ></i>
                                                                ) : (
                                                                    <i
                                                                        className="fa fa-eye"
                                                                        onClick={() => handleShowModal("event", folder.id, 'folder')}
                                                                    ></i>
                                                                )}
                                                            </div>
                                                        </a>
                                                        {!(folder.name === "agreements" && folder.parent_id === null) && (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="30"
                                                                height="30"
                                                                viewBox="0 0 30 30"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleDeleteIconClick(folder.id); // Call delete handler
                                                                }}
                                                            >
                                                                <g
                                                                    id="Group_1055"
                                                                    data-name="Group 1055"
                                                                    transform="translate(16079 13588)"
                                                                >
                                                                    <rect
                                                                        id="Rectangle_276"
                                                                        data-name="Rectangle 276"
                                                                        width="30"
                                                                        height="30"
                                                                        rx="8"
                                                                        transform="translate(-16079 -13588)"
                                                                        fill="#fad3d4"
                                                                    />
                                                                    <path
                                                                        id="Path_3653"
                                                                        data-name="Path 3653"
                                                                        d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z"
                                                                        transform="translate(-16469.299 -14914.999)"
                                                                        fill="#e5252a"
                                                                        fillRule="evenodd"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <h5 className="fs-14 text-black fw-semibold">{folder?.name}</h5>
                                                    <p className="fs-12 text-muted">
                                                        {moment(folder?.created_at).format("MMM DD, YYYY, hh:mm a")}
                                                    </p>
                                                </div>
                                            </div>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            ))
                        ) : (
                            <div className="col-12">
                                <p className="text-muted text-center">No folders available.</p>
                            </div>
                        )}
                    </div>
                    <Droppable droppableId="files">
                        {(provided) => (
                            <div className="folder_boxes row" ref={provided.innerRef} {...provided.droppableProps}>
                                {folderFileList?.files?.map((file, index) => (
                                    <Draggable key={file.id} draggableId={`${file.id}`} index={index}>
                                        {(provided) => (
                                            <div
                                                className="col-lg-4 col-md-6 col-sm-4 mt-4"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}

                                            >
                                                <div className="folder_inside bg-white shadow-main p-4 rounded-4">
                                                    <div className='l-head d-flex align-items-start justify-content-between gap-3'
                                                        onClick={() => {
                                                            if (['pdf', 'jpg', 'png', 'xls', 'doc'].includes(file.extension.toLowerCase())) {
                                                                window.open(file.path, "_blank");
                                                            } else {
                                                                const link = document.createElement("a");
                                                                link.href = file.path;
                                                                link.download = file.name; // Optional: Set a download name
                                                                document.body.appendChild(link);
                                                                link.click();
                                                                document.body.removeChild(link);
                                                            }
                                                        }}
                                                        style={{ cursor: "pointer" }}>
                                                        <img
                                                            src={fileIcons[file.extension] || fileIcons.default}
                                                            alt={`${file.extension} icon`}
                                                            width="60"
                                                            height="60"
                                                        />
                                                        <div className='d-flex align-items-center gap-2'>
                                                            <div className="dot_btn">
                                                                <div className="dot_icon">
                                                                    {file?.status === 1 ? (
                                                                        <i
                                                                            className="fa fa-eye-slash"
                                                                            onClick={(event) => handleShowModal(event, file?.id, 'file')}
                                                                        ></i>
                                                                    ) : (
                                                                        <i
                                                                            className="fa fa-eye"
                                                                            onClick={(event) => handleShowModal(event, file?.id, 'file')}
                                                                        ></i>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" onClick={(e) => {
                                                                e.stopPropagation(); // Prevent event bubbling
                                                                handleDeleteIconClick(file.id); // Call the delete handler
                                                            }}>
                                                                <g id="Group_1055" data-name="Group 1055" transform="translate(16079 13588)">
                                                                    <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(-16079 -13588)" fill="#fad3d4" />
                                                                    <path id="Path_3653" data-name="Path 3653" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-16469.299 -14914.999)" fill="#e5252a" fill-rule="evenodd" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <h4 className='fs-14 fw-semibold mt-4'>{file?.name}.{file?.extension}</h4>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <p className='fs-12 fw-regular text-muted'>Size:{formatFileSize(file?.size)}</p>
                                                        <p className='fs-12 fw-regular text-muted'> Uploaded: {timeElapsed(file?.created_at)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <Upload_Files
                open={openUploadFileModal}
                onClose={() => setOpenUploadFileModal(false)}
                title="Custom File Upload"
                onFileUpload={handleFileUpload}
            />
            <Delete
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onDelete={handleDeleteConfirm}
            />
            <CommonAddFileModal show={showSubFolderModal} onHide={handleCloseSubModal} folderFileList={folderFileList} type="corporate" handleFolderFileListing={handleFolderFileListing} />
            <SelectUser show={showModal} onHide={handleCloseModal} activeRow={selectedId ? selectedId : activeRow} type={activeType} />
        </div>
    )
}

export default BusinessDetailFiles
